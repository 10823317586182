<template>
  <div class="container public">
    <page-header />
    <div class="content-container">
      <div class="<overview-list-section">
        <h2 class="section-title">{{ $t('cdcSensorsPage.title') }} <span v-if="facilityForDevice" class="blue-text"> {{ selectedDevice.apikey }} / {{ facilityForDevice.name }} / {{ organizationForFacility.code }} </span></h2>
        <div class="btn-toolbar">
          <button type="button" class="btn btn-info mr-3" @click="backToDevices">
            <font-awesome-icon icon="arrow-alt-circle-left" /> &nbsp; {{ $t('cdcSensorsPage.backToDevices') }}
          </button>
         <button v-if="userMayEdit" type="button" class="btn btn-primary mr-3" @click="showDetail('add')">
           <font-awesome-icon icon="plus" /> &nbsp; {{ $t('cdcSensorsPage.addButton') }}
         </button>
         <button  v-if="userMayEdit" type="button" class="btn btn-secondary  mr-3" @click="showDetail('edit')"
           :disabled="this.selectedSensorId === -1"><font-awesome-icon icon="edit" />&nbsp; {{ $t('cdcSensorsPage.editButton') }}</button>
         <button  v-if="userMayEdit" type="button" class="btn btn-secondary  mr-3" @click="askDelete"
           :disabled="this.selectedSensorId  === -1"><font-awesome-icon icon="trash" />&nbsp; {{ $t('cdcSensorsPage.deleteButton') }}</button>
        </div>
        <error-row @clearError="clearError" :errorMessage="errorMessage" />
        <div v-show="isLoading" class="is-loading-section" >
          <button class="btn btn-primary"><span class="spinner-border spinner-border-sm"></span></button><span> {{ $t('cdcSensorsPage.isLoading') }} </span>
        </div>
        <div v-show="!isLoading && hasCdcsensors" id="table-section" >
          <table class="table table-hover auto-col">
            <thead>
              <tr>
                <th v-if="userMayEdit">&nbsp;</th>
                <th @click="sort('type')">{{ $t('cdcSensorsPage.columnType') }}</th>
                <th @click="sort('name')">{{ $t('cdcSensorsPage.columnName') }}</th>
                <th @click="sort('enabled')">{{ $t('cdcSensorsPage.columnEnabled') }}</th>
                <th @click="sort('connectiontype')">{{ $t('cdcSensorsPage.columnProtocol') }}</th>
                <th @click="sort('connector')">{{ $t('cdcSensorsPage.columnConnector') }}</th>
                <th @click="sort('factor')">{{ $t('cdcSensorsPage.columnFactor') }}</th>
                <th @click="sort('originalid')">{{ $t('cdcSensorsPage.columnOriginalId') }}</th>
                <th @click="sort('directreading')">{{ $t('cdcSensorsPage.columnDirectReading') }}</th>
                <th @click="sort('uuid')">{{ $t('cdcSensorsPage.columnUUID') }}</th>
                <th colspan="2" @click="sort('sensoreventcnt')">{{ $t('cdcSensorsPage.columnSensorEventCount') }}</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(sensor, index) in sortedSensors"
                @click="selectRow(sensor)"
                :class="{'table-primary' : isSensorSelected(sensor.id)}"
                :key="index"
              >
                <td v-if="userMayEdit"><span @click.stop="editSensor(sensor)"><font-awesome-icon icon="edit" /></span></td>
                <td> {{ sensorType(sensor.type) }} </td>
                <td> {{ sensor.name }} </td>
                <td class="text-center">
                  <span v-if="sensor.enabled">{{ $t('cdcSensorsPage.booleanTrue') }}</span>
                  <span v-if="!sensor.enabled">{{ $t('cdcSensorsPage.booleanFalse') }}</span>
                </td>
                <td> {{ sensorProtocol(sensor.connectiontype) }} </td>
                <td> {{ sensorConnector(sensor.connector) }} </td>
                <td class="text-center"> {{ sensor.factor }} </td>
                <td class="text-center"> {{ sensor.originalid }} </td>
                <td class="text-center">
                  <span v-if="sensor.directreading">{{ $t('cdcSensorsPage.booleanTrue') }}</span>
                  <span v-if="!sensor.directreading">{{ $t('cdcSensorsPage.booleanFalse') }}</span>
                </td>
                <td> {{ sensor.uuid }} </td>
                <td class="text-center"><span v-if="sensor.sensoreventcnt > 0">{{ sensor.sensoreventcnt }}</span></td>
                <td><button class="btn btn-outline-info btn-sm" @click="showSensorEvents(sensor)">{{ $t('cdcSensorsPage.showSensorEvents') }}</button></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <page-footer />

    <edit-sensor-modal @modalClosed="modalClosed" :parentFacility="facilityForDevice" :parentDevice="selectedDevice" :selectedCDCSensor="selectedSensor" :action="selectedAction" :modalOpened="modalTimestamp" />

    <confirm-modal
      :title="$t('cdcSensorsPage.deleteSensor')"
      :text="confirmDeleteText"
      :confirmButtonLabel="$t('confirmModal.confirmButtonLabel')"
      :cancelButtonLabel="$t('confirmModal.cancelButtonLabel')"
      @confirm="confirmDelete"
      @modalClosed="modalClosed"
    />
  </div>
</template>

<script>
import objectSorter from '@/utils/object-sorter'
import PageHeader from '@/components/PageHeader.vue'
import ErrorRow from '@/components/ErrorRow.vue'
import PageFooter from '@/components/PageFooter.vue'
import ConfirmModal from '@/modals/ConfirmModal.vue'
import PageViewMixin from '@/mixins/PageViewMixin.js'
import EditCDCSensorModal from '@/modals/EditCDCSensorModal.vue'
import cdcsensorService from '@/services/cdcsensors'

import { mapGetters } from 'vuex'

export default {
  name: 'CDCSensorsPage',
  mixins: [PageViewMixin],
  data () {
    return {
      cdcDeviceId: 0,
      selectedSensor: null,
      selectedDevice: { id: -1, facilityid: -1, apikey: '', serialnumber: '' },
      selectedAction: 'add',
      currentSortKey: 'originalid',
      currentSortOrder: 'asc',
      isLoading: false
    }
  },
  mounted () {
    this.clearError()
    this.cdcDeviceId = this.$route.params.cdcdeviceid
    if (this.cdcDeviceId > 0) {
      this.selectedDevice = { id: this.cdcDeviceId, facilityid: -1, apikey: '' }
      this.loadSensors(true)
    }
  },
  destroyed () {
    this.$store.dispatch('discardCDCSensorData')
  },
  computed: {
    ...mapGetters([
      'client',
      'hasOrganizations',
      'organizationMap',
      'facilities',
      'hasFacilities',
      'facilityMap',
      'cdcdevices',
      'hasCdcdevices',
      'cdcDeviceMap',
      'cdcsensors',
      'hasCdcsensors'
    ]),
    selectedSensorId () {
      return this.selectedSensor ? this.selectedSensor.id : -1
    },
    emptySensor () {
      return {
        id: -1,
        cdcdeviceid: this.selectedDevice.id,
        connectiontype: 0,
        connector: 0,
        directreading: false,
        enabled: false,
        factor: 1,
        name: '',
        originalid: 0,
        type: 0,
        updateinterval: 0
      }
    },
    confirmDeleteText () {
      if (this.selectedSensor) {
        return this.$t('cdcSensorsPage.confirmDeleteText', { originalid: this.selectedSensor.originalid })
      }
      return ''
    },
    sortedSensors () {
      return objectSorter.sortedArray(this.cdcsensors, this.currentSortOrder, this.currentSortKey)
    },
    facilityForDevice () {
      return this.selectedDevice.id > 0 && this.hasFacilities ? this.facilityMap[this.selectedDevice.facilityid] : { id: -1, name: '' }
    },
    organizationForFacility () {
      return this.facilityForDevice.id > 0 && this.hasOrganizations ? this.organizationMap[this.facilityForDevice.organizationid] : { name: '', code: '' }
    }
  },
  components: {
    'page-header': PageHeader,
    'error-row': ErrorRow,
    'page-footer': PageFooter,
    'edit-sensor-modal': EditCDCSensorModal,
    'confirm-modal': ConfirmModal
  },
  watch: {
    cdcsensors () {
      if (!this.running) {
        this.dataHaveBeenLoaded()
      }
    }
  },
  methods: {
    loadSensors (reset) {
      this.isLoading = true
      const payload = { cdcDeviceId: this.selectedDevice.id }
      this.$store.commit('clearError')
      this.$store.dispatch('getCDCSensorData', payload)
      if (reset) {
        this.selectedSensor = this.emptySensor
      }
    },
    backToDevices () {
      this.$router.push({ name: 'cdcdevices', params: { facilityid: this.selectedDevice.facilityid } })
    },
    isSensorSelected (id) {
      return this.selectedSensor && id === this.selectedSensor.id
    },
    selectRow (sensor) {
      if (this.selectedSensor !== sensor) {
        this.selectedSensor = sensor
      } else {
        this.selectedSensor = this.emptySensor
      }
    },
    showDetail (action) {
      if (action === 'add') {
        this.selectedSensor = this.emptySensor
        this.selectedAction = 'add'
      } else {
        this.selectedAction = 'edit'
      }
      this.showModalView()
    },
    editSensor (sensor) {
      this.selectedSensor = sensor
      this.selectedAction = 'edit'
      this.showModalView()
    },
    showModalView () {
      this.openModal('#editCDCSensorModal')
    },
    confirmDelete () {
      cdcsensorService.deleteCDCSensor(this.selectedSensor.id).then((deletedSensor) => {
        this.loadSensors(true)
      }).catch(error => {
        this.errorMessage = this.localizedBackendMessage(error.message)
      })
    },
    sort (key) {
      // reverse sort order?
      if (key === this.currentSortKey) {
        this.currentSortOrder = this.currentSortOrder === 'asc' ? 'desc' : 'asc'
      }
      this.currentSortKey = key
    },
    reloadData () {
      this.loadSensors(false)
    },
    dataHaveBeenLoaded () {
      if (this.cdcDeviceId > 0 && this.hasCdcdevices) {
        this.selectedDevice = this.cdcDeviceMap[this.cdcDeviceId]
      }
    },
    sensorType (type) {
      return this.attributeLabelForCode('sensorType', type)
    },
    sensorProtocol (protocol) {
      return this.attributeLabelForCode('sensorProtocol', protocol)
    },
    sensorConnector (connector) {
      return this.attributeLabelForCode('sensorConnector', connector)
    },
    showSensorEvents (sensor) {
      this.$router.push({ name: 'sensorevents', params: { cdcsensorid: sensor.id } })
    }
  }
}
</script>

<style lang="scss" scoped>
.content-container {
  padding: 0 35px;

  h2 {
    font-size: 18px;
    margin-bottom: 15px;
    font-weight: 400;
  }

  .magazine-list-section {
    margin: 30px 10px;
  }

  .representation-hierarchy {
    margin-top: 30px;
  }

  table.auto-col {
    table-layout: auto;
  }

  .grey {
    color: #808080;
  }

  .blue-text {
    color: #0069d9;
    font-weight: 700;
  }
  .toggle-icon {
    margin-left: 20px;
  }
}
</style>
