<template>
  <form @submit.prevent="saveOrganization" ref="organizationEditForm">
    <div class="modal" tabindex="-1" role="dialog" backdrop="static" id="editOrganizationModal" @keyup.esc="onEsc($event)">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header header">
            <h3 class="modal-title"><span v-if="action === 'add'">{{ $t('editOrganizationModal.titleAdd') }}</span><span v-if="action === 'edit'">{{ $t('editOrganizationModal.titleEdit') }}</span></h3>
            <button type="button" class="close" @click="close" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="form">
              <div v-show="errorMessage" class="alert alert-danger failed">{{ errorMessage }}</div>
              <div class="form-group">
                <label for="vendorInput">{{ $t('editOrganizationModal.nameInput') }}</label>
                <input type="text" class="form-control" :class="{ 'is-invalid': $v.organization.name.$dirty && $v.organization.name.$error }" id="nameInput" v-model.trim="organization.name" :placeholder="$t('editOrganizationModal.namePlaceholder')" maxlength="50" />
                <div class="invalid-feedback" v-if="$v.organization.name.$dirty">
                  <div class="error" v-if="!$v.organization.name.required">{{ $t('editOrganizationModal.nameRequired') }}</div>
                  <div class="error" v-if="!$v.organization.name.minLength">{{ $t('editOrganizationModal.nameMinLength', { min: $v.organization.name.$params.minLength.min }) }}</div>
                  <div class="error" v-if="!$v.organization.name.maxLength">{{ $t('editOrganizationModal.nameMaxLength', { max: $v.organization.name.$params.maxLength.max }) }}</div>
                </div>
              </div>
              <div class="form-group">
                <label for="codeInput">{{ $t('editOrganizationModal.codeInput') }}</label>
                <input type="text" class="form-control" :class="{ 'is-invalid': $v.organization.code.$dirty && $v.organization.code.$error }" id="codeInput" v-model.trim="organization.code" :placeholder="$t('editOrganizationModal.codePlaceholder')" maxlength="50" />
                <div class="invalid-feedback" v-if="$v.organization.code.$dirty">
                  <div class="error" v-if="!$v.organization.code.required">{{ $t('editOrganizationModal.codeRequired') }}</div>
                  <div class="error" v-if="!$v.organization.code.minLength">{{ $t('editOrganizationModal.codeMinLength', { min: $v.organization.code.$params.minLength.min }) }}</div>
                  <div class="error" v-if="!$v.organization.code.maxLength">{{ $t('editOrganizationModal.codeMaxLength', { max: $v.organization.code.$params.maxLength.max }) }}</div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="submit" class="btn btn-primary"><span v-if="action === 'add'">{{ $t('editOrganizationModal.buttonAdd') }}</span><span v-if="action === 'edit'">{{ $t('editOrganizationModal.buttonSave') }}</span></button>
            <button type="button" class="btn btn-secondary" @click="close">{{ $t('editOrganizationModal.close') }}</button>
          </div>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import $ from 'jquery'
import { required, minLength, maxLength } from 'vuelidate/lib/validators'
import organizationService from '@/services/organizations'
import ModalViewMixin from '@/mixins/ModalViewMixin.js'

export default {
  name: 'EditOrganizationModal',
  mixins: [ModalViewMixin],
  props: {
    selectedOrganization: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      organization: {},
      errorMessage: ''
    }
  },
  watch: {
    modalOpened () {
      this.copyOrganization()
      this.triggerFocus()
    }
  },
  validations: {
    organization: {
      name: {
        required,
        minLength: minLength(1),
        maxLength: maxLength(50)
      },
      code: {
        required,
        minLength: minLength(1),
        maxLength: maxLength(50)
      }
    }
  },
  mounted () {
    $('#editOrganizationModal').on('shown.bs.modal', () => {
      this.triggerFocus()
    })
  },
  methods: {
    copyOrganization () {
      this.organization = Object.assign({}, this.selectedOrganization)
    },
    saveOrganization () {
      this.$v.$touch()
      if (this.$v.$invalid) {
        return
      }

      if (this.action === 'add') {
        organizationService.addOrganization(this.organization).then((createdOrganization) => {
          this.flagChanges()
        }).catch(error => {
          this.errorMessage = this.localizedBackendMessage(error.message)
        })
      } else if (this.action === 'edit') {
        organizationService.updateOrganization(this.organization.id, this.organization).then((updatedOrganization) => {
          this.flagChanges()
        }).catch(error => {
          this.errorMessage = this.localizedBackendMessage(error.message)
        })
      }
    },
    close () {
      this.$v.$reset()
      this.errorMessage = ''
      // zurück zum ursprünglichen 'selected organization',
      // um etwaigen Änderungen im Formular loszuwerden
      this.copyOrganization()
      //
      // this.$refs.organizationEditForm.reset()
      this.emitClosed()
      $('#editOrganizationModal').modal('hide')
    },
    triggerFocus () {
      // Alle 'textarea-autosize' berühren!
      $('#codeInput').trigger('focus')
      $('#nameInput').trigger('focus')
    }
  }
}
</script>

<style lang="scss" scoped>
.modal {
  .modal-dialog {
    width: 400px;
  }

  .blue-text {
    color: #0069d9;
    font-weight: 700;
  }

  .td-right {
    text-align: right;
  }
}
</style>
