<template>
  <div class="container public">
    <page-header />
    <div class="content-container">
      <div class="<overview-list-section">
        <h2 class="section-title">{{ $t('cdcDevicePage.title') }} <span v-if="organizationForFacility" class="blue-text"> {{ selectedFacility.name }} / {{ organizationForFacility.code }} </span></h2>
        <div class="btn-toolbar">
          <button type="button" class="btn btn-info mr-3" @click="backToFacilities">
            <font-awesome-icon icon="arrow-alt-circle-left" /> &nbsp; {{ $t('cdcDevicePage.backToFacilities') }}
          </button>
         <button v-if="userMayEdit" type="button" class="btn btn-primary mr-3" @click="showDetail('add')">
           <font-awesome-icon icon="plus" /> &nbsp; {{ $t('cdcDevicePage.addButton') }}
         </button>
         <button  v-if="userMayEdit" type="button" class="btn btn-secondary  mr-3" @click="showDetail('edit')"
           :disabled="this.selectedDeviceId === -1"><font-awesome-icon icon="edit" />&nbsp; {{ $t('cdcDevicePage.editButton') }}</button>
         <button  v-if="userMayEdit" type="button" class="btn btn-secondary  mr-3" @click="askDelete"
           :disabled="this.selectedDeviceId  === -1"><font-awesome-icon icon="trash" />&nbsp; {{ $t('cdcDevicePage.deleteButton') }}</button>
        </div>
        <error-row @clearError="clearError" :errorMessage="errorMessage" />
        <div v-show="isLoading" class="is-loading-section" >
          <button class="btn btn-primary"><span class="spinner-border spinner-border-sm"></span></button><span> {{ $t('cdcDevicePage.isLoading') }} </span>
        </div>
        <div v-show="!isLoading && hasCdcdevices" id="table-section" >
          <table class="table table-hover auto-col">
            <thead>
              <tr>
                <th v-if="userMayEdit">&nbsp;</th>
                <th @click="sort('enabled')">{{ $t('cdcDevicePage.columnEnabled') }}</th>
                <th @click="sort('name')">{{ $t('cdcDevicePage.columnName') }}</th>
                <th @click="sort('apikey')">{{ $t('cdcDevicePage.columnApiKey') }}</th>
                <th @click="sort('lastseen')">{{ $t('cdcDevicePage.columnLastseen') }}</th>
                <th @click="sort('serialnumber')">{{ $t('cdcDevicePage.columnSerialNumber') }}</th>
                <th @click="sort('notificationstatus')">{{ $t('cdcDevicePage.columnNotificationStatus') }}</th>
                <th @click="sort('status')">{{ $t('cdcDevicePage.columnStatus') }}</th>
                <th colspan="2" @click="sort('sensorcnt')">{{ $t('cdcDevicePage.columnSensorCnt') }}</th>
                <th colspan="2" @click="sort('datapackagecnt')">{{ $t('cdcDevicePage.columnDataPackageCnt') }}</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(device, index) in sortedDevices"
                @click="selectRow(device)"
                :class="{'table-primary' : isDeviceSelected(device.id)}"
                :key="index"
              >
                <td v-if="userMayEdit"><span @click.stop="editDevice(device)"><font-awesome-icon icon="edit" /></span></td>
                <td class="text-center">
                  <span v-if="device.enabled">{{ $t('cdcDevicePage.booleanTrue') }}</span>
                  <span v-else>{{ $t('cdcDevicePage.booleanFalse') }}</span>
                </td>
                <td>
                  <span v-if="device.name">{{ device.name }}</span>
                  <span v-else>{{ $t('cdcDevicePage.noname') }}</span>
                </td>
                <td> {{ device.apikey }} </td>
                <td> {{ device.lastseen | formatDate }} </td>
                <td class="text-center"> {{ device.serialnumber }} </td>
                <td class="text-center">
                  <span v-if="device.notificationstatus">{{ $t('cdcDevicePage.booleanTrue') }}</span>
                  <span v-else>{{ $t('cdcDevicePage.booleanFalse') }}</span>
                </td>
                <td class="text-center"> {{ device.status }} </td>
                <td class="text-center"><span v-if="device.sensorcnt > 0">{{ device.sensorcnt }}</span></td>
                <td><button class="btn btn-outline-info btn-sm" @click="gotoSensors(device)">{{ $t('cdcDevicePage.editSensors') }}</button></td>
                <td class="text-center"><span v-if="device.datapackagecnt > 0">{{ device.datapackagecnt }}</span></td>
                <td><button class="btn btn-outline-info btn-sm" @click="showData(device)">{{ $t('cdcDevicePage.showData') }}</button></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <page-footer />

    <edit-device-modal @modalClosed="modalClosed" :selectedFacility="selectedFacility" :selectedCDCDevice="selectedDevice" :action="selectedAction" :modalOpened="modalTimestamp" />

    <confirm-modal
      :title="$t('cdcDevicePage.deleteDevice')"
      :text="confirmDeleteText"
      :confirmButtonLabel="$t('confirmModal.confirmButtonLabel')"
      :cancelButtonLabel="$t('confirmModal.cancelButtonLabel')"
      @confirm="confirmDelete"
      @modalClosed="modalClosed"
    />
  </div>
</template>

<script>
import moment from 'moment'
import objectSorter from '@/utils/object-sorter'
import PageHeader from '@/components/PageHeader.vue'
import ErrorRow from '@/components/ErrorRow.vue'
import PageFooter from '@/components/PageFooter.vue'
import ConfirmModal from '@/modals/ConfirmModal.vue'
import PageViewMixin from '@/mixins/PageViewMixin.js'
import EditCDCDeviceModal from '@/modals/EditCDCDeviceModal.vue'
import cdcdeviceService from '@/services/cdcdevices'

import { mapGetters } from 'vuex'

export default {
  name: 'CDCDevicesPage',
  mixins: [PageViewMixin],
  data () {
    return {
      facilityid: 0,
      selectedDevice: null,
      selectedFacility: { id: -1, organizationid: -1, name: '' },
      selectedAction: 'add',
      currentSortKey: 'apikey',
      currentSortOrder: 'asc',
      isLoading: false
    }
  },
  filters: {
    formatDate (date) {
      if (date) {
        return moment(String(date)).format('DD.MM.YYYY HH:mm')
      }
    }
  },
  mounted () {
    this.clearError()
    this.facilityid = this.$route.params.facilityid
    if (this.facilityid > 0) {
      this.selectedFacility = { id: this.facilityid, organizationid: -1, name: '' }
      this.loadDevices(true)
    }
  },
  destroyed () {
    this.$store.dispatch('discardCDCDeviceData')
  },
  computed: {
    ...mapGetters([
      'client',
      'hasOrganizations',
      'organizationMap',
      'facilities',
      'hasFacilities',
      'facilityMap',
      'cdcdevices',
      'hasCdcdevices'
    ]),
    selectedDeviceId () {
      return this.selectedDevice ? this.selectedDevice.id : -1
    },
    emptyDevice () {
      return {
        id: -1,
        facilityid: this.selectedFacility.id,
        name: '',
        apikey: '',
        lastseen: new Date(),
        serialnumber: '',
        enabled: false,
        notificationstatus: false,
        status: 0,
        commandarguments: ''
      }
    },
    confirmDeleteText () {
      if (this.selectedDevice != null) {
        return this.$t('cdcDevicePage.confirmDeleteText', { apikey: this.selectedDevice.apikey })
      }
      return ''
    },
    sortedDevices () {
      return objectSorter.sortedArray(this.cdcdevices, this.currentSortOrder, this.currentSortKey)
    },
    organizationForFacility () {
      return this.selectedFacility.id > 0 && this.hasOrganizations ? this.organizationMap[this.selectedFacility.organizationid] : { name: '', code: '' }
    }
  },
  components: {
    'page-header': PageHeader,
    'error-row': ErrorRow,
    'page-footer': PageFooter,
    'edit-device-modal': EditCDCDeviceModal,
    'confirm-modal': ConfirmModal
  },
  watch: {
    cdcdevices () {
      if (!this.running) {
        this.dataHaveBeenLoaded()
      }
    }
  },
  methods: {
    loadDevices (reset) {
      this.isLoading = true
      const payload = { facilityId: this.selectedFacility.id }
      this.$store.commit('clearError')
      this.$store.dispatch('getCDCDeviceData', payload)
      if (reset) {
        this.selectedDevice = this.emptyDevice
      }
    },
    backToFacilities () {
      this.$router.push({ name: 'facilities', params: { organizationid: this.selectedFacility.organizationid } })
    },
    isDeviceSelected (id) {
      return this.selectedDevice && id === this.selectedDevice.id
    },
    selectRow (device) {
      if (this.selectedDevice !== device) {
        this.selectedDevice = device
      } else {
        this.selectedDevice = this.emptyDevice
      }
    },
    showDetail (action) {
      if (action === 'add') {
        this.selectedDevice = this.emptyDevice
        this.selectedAction = 'add'
      } else {
        this.selectedAction = 'edit'
      }
      this.showModalView()
    },
    editDevice (device) {
      this.selectedDevice = device
      this.selectedAction = 'edit'
      this.showModalView()
    },
    showModalView () {
      this.openModal('#editCDCDeviceModal')
    },
    confirmDelete () {
      cdcdeviceService.deleteCDCDevice(this.selectedDevice.id).then((deletedDevice) => {
        this.loadDevices(true)
      }).catch(error => {
        this.errorMessage = this.localizedBackendMessage(error.message)
      })
    },
    sort (key) {
      // reverse sort order?
      if (key === this.currentSortKey) {
        this.currentSortOrder = this.currentSortOrder === 'asc' ? 'desc' : 'asc'
      }
      this.currentSortKey = key
    },
    reloadData () {
      this.loadDevices(false)
    },
    gotoSensors (device) {
      this.$router.push({ name: 'cdcsensors', params: { cdcdeviceid: device.id } })
    },
    dataHaveBeenLoaded () {
      if (this.facilityid > 0 && this.hasFacilities) {
        this.selectedFacility = this.facilityMap[this.facilityid]
      }
    },
    showData (device) {
      // this.testDataUpload(device) // FIXME!!!
      // this.testSensorConfigUpload(device)
      this.$router.push({ name: 'cdcdatavalues', params: { cdcdeviceid: device.id, from: 0, until: 0 } })
    },
    testDataUpload (device) {
      const payload = {
        key: device.apikey,
        data: [
          {
            rts: 1623064315,
            sts: 1623064315,
            pkg: 0,
            pk: 1,
            vs: [
              { v: 1.1238e8, f: -1, t: 9, s: 4 },
              { v: 1.1238e8, f: -1, t: 16, s: 18 },
              { v: 0, f: -1, t: 9, s: 19 },
              { v: 12, f: 0, t: 3, s: 4 }
            ]
          },
          {
            rts: 1623064260,
            sts: 2616424,
            pkg: 3044,
            pk: 1,
            vs: [
              { v: 5325, f: -2, t: 16, s: 18 },
              { v: 3717, f: -3, t: 1, s: 10 }
            ]
          }
        ]
      }
      cdcdeviceService.cdcDataUpload(payload).then((answer) => {
        // console.log('testDataUpload answer = ')
        // console.log(answer)
        this.loadDevices(true)
      }).catch(error => {
        this.errorMessage = this.localizedBackendMessage(error.message)
      })
    },
    testSensorConfigUpload (device) {
      const payload = {
        key: device.apikey,
        sensors: [
          {
            name: 'Kühlschrank',
            type: 4,
            protocol: 1,
            connector: 5,
            mainURI: 'D0D1D2D3D402',
            statusURIFragment: '',
            valueURIFragment: '',
            loginURIFragment: '',
            interval: 0,
            username: '',
            password: '',
            authMethod: 0,
            key: '',
            directReading: 0,
            enabled: 1,
            pk: 1,
            uuid: 'c2231cbc-c4b0-421f-a10c-804ac3cb3042',
            json: '{"model":"CS-T_Lv1"}',
            factor: -2
          },
          {
            name: 'CS-IRW',
            type: 2,
            protocol: 8,
            connector: 0,
            mainURI: '',
            statusURIFragment: '',
            valueURIFragment: '',
            loginURIFragment: '',
            interval: 0,
            username: '',
            password: '',
            authMethod: 0,
            key: '',
            directReading: 0,
            enabled: 1,
            pk: 2,
            uuid: '78c62c14-194b-4643-bedc-c0d00fc31154',
            json: '{"model":"CS-IRWv1","th":2000,"dl":500,"factor":-4}',
            factor: 0
          }
        ]
      }
      cdcdeviceService.cdcSensorConfigUpload(payload).then((answer) => {
        // console.log('testSensorConfigUpload answer = ')
        // console.log(answer)
        this.loadDevices(true)
      }).catch(error => {
        this.errorMessage = this.localizedBackendMessage(error.message)
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.content-container {
  padding: 0 35px;

  h2 {
    font-size: 18px;
    margin-bottom: 15px;
    font-weight: 400;
  }

  .magazine-list-section {
    margin: 30px 10px;
  }

  .representation-hierarchy {
    margin-top: 30px;
  }

  table.auto-col {
    table-layout: auto;
  }

  .grey {
    color: #808080;
  }

  .blue-text {
    color: #0069d9;
    font-weight: 700;
  }
  .toggle-icon {
    margin-left: 20px;
  }
}
</style>
