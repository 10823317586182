<template>
  <form @submit.prevent="saveUserPushToken" ref="userPushTokenEditForm">
    <div class="modal" tabindex="-1" role="dialog" backdrop="static" id="editUserPushTokenModal" @keyup.esc="onEsc($event)">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header header">
            <h3 class="modal-title"><span v-if="action === 'add'">{{ $t('editUserPushTokenModal.titleAdd') }}</span><span v-if="action === 'edit'">{{ $t('editUserPushTokenModal.titleEdit') }}</span></h3>
            <button type="button" class="close" @click="close" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="form">
              <div v-show="errorMessage" class="alert alert-danger failed">{{ errorMessage }}</div>
              <div class="form-group">
                <label for="userName">{{ $t('editUserPushTokenModal.userName') }}</label>
                <div id="userName" class="blue-text">{{ selectedUser.email }}</div>
              </div>
              <div class="form-group">
                <label for="developermodeInput">{{ $t('editUserPushTokenModal.developermodeInput') }}</label>
                &nbsp;&nbsp;<input type="checkbox" v-model="userPushToken.developermode" id="developermodeInput">
              </div>
              <div class="form-group">
                <label for="lastseenInput">{{ $t('editUserPushTokenModal.lastseenInput') }}</label>
                <div class="lastseenInput-input">
                  <vue-ctk-date-time-picker v-model="userPushToken.lastseen" id="lastseenInput" noLabel="true" :button-now-translation="$t('editUserPushTokenModal.lastseenNow')" format="YYYY-MM-DDTHH:mm:ssZ" />
                </div>
              </div>
              <div class="form-group">
                <label for="vendorInputSelect">{{ $t('editUserPushTokenModal.vendorInput') }}</label>
                <select class="form-control" id="vendorInputSelect" v-model="userPushToken.vendor" >
                  <option v-for="vendorCode in vendorCodes" :value="vendorCode" :key="vendorCode">
                    {{ localizedVendor(vendorCode) }}
                  </option>
                </select>
              </div>
              <div class="form-group">
                <label for="modelInput">{{ $t('editUserPushTokenModal.modelInput') }}</label>
                <input type="text" class="form-control" :class="{ 'is-invalid': $v.userPushToken.model.$dirty && $v.userPushToken.model.$error }" id="modelInput" v-model.trim="userPushToken.model" :placeholder="$t('editUserPushTokenModal.modelPlaceholder')" maxlength="255" />
                <div class="invalid-feedback" v-if="$v.userPushToken.model.$dirty">
                  <div class="error" v-if="!$v.userPushToken.model.required">{{ $t('editUserPushTokenModal.modelRequired') }}</div>
                  <div class="error" v-if="!$v.userPushToken.model.minLength">{{ $t('editUserPushTokenModal.modelMinLength', { min: $v.userPushToken.model.$params.minLength.min }) }}</div>
                  <div class="error" v-if="!$v.userPushToken.model.maxLength">{{ $t('editUserPushTokenModal.modelMaxLength', { max: $v.userPushToken.model.$params.maxLength.max }) }}</div>
                </div>
              </div>
              <div class="form-group" v-if="pushService === 'APNS'">
                <label for="tokenInput">{{ $t('editUserPushTokenModal.tokenInputAPNS') }}</label>
                <textarea-autosize rows="1" class="form-control" :class="{ 'is-invalid': $v.userPushToken.token.$dirty && $v.userPushToken.token.$error }" id="tokenInput" v-model.trim="userPushToken.token" :placeholder="$t('editUserPushTokenModal.tokenPlaceholderAPNS')" maxlength="255" :max-height="150" />
                <div class="invalid-feedback" v-if="$v.userPushToken.token.$dirty">
                  <div class="error" v-if="!$v.userPushToken.token.required">{{ $t('editUserPushTokenModal.tokenRequiredAPNS') }}</div>
                  <div class="error" v-if="!$v.userPushToken.token.minLength">{{ $t('editUserPushTokenModal.tokenMinLengthAPNS', { min: $v.userPushToken.token.$params.minLength.min }) }}</div>
                  <div class="error" v-if="!$v.userPushToken.token.maxLength">{{ $t('editUserPushTokenModal.tokenMaxLengthAPNS', { max: $v.userPushToken.token.$params.maxLength.max }) }}</div>
                </div>
              </div>
              <div class="form-group" v-if="pushService === 'GOOGLE'">
                <label for="tokenInput">{{ $t('editUserPushTokenModal.tokenInputGoogle') }}</label>
                <textarea-autosize rows="1" class="form-control" :class="{ 'is-invalid': $v.userPushToken.token.$dirty && $v.userPushToken.token.$error }" id="tokenInput" v-model.trim="userPushToken.token" :placeholder="$t('editUserPushTokenModal.tokenPlaceholderGoogle')" maxlength="255" :max-height="150" />
                <div class="invalid-feedback" v-if="$v.userPushToken.token.$dirty">
                  <div class="error" v-if="!$v.userPushToken.token.required">{{ $t('editUserPushTokenModal.tokenRequiredGoogle') }}</div>
                  <div class="error" v-if="!$v.userPushToken.token.minLength">{{ $t('editUserPushTokenModal.tokenMinLengthGoogle', { min: $v.userPushToken.token.$params.minLength.min }) }}</div>
                  <div class="error" v-if="!$v.userPushToken.token.maxLength">{{ $t('editUserPushTokenModal.tokenMaxLengthGoogle', { max: $v.userPushToken.token.$params.maxLength.max }) }}</div>
                </div>
              </div>
              <div class="form-group" v-if="pushService === 'EMAIL'">
                <label for="tokenInput">{{ $t('editUserPushTokenModal.tokenInputEmail') }}</label>
                <input type="text" class="form-control" :class="{ 'is-invalid': $v.userPushToken.token.$dirty && $v.userPushToken.token.$error }" id="tokenInput" v-model.trim="userPushToken.token" :placeholder="$t('editUserPushTokenModal.tokenPlaceholderEmail')" maxlength="128" />
                <div class="invalid-feedback" v-if="$v.userPushToken.token.$dirty">
                  <div class="error" v-if="!$v.userPushToken.token.required">{{ $t('editUserPushTokenModal.tokenRequiredEmail') }}</div>
                  <div class="error" v-if="!$v.userPushToken.token.email">{{ $t('editUserPushTokenModal.tokenEmail') }}</div>
                  <div class="error" v-if="!$v.userPushToken.token.maxLength">{{ $t('editUserPushTokenModal.tokenMaxLengthEmail', { max: $v.userPushToken.token.$params.maxLength.max }) }}</div>
                </div>
              </div>
              <div class="form-group"  v-if="pushService === 'PHONE'">
                <label for="tokenInput">{{ $t('editUserPushTokenModal.tokenInputPhone') }}</label>
                <input type="text" class="form-control" :class="{ 'is-invalid': $v.userPushToken.token.$dirty && $v.userPushToken.token.$error }" id="tokenInput" v-model.trim="userPushToken.token" :placeholder="$t('editUserPushTokenModal.tokenPlaceholderPhone')" maxlength="32" />
                <div class="invalid-feedback" v-if="$v.userPushToken.token.$dirty">
                  <div class="error" v-if="!$v.userPushToken.token.required">{{ $t('editUserPushTokenModal.tokenRequiredPhone') }}</div>
                  <div class="error" v-if="!$v.userPushToken.token.phone">{{ $t('editUserPushTokenModal.tokenPhone') }}</div>
                  <div class="error" v-if="!$v.userPushToken.token.maxLength">{{ $t('editUserPushTokenModal.tokenMaxLengthPhone', { max: $v.userPushToken.token.$params.maxLength.max }) }}</div>
                </div>
              </div>
              <div class="form-group">
                <label for="appvendoridInput">{{ $t('editUserPushTokenModal.appvendoridInput') }}</label>
                <textarea-autosize rows="1" class="form-control" :class="{ 'is-invalid': $v.userPushToken.appvendorid.$dirty && $v.userPushToken.appvendorid.$error }" id="appvendoridInput" v-model.trim="userPushToken.appvendorid" :placeholder="$t('editUserPushTokenModal.appvendoridPlaceholder')" maxlength="255" :max-height="150" />
                <div class="invalid-feedback" v-if="$v.userPushToken.appvendorid.$dirty">
                  <div class="error" v-if="!$v.userPushToken.appvendorid.maxLength">{{ $t('editUserPushTokenModal.appvendoridMaxLength', { max: $v.userPushToken.appvendorid.$params.maxLength.max }) }}</div>
                </div>
              </div>
              <div class="form-group">
                <label for="silentdeliveryInputSelect">{{ $t('editUserPushTokenModal.silentdeliveryInput') }}</label>
                <select class="form-control" id="silentdeliveryInputSelect" v-model="userPushToken.silentdelivery" >
                  <option v-for="alertStatusCode in alertStatusCodes" :value="alertStatusCode" :key="alertStatusCode">
                    {{ localizedSensorAlertStatus(alertStatusCode) }}
                  </option>
                </select>
                <div class="invalid-feedback" v-if="$v.userPushToken.silentdelivery.$dirty">
                  <div class="error" v-if="!$v.userPushToken.silentdelivery.required">{{ $t('editUserPushTokenModal.silentdeliveryRequired') }}</div>
                  <div class="error" v-if="!$v.userPushToken.silentdelivery.integer || !$v.userPushToken.silentdelivery.between">{{ $t('editUserPushTokenModal.silentdeliveryNumeric') }}</div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="submit" class="btn btn-primary"><span v-if="action === 'add'">{{ $t('editUserPushTokenModal.buttonAdd') }}</span><span v-if="action === 'edit'">{{ $t('editUserPushTokenModal.buttonSave') }}</span></button>
            <button type="button" class="btn btn-secondary" @click="close">{{ $t('editUserPushTokenModal.close') }}</button>
          </div>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import $ from 'jquery'
import moment from 'moment'
import { required, minLength, maxLength, integer, between, email, helpers } from 'vuelidate/lib/validators'

import userService from '@/services/users'
import ModalViewMixin from '@/mixins/ModalViewMixin.js'

const phone = helpers.regex('phone', /^(\+)[1-9][0-9 \-().]{7,32}$/)

export default {
  name: 'EditUserPushTokenModal',
  mixins: [ModalViewMixin],
  props: {
    selectedUser: {
      type: Object,
      required: true
    },
    selectedUserPushToken: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      userPushToken: {},
      alertStatusCodes: [0, 1, 2, 3],
      vendorCodes: ['APNS', 'GOOGLE', 'EMAIL', 'PHONE'],
      errorMessage: ''
    }
  },
  computed: {
    pushService () {
      const value = this.userPushToken.vendor
      if (value && value.length > 0) {
        return value
      } else {
        return 'NONE'
      }
    }
  },
  filters: {
    formatDate (date) {
      if (date) {
        return moment(String(date)).format('DD.MM.YYYY HH:mm')
      }
    }
  },
  watch: {
    modalOpened () {
      this.copyUserPushToken()
      this.triggerFocus()
    }
  },
  validations () {
    var map = {
      userPushToken: {
        developermode: {
          required
        },
        lastseen: {
          required
        },
        vendor: {
          required,
          minLength: minLength(1),
          maxLength: maxLength(255)
        },
        model: {
          required,
          minLength: minLength(1),
          maxLength: maxLength(255)
        },
        appvendorid: {
          maxLength: maxLength(255)
        },
        silentdelivery: {
          required,
          integer,
          between: between(0, 3)
        },
        token: {
          required,
          minLength: minLength(1),
          maxLength: maxLength(255)
        }
      }
    }
    const tokenAPNS = {
      required,
      minLength: minLength(1),
      maxLength: maxLength(255)
    }

    const tokenGoogle = {
      required,
      minLength: minLength(1),
      maxLength: maxLength(255)
    }

    const tokenEmail = {
      required,
      email,
      maxLength: maxLength(128)
    }

    const tokenPhone = {
      required,
      phone,
      maxLength: maxLength(32)
    }

    switch (this.pushService) {
      case 'APNS':
        map.userPushToken.token = tokenAPNS
        break
      case 'GOOGLE':
        map.userPushToken.token = tokenGoogle
        break
      case 'EMAIL':
        map.userPushToken.token = tokenEmail
        break
      case 'PHONE':
        map.userPushToken.token = tokenPhone
        break
      case 'NONE':
        map.userPushToken.token = {}
        break
    }

    return map
  },
  mounted () {
    $('#editUserPushTokenModal').on('shown.bs.modal', () => {
      this.triggerFocus()
    })
  },
  methods: {
    copyUserPushToken () {
      this.userPushToken = Object.assign({}, this.selectedUserPushToken)
    },
    saveUserPushToken () {
      this.$v.$touch()
      if (this.$v.$invalid) {
        return
      }

      if (this.action === 'add') {
        userService.addUserPushToken(this.userPushToken).then((createdUserPushToken) => {
          this.flagChanges()
        }).catch(error => {
          this.errorMessage = this.localizedBackendMessage(error.message)
        })
      } else if (this.action === 'edit') {
        userService.updateUserPushToken(this.userPushToken.id, this.userPushToken).then((updatedUserPushToken) => {
          this.flagChanges()
        }).catch(error => {
          this.errorMessage = this.localizedBackendMessage(error.message)
        })
      }
    },
    close () {
      this.$v.$reset()
      this.errorMessage = ''
      // zurück zum ursprünglichen 'selected userPushToken',
      // um etwaigen Änderungen im Formular loszuwerden
      this.copyUserPushToken()
      //
      // this.$refs.userPushTokenEditForm.reset()
      this.emitClosed()
      $('#editUserPushTokenModal').modal('hide')
    },
    triggerFocus () {
      // Alle 'textarea-autosize' berühren!
      $('#appvendoridInput').trigger('focus')
      $('#tokenInput').trigger('focus')
      // $('#modelInput').trigger('focus')
      // $('#vendorInput').trigger('focus')
    },
    localizedVendor (vendorCode) {
      return this.$t('editUserPushTokenModal.vendorCode' + vendorCode)
    },
    localizedSensorAlertStatus (code) {
      return this.attributeLabelForCode('sensorAlertStatus', code)
    }
  }
}
</script>

<style lang="scss" scoped>
.modal {
  .modal-dialog {
    width: 400px;
  }

  .blue-text {
    color: #0069d9;
    font-weight: 700;
  }

  .td-right {
    text-align: right;
  }
}
</style>
