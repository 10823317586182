<template>
  <form @submit.prevent="close" ref="mapSnapshotForm">
    <div class="modal" tabindex="-1" role="dialog" backdrop="static" id="mapSnapshotModal" @keyup.esc="onEsc($event)">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header header">
            <h3 class="modal-title">{{ $t('editFacilityModal.titleMapSnapshot') }}</h3>
            <button type="button" class="close" @click="close" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="form">
              <div v-show="errorMessage" class="alert alert-danger failed">{{ errorMessage }}</div>
              <div class="form-group">
                <label for="organizationName">{{ $t('editFacilityModal.organizationName') }}</label>
                <div id="organizationName" class="blue-text">{{ selectedOrganization.name }}</div>
              </div>
              <div class="form-group">
                <label for="facilityName">{{ $t('editFacilityModal.facilityName') }}</label>
                <div id="facilityName" class="blue-text">{{ selectedFacility.name }}</div>
              </div>
              <div v-if="selectedFacility.geolatitude && selectedFacility.geolongitude" class="form-group">
                <img :src=mapSnapshotUrl class="map-snapshot" />
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" @click="close">{{ $t('editFacilityModal.close') }}</button>
          </div>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import $ from 'jquery'
import ModalViewMixin from '@/mixins/ModalViewMixin.js'
import facilityService from '@/services/facilities'

export default {
  name: 'MapSnapshotModal',
  mixins: [ModalViewMixin],
  props: {
    selectedOrganization: {
      type: Object,
      required: true
    },
    selectedFacility: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      errorMessage: ''
    }
  },
  computed: {
    mapSnapshotUrl () {
      return facilityService.mapsnaphostUrl(this.selectedFacility.id)
    }
  },
  methods: {
    triggerFocus () {
      // nothing
    },
    close () {
      this.emitClosed()
      $('#mapSnapshotModal').modal('hide')
    }
  }
}
</script>

<style lang="scss" scoped>
.modal {
  .modal-dialog {
    width: 400px;
  }

  .blue-text {
    color: #0069d9;
    font-weight: 700;
  }

  .td-right {
    text-align: right;
  }

  .map-snapshot {
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 10px;
    margin-bottom: 10px;
    width: 320px;
    height: auto;
  }
}
</style>
