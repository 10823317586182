import Vue from 'vue'
import Vuex from 'vuex'
import * as getters from './getters'
import * as actions from './actions'
import mutations from './mutations'
import createLogger from 'vuex/dist/logger'

Vue.use(Vuex)

const state = {
  company: { name: '', logo: '', short: '' },
  client: {
    name: null,
    organization: { id: 0, code: '' },
    organizations: [], /* [ { id: 1, code: A' },{ id: 2, code: 'B' } ] */
    role: 0,
    authenticated: false,
    timestamp: new Date()
  },
  errorinfo: {
    origin: '',
    message: ''
  },
  errorflag: false,
  running: false,
  users: [/* {id, created, username, email, firstName, lastName, password, userroleid} */],
  organizations: [/* {id, code, name} */],
  facilities: [/* {id, organizationid, name, description, location} */],
  cdcdevices: [/* id, facilityid, apikey, lastseen, serialnumber, notificationstatus, status */],
  cdcsensors: [/* id, cdcdeviceid, ... */],
  sensorevents: [/* id, cdcsensorid, sensoralerteventcode, sensoralertstatuscode, ... */],
  cdcdatavalues: [/* id, cdcdeviceid, ..., values */],
  roles: [/* {id, code, name} */],
  userpushtokens: [/* {id, userid, lastseeen, model, token, vendor} */],
  collections: [/* id, hexaddress, name, serialnr, sensortype, created, json */],
  dailyTotals: [/* id, startofday, lastupdate, cdcsensorid, externalsensorid, factor, systempart, valuetypepulse, valuetypetotal, value, type, unit, typestring, meterinfo, obis, externalfacilityid */]
}

export default new Vuex.Store({
  state,
  getters,
  actions,
  mutations,
  plugins: process.env.NODE_ENV !== 'production'
    ? [createLogger()]
    : []
})
