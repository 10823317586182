//
// Basisfunktion für modale Views, um den Klick auf die Escape-Taste registrieren zu können.
//
//  <div class="modal" tabindex="-1" role="dialog" backdrop="static" id="editCategoryModal" @keyup.esc="onEsc($event)">
//
export default {
  data () {
    return {
      anyChanges: false
    }
  },
  props: {
    action: {
      type: String,
      required: true
    },
    modalOpened: {
      type: String,
      required: true
    }
  },
  methods: {
    flagChanges () {
      this.anyChanges = true
      // Nach einem erfolgreichen "Insert" unbedingt auf "Edit" umschalten!!!!
      if (this.action === 'add') {
        this.action = 'edit'
      }
      this.close()
    },
    onEsc (event) {
      this.close()
    },
    emitClosed () {
      this.$emit('modalClosed', this.anyChanges)
    },
    close () { // Diese Methode muss unbedingt mit dem 'emitClosed()'-Statement überladen werden!
      this.emitClosed()
    },
    localizedBackendMessage (errorMessage) {
      return this.$t('backend.' + errorMessage)
    },
    attributeLabelForCode (attribute, code) {
      return (code >= 0) ? this.$t(attribute + '.' + code) : '? ' + code
    },
    localizedUnit (valueTypeCode) {
      return this.attributeLabelForCode('physicalUnit', valueTypeCode)
    }
  }
}
