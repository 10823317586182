<template>
  <form @submit.prevent="saveDevice" ref="cdcDeviceEditForm">
    <div class="modal" tabindex="-1" role="dialog" backdrop="static" id="editCDCDeviceModal" @keyup.esc="onEsc($event)">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header header">
            <h3 class="modal-title"><span v-if="action === 'add'">{{ $t('editCDCDeviceModal.titleAdd') }}</span><span v-if="action === 'edit'">{{ $t('editCDCDeviceModal.titleEdit') }}</span></h3>
            <button type="button" class="close" @click="close" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="form">
              <div v-show="errorMessage" class="alert alert-danger failed">{{ errorMessage }}</div>
              <div class="form-group">
                <label for="facilityName">{{ $t('editCDCDeviceModal.facilityName') }}</label>
                <div id="facilityName" class="blue-text">{{ selectedFacility.name }}</div>
              </div>
              <div class="form-group">
                <label for="enabledInput">{{ $t('editCDCDeviceModal.enabeldInput') }}</label>
                &nbsp;&nbsp;<input type="checkbox" v-model="cdcDevice.enabled" id="enabledInput">
              </div>
              <div class="form-group">
                <label for="nameInput">{{ $t('editCDCDeviceModal.nameInput') }}</label>
                <input type="text" class="form-control" :class="{ 'is-invalid': $v.cdcDevice.name.$dirty && $v.cdcDevice.name.$error }" id="nameInput" v-model.trim="cdcDevice.name" :placeholder="$t('editCDCDeviceModal.namePlaceholder')" maxlength="50" />
                <div class="invalid-feedback" v-if="$v.cdcDevice.name.$dirty">
                  <div class="error" v-if="!$v.cdcDevice.name.maxLength">{{ $t('editCDCDeviceModal.nameMaxLength', { max: $v.cdcDevice.name.$params.maxLength.max }) }}</div>
                </div>
              </div>
              <div class="form-group">
                <label for="apikeyInput">{{ $t('editCDCDeviceModal.apikeyInput') }}</label>
                <input type="text" class="form-control" :class="{ 'is-invalid': $v.cdcDevice.apikey.$dirty && $v.cdcDevice.apikey.$error }" id="apikeyInput" v-model.trim="cdcDevice.apikey" :placeholder="$t('editCDCDeviceModal.apikeyPlaceholder')" maxlength="255" />
                <div class="invalid-feedback" v-if="$v.cdcDevice.apikey.$dirty">
                  <div class="error" v-if="!$v.cdcDevice.apikey.required">{{ $t('editCDCDeviceModal.apikeyRequired') }}</div>
                  <div class="error" v-if="!$v.cdcDevice.apikey.minLength">{{ $t('editCDCDeviceModal.apikeyMinLength', { min: $v.cdcDevice.apikey.$params.minLength.min }) }}</div>
                  <div class="error" v-if="!$v.cdcDevice.apikey.maxLength">{{ $t('editCDCDeviceModal.apikeyMaxLength', { max: $v.cdcDevice.apikey.$params.maxLength.max }) }}</div>
                </div>
              </div>
              <div class="form-group">
                <label for="lastseenInput">{{ $t('editCDCDeviceModal.lastseenInput') }}</label>
                <div class="lastseenInput-input">
                  <vue-ctk-date-time-picker v-model="cdcDevice.lastseen" id="lastseenInput" noLabel="true" :button-now-translation="$t('editCDCDeviceModal.lastseenNow')" format="YYYY-MM-DDTHH:mm:ssZ" />
                </div>
              </div>
              <div class="form-group">
                <label for="serialnumberInput">{{ $t('editCDCDeviceModal.serialnumberInput') }}</label>
                <input type="text" class="form-control" :class="{ 'is-invalid': $v.cdcDevice.serialnumber.$dirty && $v.cdcDevice.serialnumber.$error }" id="serialnumberInput" v-model.trim="cdcDevice.serialnumber" :placeholder="$t('editCDCDeviceModal.serialnumberPlaceholder')" maxlength="255" />
                <div class="invalid-feedback" v-if="$v.cdcDevice.serialnumber.$dirty">
                  <div class="error" v-if="!$v.cdcDevice.serialnumber.required">{{ $t('editCDCDeviceModal.serialnumberRequired') }}</div>
                  <div class="error" v-if="!$v.cdcDevice.serialnumber.minLength">{{ $t('editCDCDeviceModal.serialnumberMinLength', { min: $v.cdcDevice.serialnumber.$params.minLength.min }) }}</div>
                  <div class="error" v-if="!$v.cdcDevice.serialnumber.maxLength">{{ $t('editCDCDeviceModal.serialnumberMaxLength', { max: $v.cdcDevice.serialnumber.$params.maxLength.max }) }}</div>
                </div>
              </div>
              <div class="form-group">
                <label for="notificationstatusInput">{{ $t('editCDCDeviceModal.notificationstatusInput') }}</label>
                &nbsp;&nbsp;<input type="checkbox" v-model="cdcDevice.notificationstatus" id="notificationstatusInput">
              </div>
              <div class="form-group">
                <label for="statusInput">{{ $t('editCDCDeviceModal.statusInput') }}</label>
                <select class="form-control" id="statusInput" v-model="cdcDevice.status" @change="checkStatus">
                  <option v-for="deviceStatus in deviceStatusList" :value="deviceStatus" :key="deviceStatus">
                    {{ deviceStatusString(deviceStatus) }}
                  </option>
                </select>
                <!--
                <input type="text" class="form-control" :class="{ 'is-invalid': $v.cdcDevice.status.$dirty && $v.cdcDevice.status.$error }" id="statusInput" v-model.trim="cdcDevice.status" @change="checkStatus" :placeholder="$t('editCDCDeviceModal.statusPlaceholder')" maxlength="2" />
                -->
                <div class="invalid-feedback" v-if="$v.cdcDevice.status.$dirty">
                  <div class="error" v-if="!$v.cdcDevice.status.required">{{ $t('editCDCDeviceModal.statusRequired') }}</div>
                  <div class="error" v-if="!$v.cdcDevice.status.integer || !$v.cdcDevice.status.between">{{ $t('editCDCDeviceModal.statusNumeric') }}</div>
                </div>
              </div>
              <div class="form-group">
                <label for="commandargumentsInput">{{ $t('editCDCDeviceModal.commandargumentsInput') }}</label>
                <textarea-autosize rows="1" class="form-control" :id="commandargumentsInput" v-model.trim="cdcDevice.commandarguments" maxlength="1024" :min-height="15" />
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="submit" class="btn btn-primary"><span v-if="action === 'add'">{{ $t('editCDCDeviceModal.buttonAdd') }}</span><span v-if="action === 'edit'">{{ $t('editCDCDeviceModal.buttonSave') }}</span></button>
            <button type="button" class="btn btn-secondary" @click="close">{{ $t('editCDCDeviceModal.close') }}</button>
          </div>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import $ from 'jquery'
import moment from 'moment'
import { required, integer, between, minLength, maxLength } from 'vuelidate/lib/validators'
import cdcdeviceService from '@/services/cdcdevices'
import ModalViewMixin from '@/mixins/ModalViewMixin.js'

export default {
  name: 'EditCDCDeviceModal',
  mixins: [ModalViewMixin],
  props: {
    selectedFacility: {
      type: Object,
      required: true
    },
    selectedCDCDevice: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      cdcDevice: {},
      deviceStatusList: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13],
      errorMessage: ''
    }
  },
  filters: {
    formatDate (date) {
      if (date) {
        return moment(String(date)).format('DD.MM.YYYY HH:mm')
      }
    }
  },
  watch: {
    modalOpened () {
      this.copyCDCDevice()
      this.triggerFocus()
    }
  },
  validations: {
    cdcDevice: {
      name: {
        maxLength: maxLength(50)
      },
      apikey: {
        required,
        minLength: minLength(1),
        maxLength: maxLength(255)
      },
      lastseen: {
        required
      },
      serialnumber: {
        required,
        minLength: minLength(1),
        maxLength: maxLength(100)
      },
      enabled: {
        required
      },
      notificationstatus: {
        required
      },
      status: {
        required,
        integer,
        between: between(0, 12)
      },
      commandarguments: {
        maxLength: maxLength(1024)
      }
    }
  },
  mounted () {
    $('#editCDCDeviceModal').on('shown.bs.modal', () => {
      this.triggerFocus()
    })
  },
  methods: {
    copyCDCDevice () {
      this.cdcDevice = Object.assign({}, this.selectedCDCDevice)
    },
    saveDevice () {
      this.$v.$touch()
      if (this.$v.$invalid) {
        return
      }

      if (this.action === 'add') {
        cdcdeviceService.addCDCDevice(this.cdcDevice).then((createdCDCDevice) => {
          this.flagChanges()
        }).catch(error => {
          this.errorMessage = this.localizedBackendMessage(error.message)
        })
      } else if (this.action === 'edit') {
        // see 'MERICK ServerMER-79'
        this.checkStatus()
        cdcdeviceService.updateCDCDevice(this.cdcDevice.id, this.cdcDevice).then((updatedCDCDevice) => {
          this.flagChanges()
        }).catch(error => {
          this.errorMessage = this.localizedBackendMessage(error.message)
        })
      }
    },
    close () {
      this.$v.$reset()
      this.errorMessage = ''
      // zurück zum ursprünglichen 'selected cdcDevice',
      // um etwaigen Änderungen im Formular loszuwerden
      this.copyCDCDevice()
      //
      // this.$refs.cdcDeviceEditForm.reset()
      this.emitClosed()
      $('#editCDCDeviceModal').modal('hide')
    },
    triggerFocus () {
      // Alle 'textarea-autosize' berühren!
      $('#serialnumberInput').trigger('focus')
      $('#apikeyInput').trigger('focus')
      $('#commandargumentsInput').trigger('focus')
    },
    checkStatus () {
      // see 'MERICK ServerMER-79'
      if (this.cdcDevice.status < 2) {
        this.cdcDevice.commandarguments = ''
        $('#commandargumentsInput').value = ''
      }
    },
    deviceStatusString (status) {
      return this.attributeLabelForCode('deviceStatus', status)
    }
  }
}
</script>

<style lang="scss" scoped>
.modal {
  .modal-dialog {
    width: 400px;
  }

  .blue-text {
    color: #0069d9;
    font-weight: 700;
  }

  .td-right {
    text-align: right;
  }
}
</style>
