<template>
  <form @submit.prevent="saveSensorTotal" ref="sensorTotalEditForm">
    <div class="modal" tabindex="-1" role="dialog" backdrop="static" id="editSensorTotalModal" @keyup.esc="onEsc($event)">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header header">
            <h3 class="modal-title">{{ $t('editSensorTotalModal.titleAdd') }}</h3>
            <button type="button" class="close" @click="close" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="form">
              <div v-show="errorMessage" class="alert alert-danger failed">{{ errorMessage }}</div>
              <div class="form-group">
                <label for="sensorName">{{ $t('editSensorTotalModal.sensorName') }}</label>
                <div id="sensorName" class="blue-text">{{ selectedSensor.name }}</div>
              </div>
              <div class="form-group">
                <label for="valueInput">{{ $t('editSensorTotalModal.valueInput') }} <span v-if="payload.type >= 0">&nbsp;/&nbsp; {{ localizedUnit(payload.type) }}</span></label>
                <input type="text" class="form-control" :class="{ 'is-invalid': $v.payload.value.$dirty && $v.payload.value.$error }" v-model="payload.value" id="valueInput" />
                <div class="invalid-feedback" v-if="$v.payload.value.$dirty">
                  <div class="error" v-if="!$v.payload.value.required">{{ $t('editSensorTotalModal.valueRequired') }}</div>
                  <div class="error" v-if="!$v.payload.value.decimal">{{ $t('editSensorTotalModal.valueDecimal') }}</div>
                </div>
              </div>
              <div class="form-group">
                <label for="readingTimestamp">{{ $t('editSensorTotalModal.readingInput') }}</label>
                <div>
                  <DateTime ref="readinginput" v-model="payload.reading" id="readingTimestamp"/>
                </div>
                <VueCtkDateTimePicker v-model="payload.reading" noLabel="true" :button-now-translation="$t('editSensorTotalModal.readingNow')" format="YYYY-MM-DDTHH:mm:ssZ" />
                <div class="invalid-feedback" style="display: block;" v-if="invalidReading">
                  <div>{{ $t('editSensorTotalModal.readingError') }}</div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="submit" class="btn btn-primary">{{ $t('editSensorTotalModal.buttonAdd') }}</button>
            <button type="button" class="btn btn-secondary" @click="close">{{ $t('editSensorTotalModal.close') }}</button>
          </div>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import $ from 'jquery'
import moment from 'moment'
import { required, integer, decimal, minValue } from 'vuelidate/lib/validators'
import deviceService from '@/services/cdcdevices'
import ModalViewMixin from '@/mixins/ModalViewMixin.js'
import DateTime from '@/components/DateTime.vue'

export default {
  name: 'EditSensorTotalModal',
  mixins: [ModalViewMixin],
  props: {
    selectedSensor: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      payload: {
        type: -1,
        reading: new Date()
      },
      errorMessage: ''
    }
  },
  watch: {
    modalOpened () {
      const SensorTypePower = 0
      const SensorTypeGas = 1
      const SensorTypeWater = 2

      // const SystemPartTotal = 4
      // const SystemPartTotalSinceStart = 26
      // const ValueTypeImport = 9
      // const ValueTypeVolume = 22

      this.payload.cdcdeviceid = this.selectedSensor.cdcdeviceid
      this.payload.originalid = this.selectedSensor.originalid

      const total = this.selectedSensor.total

      this.payload.systempart = total.systempart // SystemPartTotal or SystemPartTotalSinceStart
      this.payload.factor = 0
      this.payload.type = total.valuetypetotal

      switch (this.selectedSensor.type) {
        case SensorTypePower:
        case SensorTypeGas:
        case SensorTypeWater:
          break
        default:
          this.errorMessage = this.$t('editSensorTotalModal.unsupportedSensorType')
          break
      }

      this.payload.reading = new Date()

      this.triggerFocus()
    }
  },
  validations: {
    payload: {
      cdcdeviceid: {
        required,
        integer,
        minValue: minValue(1)
      },
      originalid: {
        required,
        integer,
        minValue: minValue(0)
      },
      systempart: {
        required,
        integer
      },
      type: {
        required,
        integer
      },
      reading: {
        required
      },
      value: {
        required,
        decimal
      }
    }
  },
  mounted () {
    $('#editSensorTotalModal').on('shown.bs.modal', () => {
      this.triggerFocus()
    })
  },
  computed: {
    invalidReading () {
      return !this.payload.reading || this.payload.reading.length < 25
    },
    checkedReading: { // not used!
      // getter
      get () {
        return this.payload.reading
      },
      set (newValue) {
        if (newValue) {
          const startOfDay = moment(newValue, 'YYYY-MM-DDTHH:mm:ssZ').startOf('day') // something like 'Wed Sep 01 2021 00:00:00 GMT+0200'
          this.payload.reading = startOfDay.format('YYYY-MM-DDTHH:mm:ssZ') // therefore formatting is required!
        } else {
          this.payload.reading = new Date()
        }
      }
    }
  },
  components: {
    DateTime
  },
  methods: {
    saveSensorTotal () {
      this.$v.$touch()
      if (this.$v.$invalid) {
        this.errorMessage = 'this.$v.$invalid'
        return
      }

      //
      // date to epoch seconds!
      //
      const payload = Object.assign({}, this.payload)
      payload.reading = moment(payload.reading, 'YYYY-MM-DDTHH:mm:ssZ').unix()

      deviceService.addSensorTotal(payload).then((answer) => {
        this.close()
      }).catch(error => {
        this.errorMessage = this.localizedBackendMessage(error.message)
      })
    },
    close () {
      this.$v.$reset()
      this.errorMessage = ''
      this.$refs.sensorTotalEditForm.reset()
      this.emitClosed()
      $('#editSensorTotalModal').modal('hide')
    },
    triggerFocus () {
      $('#valueInput').trigger('focus')
      this.$refs.readinginput.selectFirstInput()
    }
  }
}
</script>

<style lang="scss" scoped>
.modal {
  .modal-dialog {
    width: 400px;
  }

  .blue-text {
    color: #0069d9;
    font-weight: 700;
  }

  .td-right {
    text-align: right;
  }
}
</style>
