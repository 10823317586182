<template>
  <div>
    <div class="page-header d-flex justify-content-between">
      <img v-if="currentCompany.logo != ''" :src="'/images/'+currentCompany.logo"  v-bind:alt="currentCompany.name" height="47">
      <div class="logo" @click="goHome">
        <font-awesome-icon icon="home" class="home-icon" />
      </div>

      <!-- <div class="main-menu"> -->
      <div class="btn-toolbar toolbar">
        <div v-if="[1,2,3,4].includes(client.role)" class="dropdown mr-3">
          <button class="btn btn-secondary" type="button" id="mainMenu" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            {{ $t('header.mainMenu.label') }}
          </button>
          <div class="dropdown-menu" aria-labelledby="mainMenu">
            <button v-if="[4].includes(client.role)" class="dropdown-item" type="button" @click="organizationManagement">{{ $t('header.mainMenu.organizations') }}</button>
            <button v-if="[3,4].includes(client.role)" class="dropdown-item" type="button" @click="facilityManagement">{{ $t('header.mainMenu.facilities') }}</button>
            <button v-if="[3,4].includes(client.role)" class="dropdown-item" type="button" @click="userManagement">{{ $t('header.mainMenu.users') }}</button>
            <button v-if="[4].includes(client.role)" class="dropdown-item" type="button" @click="collectionAddressManagement">{{ $t('header.mainMenu.collectionAddresses') }}</button>
          </div>
        </div>
      </div>
      <!-- </div> -->

      <!--
      <div class="search-box flex-fill">
        <div class="search-wrapper">
          <font-awesome-icon icon="search" class="search-icon" />
          <input type="text" v-bind:placeholder="$t('header.search')" class="form-control form-control-sm" />
        </div>
      </div>
      -->
      <div class="client-menu float-right">
        <div class="dropdown">
          <button class="btn dropdown-toggle" type="button" id="profileMenu" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            {{ client.name }} / {{ client.organization.code }}
          </button>
          <div class="dropdown-menu" aria-labelledby="profileMenu">
            <button v-for="(organization, index) in client.organizations"
                          type="button"
                          @click="selectOrganization(organization)"
                          class="dropdown-item" :class="{'selected-menu' : organization.code === client.organization.code}"
                          :key="index"
                        >
              {{ organization.name }}
            </button>
            <button class="dropdown-item" style="color: white;" type="button">... ... ...</button>
            <button class="dropdown-item" type="button" @click="signOut()">{{ $t('header.signOut') }}</button>
          </div>
        </div>
      </div>
    </div>
    <auto-logout />
  </div>
</template>

<script>
import 'bootstrap/dist/js/bootstrap.min'
import AutoLogout from '@/components/AutoLogout.vue'
import LogoutViewMixin from '@/mixins/LogoutViewMixin.js'
import { mapGetters } from 'vuex'

export default {
  name: 'PageHeader',
  mixins: [LogoutViewMixin],
  components: {
    'auto-logout': AutoLogout
  },
  data () {
    return {
      errorMessage: '',
      currentCompany: { logo: '', name: '' }
    }
  },
  computed: {
    ...mapGetters([
      'client'
    ])
    //  currentCompany () {
    // return this.$store.getters.company
    // }
  },
  mounted () {
    this.loadCompanyValues()
    /* eslint-disable no-console */
    // console.log('Page Header')
    /* eslint-disable no-console */
    // console.log(this.$store)
    if (!this.client.authenticated) {
      this.$store.dispatch('getClientData')
    }
  },
  methods: {
    loadCompanyValues () {
      this.$store.dispatch('getCompanyData')
        .then(() => {
          this.currentCompany = this.$store.getters.company
        })
        .catch(() => {
          /* eslint-disable no-console */
          console.log('could not get company data')
        })
    },
    routeTo (path, params) {
      const absolutePath = (path === 'home') ? '/' : '/' + path
      if (this.$route.path !== absolutePath) {
        var argument = { name: path }
        if (params) {
          argument.params = params
        }
        this.$router.push(argument)
      }
    },
    goHome () {
      this.routeTo('home')
    },
    organizationManagement () {
      this.routeTo('organizations')
    },
    facilityManagement () {
      const params = { organizationid: this.client.organization.id, organization: this.client.organization }
      this.routeTo('facilities', params)
      // this.$router.push({ name: 'facilities', params: { organizationid: this.client.organization.id, organization: this.client.organization } })
    },
    userManagement () {
      this.routeTo('users')
    },
    collectionAddressManagement () {
      this.routeTo('collections')
    },
    selectOrganization (organization) {
      this.loginWithOrganization(organization, this.client.name)
    }
  }
}
</script>

<style lang="scss" scoped>
.page-header {
  padding: 9px 10px 8px;
  border-bottom: 1px solid #eee;
  margin-bottom: 10px;

  .logo {
    color: #444;
    height: 47px;
    width: 218px;
    margin-top: 2px;
    cursor: pointer;

    .home-icon {
      font-size: 20px;
      vertical-align: middle;
    }

    .img {
      margin-left: 5px;
      margin-top: 6px;
      width: 80px;
      // vertical-align: bottom;
    }
  }

  .toolbar {
    height: 36px;
  }

  .main-menu {
    padding-left: 20px;
    width: 100px;
  }

  .client-menu {
    width: 200px;
    text-align: right;
  }

  .selected-menu {
    background-color: #b8daff;
  }

  .search-box {
    .search-wrapper {
      width: 300px;
      margin: 0 auto;
      position: relative;
    }

    .search-icon {
      position: absolute;
      top: 8px;
      left: 8px;
      color: #666;
    }

    input {
      padding-left: 30px;
      height: calc(1.8125rem + 5px);
      font-size: 1rem;
      border: 1px solid #eee;
      border-radius: 5px;
    }
  }
}

.dropdown-toggle {
  padding: 2px 5px !important;
}
</style>
