//
//  Basisfunktion für aktiven Logout
//

import clientService from '@/services/client'

export default {
  methods: {
    signOut () {
      clientService.signOut().then(() => {
        this.$store.dispatch('logout')
        this.$router.push({ name: 'login' })
      }).catch(error => {
        this.errorMessage = this.localizedBackendMessage(error.message)
      })
    },
    loginWithOrganization (organization, username) {
      const params = { organization: organization, username: username }
      clientService.signOut().then(() => {
        this.$store.dispatch('logout')
        this.$router.push({ name: 'login', params: params })
      }).catch(error => {
        this.errorMessage = this.localizedBackendMessage(error.message)
      })
    },
    localizedBackendMessage (errorMessage) {
      return this.$t('backend.' + errorMessage)
    }
  }
}
