<template>
  <div class="container public">
    <page-header />
    <div class="content-container">
      <div class="<overview-list-section">
        <h2 class="section-title">{{ $t('sensorEventsPage.title') }} <span v-if="afterLoad && selectedSensor" class="blue-text"> {{ selectedSensor.name }} ({{ sensorType(selectedSensor.type) }}) / {{ deviceForSensor.apikey }} / {{ facilityForDevice.name }} / {{ organizationForFacility.code }} </span></h2>
        <div class="btn-toolbar">
          <button type="button" class="btn btn-info mr-3" @click="backToSensors">
            <font-awesome-icon icon="arrow-alt-circle-left" /> &nbsp; {{ $t('sensorEventsPage.backToSensors') }}
          </button>
         <button v-if="userMayEdit" type="button" class="btn btn-primary mr-3" @click="showDetail('add')">
           <font-awesome-icon icon="plus" /> &nbsp; {{ $t('sensorEventsPage.addButton') }}
         </button>
         <button  v-if="userMayEdit" type="button" class="btn btn-secondary  mr-3" @click="showDetail('edit')"
           :disabled="this.selectedSensorEventId === -1"><font-awesome-icon icon="edit" />&nbsp; {{ $t('sensorEventsPage.editButton') }}</button>
         <button  v-if="userMayEdit" type="button" class="btn btn-secondary  mr-3" @click="askDelete"
           :disabled="this.selectedSensorEventId  === -1"><font-awesome-icon icon="trash" />&nbsp; {{ $t('sensorEventsPage.deleteButton') }}</button>
        </div>
        <error-row @clearError="clearError" :errorMessage="errorMessage" />
        <div v-show="isLoading" class="is-loading-section" >
          <button class="btn btn-primary"><span class="spinner-border spinner-border-sm"></span></button><span> {{ $t('sensorEventsPage.isLoading') }} </span>
        </div>
        <div v-show="!isLoading && hasSensorevents" id="table-section" >
          <table class="table table-hover auto-col">
            <thead>
              <tr>
                <th v-if="userMayEdit">&nbsp;</th>
                <th @click="sort('enabled')">{{ $t('sensorEventsPage.columnEnabled') }}</th>
                <th @click="sort('sensoralerteventcode')">{{ $t('sensorEventsPage.columnAlertEvent') }}</th>
                <th @click="sort('sensoralertstatuscode')">{{ $t('sensorEventsPage.columnAlertStatus') }}</th>
                <th @click="sort('systempart')">{{ $t('sensorEventsPage.columnSystemPart') }}</th>
                <th @click="sort('type')">{{ $t('sensorEventsPage.columnUnit') }}</th>
                <th @click="sort('value')">{{ $t('sensorEventsPage.columnValue') }}</th>
                <th @click="sort('threshold')">{{ $t('sensorEventsPage.columnThreshold') }}</th>
                <th @click="sort('message')">{{ $t('sensorEventsPage.columnMessage') }}</th>
                <th @click="sort('intermission')">{{ $t('sensorEventsPage.columnIntermission') }}</th>
                <th @click="sort('combinedsensoreventid')">{{ $t('sensorEventsPage.columnCombinedEvent') }}</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(sensorEvent, index) in sortedSensorEvents"
                @click="selectRow(sensorEvent)"
                :class="{'table-primary' : isSensorEventSelected(sensorEvent.id)}"
                :key="index"
              >
                <td v-if="userMayEdit"><span @click.stop="editSensorEvent(sensorEvent)"><font-awesome-icon icon="edit" /></span></td>
                <td class="text-center">
                  <span v-if="sensorEvent.enabled">{{ $t('cdcSensorsPage.booleanTrue') }}</span>
                  <span v-if="!sensorEvent.enabled">{{ $t('cdcSensorsPage.booleanFalse') }}</span>
                </td>
                <td> {{ localizedSensorAlertEvent(sensorEvent.sensoralerteventcode) }} </td>
                <td> {{ localizedSensorAlertStatus(sensorEvent.sensoralertstatuscode) }} </td>
                <td class="text-center"> {{ localizedPart(sensorEvent.systempart) }} </td>
                <td class="text-center"> {{ localizedUnit(sensorEvent.type) }} </td>
                <td class="text-center"> {{ sensorEvent.value }} </td>
                <td class="text-center"> {{ sensorEvent.threshold }} </td>
                <td> {{ sensorEvent.message }} </td>
                <td class="text-center"> {{ sensorEvent.intermission }} </td>
                <td class="text-center"> {{ sensorEvent.combinedsensoreventid }} </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <page-footer />

    <edit-sensor-event-modal @modalClosed="modalClosed" :parentFacility="facilityForDevice" :parentDevice="deviceForSensor" :parentSensor="selectedSensor" :selectedSensorEvent="selectedSensorEvent" :action="selectedAction" :modalOpened="modalTimestamp" />

    <confirm-modal
      :title="$t('sensorEventsPage.deleteSensorEvent')"
      :text="confirmDeleteText"
      :confirmButtonLabel="$t('confirmModal.confirmButtonLabel')"
      :cancelButtonLabel="$t('confirmModal.cancelButtonLabel')"
      @confirm="confirmDelete"
      @modalClosed="modalClosed"
    />
  </div>
</template>

<script>
import objectSorter from '@/utils/object-sorter'
import PageHeader from '@/components/PageHeader.vue'
import ErrorRow from '@/components/ErrorRow.vue'
import PageFooter from '@/components/PageFooter.vue'
import ConfirmModal from '@/modals/ConfirmModal.vue'
import PageViewMixin from '@/mixins/PageViewMixin.js'
import EditSensorEventModal from '@/modals/EditSensorEventModal.vue'
import cdcsensorService from '@/services/cdcsensors'

import { mapGetters } from 'vuex'

export default {
  name: 'SensorEventsPage',
  mixins: [PageViewMixin],
  data () {
    return {
      cdcSensorId: 0,
      selectedSensorEvent: null,
      selectedSensor: { id: -1, cdcdeviceid: -1, name: '' },
      selectedAction: 'add',
      currentSortKey: 'sensoralertstatuscode',
      currentSortOrder: 'asc',
      isLoading: false,
      afterLoad: false
    }
  },
  mounted () {
    this.afterLoad = false
    this.clearError()
    this.cdcSensorId = this.$route.params.cdcsensorid
    if (this.cdcSensorId > 0) {
      this.selectedSensor = { id: this.cdcSensorId, cdcdeviceid: -1, name: '' }
      this.loadSensorEvents(true)
    }
  },
  destroyed () {
    this.$store.dispatch('discardSensorEventData')
  },
  computed: {
    ...mapGetters([
      'client',
      'hasOrganizations',
      'organizationMap',
      'facilities',
      'hasFacilities',
      'facilityMap',
      'cdcdevices',
      'cdcDeviceMap',
      'cdcsensors',
      'hasCdcsensors',
      'cdcSensorMap',
      'sensorevents',
      'hasSensorevents'
    ]),
    selectedSensorEventId () {
      return this.selectedSensorEvent ? this.selectedSensorEvent.id : -1
    },
    emptySensorEvent () {
      return {
        id: -1,
        cdcsensorid: this.selectedSensor.id,
        sensoralerteventcode: 0,
        sensoralertstatuscode: 0,
        systempart: 0,
        type: 0,
        value: 0.0,
        threshold: 0.0,
        message: '',
        intermission: 300,
        combinedsensoreventid: -1
      }
    },
    confirmDeleteText () {
      if (this.selectedSensorEvent) {
        return this.$t('sensorEventsPage.confirmDeleteText', { id: this.selectedSensorEvent.id })
      }
      return ''
    },
    sortedSensorEvents () {
      return objectSorter.sortedArray(this.sensorevents, this.currentSortOrder, this.currentSortKey)
    },
    deviceForSensor () {
      return this.selectedSensor.cdcdeviceid > 0 && this.hasCdcsensors ? this.cdcDeviceMap[this.selectedSensor.cdcdeviceid] : { id: -1, apikey: '', facilityid: -1 }
    },
    facilityForDevice () {
      return this.deviceForSensor.facilityid > 0 && this.hasFacilities ? this.facilityMap[this.deviceForSensor.facilityid] : { id: -1, name: '', organizationid: -1 }
    },
    organizationForFacility () {
      return this.facilityForDevice.organizationid > 0 && this.hasOrganizations ? this.organizationMap[this.facilityForDevice.organizationid] : { name: '', code: '' }
    }
  },
  components: {
    'page-header': PageHeader,
    'error-row': ErrorRow,
    'page-footer': PageFooter,
    'edit-sensor-event-modal': EditSensorEventModal,
    'confirm-modal': ConfirmModal
  },
  watch: {
    cdcsensors () {
      if (!this.running) {
        this.dataHaveBeenLoaded()
        this.afterLoad = true
      }
    }
  },
  methods: {
    loadSensorEvents (reset) {
      this.afterLoad = false
      this.isLoading = true
      const payload = { cdcSensorId: this.selectedSensor.id }
      this.$store.commit('clearError')
      this.$store.dispatch('getSensorEventData', payload)
      if (reset) {
        this.selectedSensorEvent = this.emptySensorEvent
      }
    },
    backToSensors () {
      this.$router.push({ name: 'cdcsensors', params: { cdcdeviceid: this.selectedSensor.cdcdeviceid } })
    },
    isSensorEventSelected (id) {
      return this.selectedSensorEvent && id === this.selectedSensorEvent.id
    },
    selectRow (sensorEvent) {
      if (this.selectedSensorEvent !== sensorEvent) {
        this.selectedSensorEvent = sensorEvent
      } else {
        this.selectedSensorEvent = this.emptySensorEvent
      }
    },
    showDetail (action) {
      if (action === 'add') {
        this.selectedSensorEvent = this.emptySensorEvent
        this.selectedAction = 'add'
      } else {
        this.selectedAction = 'edit'
      }
      this.showModalView()
    },
    editSensorEvent (sensorEvent) {
      this.selectedSensorEvent = sensorEvent
      this.selectedAction = 'edit'
      this.showModalView()
    },
    showModalView () {
      this.openModal('#editSensorEventModal')
    },
    confirmDelete () {
      cdcsensorService.deleteSensorEvent(this.selectedSensorEvent.id).then((deletedSensorEvent) => {
        this.loadSensorEvents(true)
      }).catch(error => {
        this.errorMessage = this.localizedBackendMessage(error.message)
      })
    },
    sort (key) {
      // reverse sort order?
      if (key === this.currentSortKey) {
        this.currentSortOrder = this.currentSortOrder === 'asc' ? 'desc' : 'asc'
      }
      this.currentSortKey = key
    },
    reloadData () {
      this.loadSensorEvents(false)
    },
    dataHaveBeenLoaded () {
      if (this.cdcSensorId > 0 && this.hasCdcsensors) {
        this.selectedSensor = this.cdcSensorMap[this.cdcSensorId]
      }
    },
    localizedSensorAlertEvent (code) {
      return this.attributeLabelForCode('sensorAlertEvent', code)
    },
    localizedSensorAlertStatus (code) {
      return this.attributeLabelForCode('sensorAlertStatus', code)
    },
    localizedUnit (valueTypeCode) {
      return this.attributeLabelForCode('physicalUnit', valueTypeCode)
    },
    localizedPart (systemPart) {
      return this.attributeLabelForCode('systemPart', systemPart)
    },
    sensorType (type) {
      return this.attributeLabelForCode('sensorType', type)
    }
  }
}
</script>

<style lang="scss" scoped>
.content-container {
  padding: 0 35px;

  h2 {
    font-size: 18px;
    margin-bottom: 15px;
    font-weight: 400;
  }

  .magazine-list-section {
    margin: 30px 10px;
  }

  .representation-hierarchy {
    margin-top: 30px;
  }

  table.auto-col {
    table-layout: auto;
  }

  .grey {
    color: #808080;
  }

  .blue-text {
    color: #0069d9;
    font-weight: 700;
  }
  .toggle-icon {
    margin-left: 20px;
  }
}
</style>
