import axios from 'axios'
import errorParser from '@/utils/error-parser'

export default {
  /**
   * Fetch current users
   */
  getUserData () {
    return new Promise((resolve, reject) => {
      axios.get('/users').then(({ data }) => {
        resolve(data)
      }).catch((error) => {
        reject(errorParser.parse(error))
      })
    })
  },

  /**
   * Add a new user
   * @param {Object} user detail
   */
  addUser (detail) {
    return new Promise((resolve, reject) => {
      axios.post('/users', detail).then(({ data }) => {
        resolve(data)
      }).catch((error) => {
        reject(errorParser.parse(error))
      })
    })
  },

  /**
   * Update existing user
   * @param {Integer} userId
   * @param {Object} user detail
   */
  updateUser (userId, detail) {
    return new Promise((resolve, reject) => {
      axios.put('/users/' + userId, detail).then(({ data }) => {
        resolve(data)
      }).catch((error) => {
        reject(errorParser.parse(error))
      })
    })
  },

  /**
   * Delete an existing user
   * @param {Integer} userId
   */
  deleteUser (userId) {
    return new Promise((resolve, reject) => {
      axios.delete('/users/' + userId).then(({ data }) => {
        resolve(data)
      }).catch((error) => {
        reject(errorParser.parse(error))
      })
    })
  },

  /**
   * Fetch all push tokens for a certain user
   */
  getUserPushTokensData (userId) {
    return new Promise((resolve, reject) => {
      axios.get('/userpushtokens/' + userId).then(({ data }) => {
        resolve(data)
      }).catch((error) => {
        reject(errorParser.parse(error))
      })
    })
  },

  /**
   * Add a new user push token
   * @param {Object} push token detail
   */
  addUserPushToken (detail) {
    return new Promise((resolve, reject) => {
      axios.post('/userpushtokens', detail).then(({ data }) => {
        resolve(data)
      }).catch((error) => {
        reject(errorParser.parse(error))
      })
    })
  },

  /**
   * Update existing user push token
   * @param {Integer} push token ID
   * @param {Object} user push token detail
   */
  updateUserPushToken (userPushTokenId, detail) {
    return new Promise((resolve, reject) => {
      axios.put('/userpushtokens/' + userPushTokenId, detail).then(({ data }) => {
        resolve(data)
      }).catch((error) => {
        reject(errorParser.parse(error))
      })
    })
  },

  /**
   * Delete an existing UserPushToken
   * @param {Integer} userPushTokenId
   */
  deleteUserPushToken (userPushTokenId) {
    return new Promise((resolve, reject) => {
      axios.delete('/userpushtokens/' + userPushTokenId).then(({ data }) => {
        resolve(data)
      }).catch((error) => {
        reject(errorParser.parse(error))
      })
    })
  },

  /**
   * Refresh an existing push token or create a new one
   * @param {Object} push token detail
   */
  refreshUserPushToken (detail) {
    return new Promise((resolve, reject) => {
      axios.post('/refreshpushtoken', detail).then(({ data }) => {
        resolve(data)
      }).catch((error) => {
        reject(errorParser.parse(error))
      })
    })
  }
}
