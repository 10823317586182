<template>
  <form @submit.prevent="saveCollection" ref="collectionEditForm">
    <div class="modal" tabindex="-1" role="dialog" backdrop="static" id="editCollectionModal" @keyup.esc="onEsc($event)">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header header">
            <h3 class="modal-title"><span v-if="action === 'add'">{{ $t('editCollectionModal.titleAdd') }}</span><span v-if="action === 'edit'">{{ $t('editCollectionModal.titleEdit') }}</span></h3>
            <button type="button" class="close" @click="close" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="form">
              <div v-show="errorMessage" class="alert alert-danger failed">{{ errorMessage }}</div>
              <div v-if="action === 'edit'" class="form-group">
                <label for="nameInput">{{ $t('editCollectionModal.created') }}</label>
                <div> {{ collection.created | formatDate }} </div>
              </div>
              <div class="form-group">
                <label for="hexaddressInput">{{ $t('editCollectionModal.hexaddressInput') }}</label>
                <input type="text" class="form-control" :class="{ 'is-invalid': $v.collection.hexaddress.$dirty && $v.collection.hexaddress.$error }" id="hexaddressInput" v-model.trim="collection.hexaddress" :placeholder="$t('editCollectionModal.hexaddressPlaceholder')" maxlength="12" />
                <div class="invalid-feedback" v-if="$v.collection.hexaddress.$dirty">
                  <div class="error" v-if="!$v.collection.hexaddress.required">{{ $t('editCollectionModal.hexaddressRequired') }}</div>
                  <div class="error" v-if="!$v.collection.hexaddress.minLength">{{ $t('editCollectionModal.hexaddressMinLength', { min: $v.collection.hexaddress.$params.minLength.min }) }}</div>
                  <div class="error" v-if="!$v.collection.hexaddress.maxLength">{{ $t('editCollectionModal.hexaddressMaxLength', { max: $v.collection.hexaddress.$params.maxLength.max }) }}</div>
                </div>
              </div>
              <div class="form-group">
                <label for="nameInput">{{ $t('editCollectionModal.nameInput') }}</label>
                <input type="text" class="form-control" :class="{ 'is-invalid': $v.collection.name.$dirty && $v.collection.name.$error }" id="nameInput" v-model.trim="collection.name" :placeholder="$t('editCollectionModal.namePlaceholder')" maxlength="50" />
                <div class="invalid-feedback" v-if="$v.collection.name.$dirty">
                  <div class="error" v-if="!$v.collection.name.maxLength">{{ $t('editCollectionModal.nameMaxLength', { max: $v.collection.name.$params.maxLength.max }) }}</div>
                </div>
              </div>
              <div class="form-group">
                <label for="typeInputSelect">{{ $t('editCollectionModal.typeInput') }}</label>
                <select class="form-control" id="typeInputSelect" v-model="collection.sensortype" >
                  <option v-for="cdcSensorType in cdcSensorTypes" :value="cdcSensorType" :key="cdcSensorType">
                    {{ sensorTypeName(cdcSensorType) }}
                  </option>
                </select>
                <div class="invalid-feedback" v-if="$v.collection.sensortype.$dirty">
                  <div class="error" v-if="!$v.collection.sensortype.required">{{ $t('editCollectionModal.typeRequired') }}</div>
                  <div class="error" v-if="!$v.collection.sensortype.integer || !$v.collection.sensortype.between">{{ $t('editCollectionModal.typeNumeric') }}</div>
                </div>
              </div>
              <div class="form-group">
                <label for="systemPartTotalInputSelect">{{ $t('editCollectionModal.systemPartTotalInput') }}</label>
                <select class="form-control" id="systemPartTotalInputSelect" v-model="collection.systemparttotal" >
                  <option v-for="systemPart in systemParts" :value="systemPart" :key="systemPart">
                    {{ systemPartName(systemPart) }}
                  </option>
                </select>
                <div class="invalid-feedback" v-if="$v.collection.systemparttotal.$dirty">
                  <div class="error" v-if="!$v.collection.systemparttotal.integer || !$v.collection.systemparttotal.between">{{ $t('editCollectionModal.systemPartTotalNumeric') }}</div>
                </div>
              </div>
              <div class="form-group">
                <label for="valueTypeTotalInputSelect">{{ $t('editCollectionModal.valueTypeTotalInput') }}</label>
                <select class="form-control" id="valueTypeTotalInputSelect" v-model="collection.valuetypetotal" >
                  <option v-for="valueType in valueTypes" :value="valueType" :key="valueType">
                    {{ valueTypeName(valueType) }}
                  </option>
                </select>
                <div class="invalid-feedback" v-if="$v.collection.valuetypetotal.$dirty">
                  <div class="error" v-if="!$v.collection.valuetypetotal.integer || !$v.collection.valuetypetotal.between">{{ $t('editCollectionModal.valueTypeTotalNumeric') }}</div>
                </div>
              </div>
              <div class="form-group">
                <label for="serialnrInput">{{ $t('editCollectionModal.serialnrInput') }}</label>
                <input type="text" class="form-control" :class="{ 'is-invalid': $v.collection.serialnr.$dirty && $v.collection.name.$error }" id="serialnrInput" v-model.trim="collection.serialnr" :placeholder="$t('editCollectionModal.serialnrPlaceholder')" maxlength="50" />
                <div class="invalid-feedback" v-if="$v.collection.serialnr.$dirty">
                  <div class="error" v-if="!$v.collection.serialnr.maxLength">{{ $t('editCollectionModal.serialnrMaxLength', { max: $v.collection.serialnr.$params.maxLength.max }) }}</div>
                </div>
              </div>
              <div class="form-group">
                <label for="jsonInput">{{ $t('editCollectionModal.jsonInput') }}</label>
                <textarea-autosize rows="1" class="form-control" :class="{ 'is-invalid': $v.collection.json.$dirty && $v.collection.json.$error }" id="jsonInput" v-model.trim="collection.json" :placeholder="$t('editCollectionModal.jsonPlaceholder')" maxlength="1024" :max-height="300" />
                <div class="invalid-feedback" v-if="$v.collection.json.$dirty">
                  <div class="error" v-if="!$v.collection.json.url">{{ $t('editCollectionModal.jsonUrlError') }}</div>
                  <div class="error" v-if="!$v.collection.json.maxLength">{{ $t('editCollectionModal.jsonMaxLength', { max: $v.collection.json.$params.maxLength.max }) }}</div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="submit" class="btn btn-primary"><span v-if="action === 'add'">{{ $t('editCollectionModal.buttonAdd') }}</span><span v-if="action === 'edit'">{{ $t('editCollectionModal.buttonSave') }}</span></button>
            <button type="button" class="btn btn-secondary" @click="close">{{ $t('editCollectionModal.close') }}</button>
          </div>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import $ from 'jquery'
import moment from 'moment'
import { required, integer, between, minLength, maxLength } from 'vuelidate/lib/validators'
import collectionService from '@/services/collections'
import ModalViewMixin from '@/mixins/ModalViewMixin.js'

export default {
  name: 'EditCDCSensorModal',
  mixins: [ModalViewMixin],
  props: {
    selectedCollection: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      collection: {},
      errorMessage: '',
      cdcSensorTypes: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
      systemParts: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32],
      valueTypes: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33]
    }
  },
  watch: {
    modalOpened () {
      this.copyCollection()
      this.triggerFocus()
    }
  },
  validations: {
    collection: {
      hexaddress: {
        required,
        minLength: minLength(12),
        maxLength: maxLength(12)
      },
      name: {
        maxLength: maxLength(50)
      },
      sensortype: {
        required,
        integer,
        between: between(0, 10)
      },
      systemparttotal: {
        integer,
        between: between(0, 32)
      },
      valuetypetotal: {
        integer,
        between: between(0, 33)
      },
      serialnr: {
        maxLength: maxLength(50)
      },
      json: {
        maxLength: maxLength(1024)
      }
    }
  },
  mounted () {
    $('#editCollectionModal').on('shown.bs.modal', () => {
      this.triggerFocus()
    })
  },
  filters: {
    formatDate (date) {
      if (date) {
        return moment(String(date)).format('DD.MM.YYYY HH:mm')
      }
    }
  },
  methods: {
    copyCollection () {
      this.collection = Object.assign({}, this.selectedCollection)
    },
    saveCollection () {
      this.$v.$touch()
      if (this.$v.$invalid) {
        return
      }
      if (this.action === 'add') {
        collectionService.addCollection(this.collection).then((createdCollection) => {
          this.flagChanges()
        }).catch(error => {
          this.errorMessage = this.localizedBackendMessage(error.message)
        })
      } else if (this.action === 'edit') {
        collectionService.updateCollection(this.collection.id, this.collection).then((updatedCollection) => {
          this.flagChanges()
        }).catch(error => {
          this.errorMessage = this.localizedBackendMessage(error.message)
        })
      }
    },
    close () {
      this.$v.$reset()
      this.errorMessage = ''
      // zurück zum ursprünglichen 'selected collection',
      // um etwaigen Änderungen im Formular loszuwerden
      this.copyCollection()
      //
      // this.$refs.collectionEditForm.reset()
      this.emitClosed()
      $('#editCollectionModal').modal('hide')
    },
    triggerFocus () {
      // Alle 'textarea-autosize' berühren!
      $('#jsonInput').trigger('focus')
      $('#nameInput').trigger('focus')
      $('#hexaddressInput').trigger('focus')
    },
    sensorTypeName (type) {
      return this.attributeLabelForCode('sensorType', type)
    },
    systemPartName (code) {
      return this.attributeLabelForCode('systemPart', code)
    },
    valueTypeName (code) {
      return this.attributeLabelForCode('valueType', code)
    }
  }
}
</script>

<style lang="scss" scoped>
.modal {
  .modal-dialog {
    width: 400px;
  }

  .blue-text {
    color: #0069d9;
    font-weight: 700;
  }

  .td-right {
    text-align: right;
  }
}
</style>
