import axios from 'axios'
import errorParser from '@/utils/error-parser'

export default {
  /**
   * Fetch all cdcsensors for a certain device
   */
  getCDCSensorData (deviceId) {
    return new Promise((resolve, reject) => {
      axios.get('/cdcsensors/' + deviceId).then(({ data }) => {
        resolve(data)
      }).catch((error) => {
        reject(errorParser.parse(error))
      })
    })
  },

  /**
   * Add a new cdcsensor
   * @param {Object} cdcsensor detail
   */
  addCDCSensor (detail) {
    return new Promise((resolve, reject) => {
      axios.post('/cdcsensors', detail).then(({ data }) => {
        resolve(data)
      }).catch((error) => {
        reject(errorParser.parse(error))
      })
    })
  },

  /**
   * Update existing cdcsensor
   * @param {Integer} cdcsensor ID
   * @param {Object} cdcsensor detail
   */
  updateCDCSensor (cdcsensorId, detail) {
    return new Promise((resolve, reject) => {
      axios.put('/cdcsensors/' + cdcsensorId, detail).then(({ data }) => {
        resolve(data)
      }).catch((error) => {
        reject(errorParser.parse(error))
      })
    })
  },

  /**
   * Delete an existing cdcsensor
   * @param {Integer} cdcsensorId
   */
  deleteCDCSensor (cdcsensorId) {
    return new Promise((resolve, reject) => {
      axios.delete('/cdcsensors/' + cdcsensorId).then(({ data }) => {
        resolve(data)
      }).catch((error) => {
        reject(errorParser.parse(error))
      })
    })
  },

  /**
   * Fetch all sensor-event-data for a certain sensor
   */
  getSensorEventData (sensorId) {
    return new Promise((resolve, reject) => {
      axios.get('/sensorevents/' + sensorId).then(({ data }) => {
        resolve(data)
      }).catch((error) => {
        reject(errorParser.parse(error))
      })
    })
  },

  /**
   * Add a new sensor-event
   * @param {Object} cdcsensor detail
   */
  addSensorEvent (detail) {
    return new Promise((resolve, reject) => {
      axios.post('/sensorevents', detail).then(({ data }) => {
        resolve(data)
      }).catch((error) => {
        reject(errorParser.parse(error))
      })
    })
  },

  /**
   * Update existing sensor-event
   * @param {Integer} sensor-event ID
   * @param {Object} sensor-event detail
   */
  updateSensorEvent (sensorEventId, detail) {
    return new Promise((resolve, reject) => {
      axios.put('/sensorevents/' + sensorEventId, detail).then(({ data }) => {
        resolve(data)
      }).catch((error) => {
        reject(errorParser.parse(error))
      })
    })
  },

  /**
   * Delete an existing sensor-event
   * @param {Integer} sensoreventid
   */
  deleteSensorEvent (sensorEventId) {
    return new Promise((resolve, reject) => {
      axios.delete('/sensorevents/' + sensorEventId).then(({ data }) => {
        resolve(data)
      }).catch((error) => {
        reject(errorParser.parse(error))
      })
    })
  }
}
