<template>
  <div class="modal" tabindex="-1" role="dialog" backdrop="static" id="confirmModal" @keyup.esc="onEsc($event)">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title">{{ title }}</h4>
          <button type="button" class="close" @click="close" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="form">
            <div class="form-group">
              <div> {{ text }} </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
            <button type="submit" class="btn btn-primary" @click="confirm">{{ confirmButtonLabel }}</button>
            <button type="button" class="btn btn-secondary" @click="close">{{ cancelButtonLabel }}</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from 'jquery'
import ModalViewMixin from '@/mixins/ModalViewMixin.js'

export default {
  name: 'ConfirmModal',
  mixins: [ModalViewMixin],
  props: ['title', 'text', 'confirmButtonLabel', 'cancelButtonLabel'],
  methods: {
    confirm () {
      this.terminateEmitting('confirm')
    },
    close () { // Override für 'ModalViewMixin'
      this.terminateEmitting('cancel')
    },
    terminateEmitting (message) {
      $('#confirmModal').modal('hide')
      this.$emit(message)
      this.$emit('modalClosed')
    }
  }
}
</script>
