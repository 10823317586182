export default {
  //
  // returns a sorted copy of an array
  // argument 'array' of objects
  // argument 'sortOrder' which is 'asc' or 'desc'
  // argument 'sortKey' which points to field of object
  //
  sortedArray (array, sortOrder, sortKey) {
    const copy = [...array]
    return this.sortArray(copy, sortOrder, sortKey)
  },

  //
  // returns a sorted array
  // argument 'array' of objects
  // argument 'sortOrder' which is 'asc' or 'desc'
  // argument 'sortKey' which points to field of object
  //
  sortArray (array, sortOrder, sortKey) {
    return array.sort((a, b) => {
      const sign = sortOrder === 'asc' ? 1 : -1
      const valueA = a[sortKey]
      const valueB = b[sortKey]
      if (typeof valueA === 'string' && typeof valueB === 'string') {
        return valueA.localeCompare(valueB, undefined, { sensitivity: 'base' }) * sign
      } else if (valueA > valueB) {
        return sign
      } else if (valueA < valueB) {
        return -1 * sign
      }
      return 0
    })
  }
}
