import axios from 'axios'
import errorParser from '@/utils/error-parser'

export default {
  /**
   * Fetch all facilities for a certain organization
   */
  getFacilityData (organizationId) {
    return new Promise((resolve, reject) => {
      axios.get('/facilities/' + organizationId).then(({ data }) => {
        resolve(data)
      }).catch((error) => {
        reject(errorParser.parse(error))
      })
    })
  },

  /**
   * Add a new user facility
   * @param {Object} facility detail
   */
  addFacility (detail) {
    return new Promise((resolve, reject) => {
      axios.post('/facilities', detail).then(({ data }) => {
        resolve(data)
      }).catch((error) => {
        reject(errorParser.parse(error))
      })
    })
  },

  /**
   * Update existing user facility
   * @param {Integer} facility ID
   * @param {Object} user facility detail
   */
  updateFacility (facilityId, detail) {
    return new Promise((resolve, reject) => {
      axios.put('/facilities/' + facilityId, detail).then(({ data }) => {
        resolve(data)
      }).catch((error) => {
        reject(errorParser.parse(error))
      })
    })
  },

  /**
   * Delete an existing facility
   * @param {Integer} facilityId
   */
  deleteFacility (facilityId) {
    return new Promise((resolve, reject) => {
      axios.delete('/facilities/' + facilityId).then(({ data }) => {
        resolve(data)
      }).catch((error) => {
        reject(errorParser.parse(error))
      })
    })
  },

  /**
   * Construct url for mapsnaphost a certain facility
   */
  mapsnaphostUrl (facilityId) {
    return axios.defaults.baseURL + '/facilitymapsnapshot/' + facilityId
  }
}
