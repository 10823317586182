import axios from 'axios'
import errorParser from '@/utils/error-parser'

export default {
  /**
   * Fetch current user's name, authorizations
   */
  getClientData () {
    return new Promise((resolve, reject) => {
      axios.get('/client').then(({ data }) => {
        resolve(data)
      }).catch((error) => {
        reject(errorParser.parse(error))
      })
    })
  },
  signOut () {
    return new Promise((resolve, reject) => {
      axios.post('/client/logout').then(({ data }) => {
        resolve(data)
      }).catch((error) => {
        reject(errorParser.parse(error))
      })
    })
  }
}
