export default {
  updateClientData (state, data) {
    state.client = data.client
    state.client.authenticated = true
    state.client.timestamp = new Date()
    state.running = false
  },
  logout (state) {
    state.client = { name: '', organization: { id: 0, code: '' }, role: 0, authenticated: false }
    state.organization = []
    state.users = []
    state.roles = []
    state.userpushtokens = []
    state.organizations = []
    state.facilities = []
    state.cdcdevices = []
    state.cdcsensors = []
    state.sensorevents = []
    state.cdcdatavalues = []
    state.collections = []
    state.dailyTotals = []
  },
  discardDailyTotals (state) {
    state.dailyTotals = []
  },
  updateDailyTotals (state, data) {
    state.dailyTotals = data
    state.running = false
  },
  discardLatestSensorData (state) {
    state.organizations = []
  },
  updateOrganizationData (state, data) {
    state.organizations = data.organizations
    state.client.timestamp = new Date()
    state.running = false
  },
  discardOrganizationData (state) {
    state.organizations = []
  },
  updateFacilityData (state, data) {
    state.organizations = data.organizations
    state.facilities = data.facilities
    state.client.timestamp = new Date()
    state.running = false
  },
  discardFacilityData (state) {
    state.organizations = []
    state.facilities = []
  },
  updateCDCDeviceData (state, data) {
    state.organizations = data.organizations
    state.facilities = data.facilities
    state.cdcdevices = data.cdcdevices
    state.client.timestamp = new Date()
    state.running = false
  },
  discardCDCDeviceData (state) {
    state.organizations = []
    state.facilities = []
    state.cdcdevices = []
  },
  updateCDCSensorData (state, data) {
    state.organizations = data.organizations
    state.facilities = data.facilities
    state.cdcdevices = data.cdcdevices
    state.cdcsensors = data.cdcsensors
    state.client.timestamp = new Date()
    state.running = false
  },
  discardCDCSensorData (state) {
    state.organizations = []
    state.facilities = []
    state.cdcdevices = []
    state.cdcsensors = []
  },
  updateSensorEventData (state, data) {
    state.organizations = data.organizations
    state.facilities = data.facilities
    state.cdcdevices = data.cdcdevices
    state.cdcsensors = data.cdcsensors
    state.sensorevents = data.sensorevents
    state.client.timestamp = new Date()
    state.running = false
  },
  discardSensorEventData (state) {
    state.organizations = []
    state.facilities = []
    state.cdcdevices = []
    state.cdcsensors = []
    state.sensorevents = []
  },
  updateCDCDataValues (state, data) {
    state.organizations = data.organizations
    state.facilities = data.facilities
    state.cdcdevices = data.cdcdevices
    state.cdcdatavalues = data.cdcdatavalues
    state.client.timestamp = new Date()
    state.running = false
  },
  updateCDCDataValuesSlim (state, data) {
    state.cdcdatavalues = data.cdcdatavalues
    state.client.timestamp = new Date()
    state.running = false
  },
  discardCDCDataValues (state) {
    state.organizations = []
    state.facilities = []
    state.cdcdevices = []
    state.cdcdatavalues = []
  },
  discardCDCDataValuesSlim (state) {
    state.cdcdatavalues = []
  },
  updateUserData (state, data) {
    state.organizations = data.organizations
    state.users = data.users
    state.roles = data.roles
    state.client.timestamp = new Date()
    state.running = false
  },
  discardUserData (state) {
    state.organization = []
    state.users = []
    state.state = []
    state.roles = []
  },
  updateUserPushTokensData (state, data) {
    state.users = data.users
    state.userpushtokens = data.userpushtokens
    state.client.timestamp = new Date()
    state.running = false
  },
  discardUserPushTokensData (state) {
    state.users = []
    state.userpushtokens = []
  },
  updateCollectionsData (state, data) {
    state.collections = data.collections
    state.client.timestamp = new Date()
    state.running = false
  },
  discardCollectionsData (state) {
    state.collections = []
  },
  updateCompanyData (state, data) {
    state.company.name = data.name
    state.company.logo = data.logo
    state.company.short = data.short
    state.running = false
  },
  updateErrorMessage (state, data) {
    state.errorflag = data.flag
    state.errorinfo.origin = data.origin
    state.errorinfo.message = data.message
    state.running = false
  },
  updateRunning (state, data) {
    state.running = data.flag
  }

}
