<template>
  <div class="container public">
    <page-header />
    <div class="content-container">
      <div class="<user-list-section">
        <h2 class="section-title">{{ $t('userPage.title') }}</h2>
        <div class="btn-toolbar">
          <button type="button" class="btn btn-primary mr-3" @click="showDetail('add')">
            <font-awesome-icon icon="plus" /> &nbsp; {{ $t('userPage.addUser') }}
          </button>
          <button class="btn btn-secondary  mr-3" @click="showDetail('edit')"
            :disabled="this.selectedUserId === -1"><font-awesome-icon icon="edit" />&nbsp; {{ $t('userPage.editUser') }}</button>
          <button class="btn btn-secondary  mr-3" @click="askDelete"
            :disabled="this.selectedUserId === -1 || this.selectedUserId === 1000"><font-awesome-icon icon="trash" />&nbsp; {{ $t('userPage.deleteUser') }}</button>
        </div>
        <error-row @clearError="clearError" :errorMessage="errorMessage" />
        <div v-show="isLoading" class="is-loading-section" >
          <button class="btn btn-primary"><span class="spinner-border spinner-border-sm"></span></button><span> {{ $t('userPage.isLoading') }} </span>
        </div>
        <div v-show="hasRoles && !hasUsers" id="no-data-section">
          {{ $t('userPage.tableHasNoEntries') }}
        </div>
        <div v-show="!isLoading && hasUsers" id="table-section" >
          <table class="table table-hover">
            <thead>
              <tr>
                <th>&nbsp;</th>
                <th @click="sort('username')">{{ $t('userPage.columnUsername') }}</th>
                <th @click="sort('userroleid')">{{ $t('userPage.columnRole') }}</th>
                <th @click="sort('email')">{{ $t('userPage.columnEmail') }}</th>
                <th @click="sort('firstname')">{{ $t('userPage.columnFirstname') }}</th>
                <th @click="sort('lastname')">{{ $t('userPage.columnLastname') }}</th>
                <th @click="sort('password')">{{ $t('userPage.columnPassword') }}</th>
                <th colspan="2" @click="sort('pushtokencnt')">{{ $t('userPage.columnPushTokenCnt') }}</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="user in sortedUsers"
                :key="user.id"
                @click="selectRow(user)"
                :class="{'table-primary' : isUserSelected(user.id)}"
              >
                <td><span @click.stop="editUser(user)"><font-awesome-icon icon="edit" /></span></td>
                <td>{{ user.username }}</td>
                <td>{{ rolesForUserDisplay(user) }}</td>
                <td>{{ user.email }}</td>
                <td>{{ user.firstname }}</td>
                <td>{{ user.lastname }}</td>
                <td class="text-center"><span v-if="user.password && user.password.length > 0">{{ $t('userPage.passwordIndicator') }}</span></td>
                <td class="text-center"><span v-if="user.pushtokencnt > 0">{{ user.pushtokencnt }}<span></td>
                <td><button class="btn btn-outline-info btn-sm" @click="gotoUserPushTokens(user)">{{ $t('userPage.editPushTokens') }}</button></td>
              </tr>
            </tbody>
         </table>
        </div>
      </div>
    </div>
    <page-footer />

    <edit-user-modal @modalClosed="modalClosed" :selectedUser="selectedUser" :action="selectedAction" :modalOpened="modalTimestamp" />

    <confirm-modal
      :title="$t('userPage.deleteUser')"
      :text="confirmDeleteText"
      :confirmButtonLabel="$t('confirmModal.confirmButtonLabel')"
      :cancelButtonLabel="$t('confirmModal.cancelButtonLabel')"
      @confirm="confirmDelete"
      @modalClosed="modalClosed"
    />

  </div>
</template>

<script>
import moment from 'moment'
import objectSorter from '@/utils/object-sorter'
import PageHeader from '@/components/PageHeader.vue'
import ErrorRow from '@/components/ErrorRow.vue'
import PageFooter from '@/components/PageFooter.vue'
import EditUserModal from '@/modals/EditUserModal.vue'
import ConfirmModal from '@/modals/ConfirmModal.vue'
import PageViewMixin from '@/mixins/PageViewMixin.js'

import { mapGetters } from 'vuex'
import userService from '@/services/users'

export default {
  name: 'UserPage',
  mixins: [PageViewMixin],
  data () {
    return {
      selectedUser: null,
      selectedAction: 'add',
      currentSortKey: 'username',
      currentSortOrder: 'asc',
      isLoading: true
    }
  },
  filters: {
    formatDate (date) {
      if (date) {
        return moment(String(date)).format('DD.MM.YYYY HH:mm')
      }
    }
  },
  created () {
    this.loadUsers(true)
  },
  destroyed () {
    this.$store.dispatch('discardUserData')
  },
  mounted () {
    this.clearError()
  },
  computed: {
    ...mapGetters([
      'hasUsers',
      'users',
      'hasRoles',
      'roles',
      'roleMap',
      'running'
    ]),
    confirmDeleteText () {
      if (this.selectedUser != null) {
        return this.$t('userPage.confirmDeleteText', { username: this.selectedUser.username })
      }
      return ''
    },
    selectedUserId () {
      return this.selectedUser ? this.selectedUser.id : -1
    },
    sortedUsers () {
      return objectSorter.sortedArray(this.users, this.currentSortOrder, this.currentSortKey)
    }
  },
  components: {
    'page-header': PageHeader,
    'error-row': ErrorRow,
    'page-footer': PageFooter,
    'edit-user-modal': EditUserModal,
    'confirm-modal': ConfirmModal
  },
  methods: {
    loadUsers (reset) {
      this.isLoading = true
      this.$store.dispatch('getUserData')
      if (reset) {
        this.selectedUser = this.emptyUser()
      }
    },
    isUserSelected (id) {
      return id === this.selectedUserId
    },
    selectRow (user) {
      if (this.selectedUser !== user) {
        this.selectedUser = user
      } else {
        this.selectedUser = this.emptyUser()
      }
    },
    showDetail (action) {
      if (action === 'add') {
        this.selectedUser = this.emptyUser()
        this.selectedAction = 'add'
      } else {
        this.selectedAction = 'edit'
      }
      this.showModalView()
    },
    editUser (user) {
      // console.log("UserPage#editUser : " + user.id)
      this.selectedUser = user
      this.selectedAction = 'edit'
      this.showModalView()
    },
    showModalView () {
      this.openModal('#editUserModal')
    },
    confirmDelete () {
      userService.deleteUser(this.selectedUserId).then((deletedUser) => {
        this.loadUsers(true)
      }).catch(error => {
        this.errorMessage = this.localizedBackendMessage(error.message)
      })
    },
    defaultUserRoleId () {
      return (this.roles.length > 0) ? this.roles[0].id : 0
    },
    emptyUser () {
      return {
        id: -1,
        // created: null
        username: '',
        email: '',
        firstName: '',
        lastName: '',
        password: '',
        userroleid: this.defaultUserRoleId(),
        organizationgroups: []
      }
    },
    rolesForUserDisplay (user) { // Vorläufig alle Rollen anzeigen
      if (!user || user.id < 1) {
        return ''
      }
      // Menge der involvierten Rollen-IDs zusammentragen
      const groupIds = new Set()
      user.organizationgroups.forEach(organizationGroup => {
        groupIds.add(organizationGroup.groupid)
      })
      // Zugehörige Rollen picken
      var groups = []
      groupIds.forEach(groupId => {
        groups.push(this.roleMap[groupId])
      })
      // Rollen sortieren
      groups = objectSorter.sortedArray(groups, 'asc', 'code')
      // String zusammenfügen
      var string = ''
      groups.forEach(group => {
        if (string.length > 0) { string += '|' }
        string += group.name
      })
      return string
    },
    sort (key) {
      // reverse sort order?
      if (key === this.currentSortKey) {
        this.currentSortOrder = this.currentSortOrder === 'asc' ? 'desc' : 'asc'
      }
      this.currentSortKey = key
    },
    reloadData () {
      this.loadUsers(false)
    },
    gotoUserPushTokens (user) {
      this.$router.push({ name: 'userpushtokens', params: { userid: user.id } })
    }
  }
}
</script>

<style lang="scss" scoped>
.content-container {
  padding: 0 35px;

  h2 {
    font-size: 18px;
    margin-bottom: 15px;
    font-weight: 400;
  }

  .user-list-section {
    margin: 30px 10px;
  }
}
</style>
