<template>
  <div class="DateTime"> <!-- @keyup.capture="updateValue" -->
    <input
      v-if="showDay"
      ref="day"
      v-model="day"
      class="DateTime__input DateTime__input--day"
      type="number"
      placeholder="dd"
      @input="updateDay"
      @blur="day = day.padStart(2, 0)">
    <span v-if="showDay && showMonth" class="DateTime__divider">.</span>
    <input
      v-if="showMonth"
      ref="month"
      v-model="month"
      class="DateTime__input DateTime__input--month"
      type="number"
      placeholder="mm"
      @input="updateMonth"
      @blur="month = month.padStart(2, 0)">
    <span v-if="showYear && (showDay || showMonth)" class="DateTime__divider">.</span>
    <input
      v-if="showYear"
      ref="year"
      v-model="year"
      class="DateTime__input DateTime__input--year"
      type="number"
      placeholder="yyyy"
      @input="updateYear"
      @blur="year = year.padStart(4, 0)">
    <span v-if="showTime && (showYear)" class="DateTime__divider"></span>
    <input
      v-if="showTime"
      ref="hour"
      v-model="hour"
      class="DateTime__input DateTime__input--hour"
      type="number"
      placeholder="HH"
      @input="updateHour"
      @blur="hour = hour.padStart(2, 0)">
    <span v-if="showTime" class="DateTime__divider">:</span>
    <input
      v-if="showTime"
      ref="minute"
      v-model="minute"
      class="DateTime__input DateTime__input--minute"
      type="number"
      placeholder="mm"
      @input="updateMinute"
      @blur="minute = minute.padStart(2, 0)">
    <span class="DateTime__divider"></span>
    <button type="button" @click="updateValue">
      <font-awesome-icon icon="check" />
    </button>
    <span class="DateTime__divider"></span>
  </div>
</template>

<script>

import moment from 'moment'

export default {
  name: 'DateTime',
  props: {
    value: {
      type: Date,
      required: true,
      default: new Date()
    },
    showDay: {
      type: Boolean,
      default: true
    },
    showMonth: {
      type: Boolean,
      default: true
    },
    showYear: {
      type: Boolean,
      default: true
    },
    showTime: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      day: '',
      month: '',
      year: '',
      hour: '',
      minute: ''
    }
  },
  mounted () {
    this.selectFirstInput()
  },
  watch: {
    day (current, prev) {
      if (current < 0 || current > 31) {
        this.day = prev
      }
    },
    month (current, prev) {
      if (current < 0 || current > 12) {
        this.month = prev
      }
    },
    year (current, prev) {
      if (current < 2000 || current > 2100) {
        // this.year = prev
      }
    },
    hour (current, prev) {
      if (current < 0 || current > 23) {
        this.hour = prev
      }
    },
    minute (current, prev) {
      if (current < 0 || current > 59) {
        this.minute = prev
      }
    },
    value (current, prev) {
      if (current) {
        const amoment = moment(current)
        this.day = amoment.format('DD')
        this.month = amoment.format('MM')
        this.year = amoment.format('YYYY')
        this.hour = amoment.format('HH')
        this.minute = amoment.format('mm')
      } else {
        this.day = ''
        this.month = ''
        this.year = ''
        this.hour = ''
        this.minute = ''
      }
    }
  },
  methods: {
    updateDay () {
      if (!this.day.length || parseInt(this.day, 10) < 4) {
        return
      }
      if (this.showMonth) {
        this.$refs.month.select()
      } else if (this.showYear) {
        this.$refs.year.select()
      }
    },
    updateMonth () {
      if (!this.month.length || parseInt(this.month, 10) < 2) {
        return
      }
      if (this.showYear) {
        this.$refs.year.select()
      }
    },
    updateYear () {
      if (!this.year.length || parseInt(this.year, 10) < 2000) {
        return
      }
      if (this.showTime) {
        this.$refs.hour.select()
      }
    },
    updateHour () {
      if (!this.hour.length || parseInt(this.hour, 10) < 3) {
        return
      }
      this.$refs.minute.select()
    },
    updateMinute () {
      if (!this.minute.length || parseInt(this.minute, 10) < 7) {
        return
      }
      if (this.showDay) {
        this.$refs.day.select()
      }
    },
    updateValue () {
      const string = this.year.padStart(4, 0) + '-' + this.month.padStart(2, 0) + '-' + this.day.padStart(2, 0) + ' ' + this.hour.padStart(2, 0) + ':' + this.minute.padStart(2, 0)
      const amoment = moment(string, 'YYYY-MM-DD HH:mm')
      if (!amoment.isValid()) {
        return
      }
      this.$emit('input', amoment.format('YYYY-MM-DDTHH:mm:ssZ'))
    },
    selectFirstInput () {
      this.$refs.day.focus()
    }
  }
}
</script>

<style lang="scss" scoped>
.DateTime {
  $spacing: 0.8em;
  $half-space: 0.4em;
  display: inline-flex;
  position: relative;
  overflow: hidden;
  border: 1px solid #888;
  border-radius: 0.25em;

  // 1. Hide the spinner button in Chrome, Safari and Firefox.
  &__input {
    padding: $spacing;
    padding-right: $half-space;
    padding-left: $half-space;
    border: none;
    text-align: center;
    /* stylelint-disable-next-line property-no-vendor-prefix */
    -moz-appearance: textfield; // 1

    &::-webkit-inner-spin-button {
      display: none; // 1
    }

    &:first-child {
      padding-left: $half-space;
    }

    &:last-child {
      padding-right: $half-space;
    }

    &:focus {
      outline: none;
    }

    &--day,
    &--month {
      width: 2em;
    }

    &--year {
      width: 4em;
    }

    &--hour,
    &--minute {
      width: 3em;
    }
  }

  &__divider {
    color: grey;
    padding-top: $spacing;
    padding-bottom: $spacing;
    pointer-events: none;
  }
}
</style>
