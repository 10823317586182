<template>
  <div class="container public">
    <page-header />
    <div class="content-container">
      <div class="<collections-list-section">
        <h2 class="section-title">{{ $t('collectionsPage.title') }}</h2>
        <div class="btn-toolbar">
          <button type="button" class="btn btn-primary mr-3" @click="showDetail('add')">
            <font-awesome-icon icon="plus" /> &nbsp; {{ $t('collectionsPage.addCollection') }}
          </button>
          <button class="btn btn-secondary mr-3" @click="showDetail('edit')"
            :disabled="this.selectedCollectionId === -1"><font-awesome-icon icon="edit" /> &nbsp; {{ $t('collectionsPage.editCollection') }}</button>
          <button class="btn btn-secondary mr-3" @click="askDelete"> <font-awesome-icon icon="trash" /> &nbsp; {{ $t('collectionsPage.deleteCollection') }}</button>
        </div>
        <error-row @clearError="clearError" :errorMessage="errorMessage" />
        <div v-show="isLoading" class="is-loading-section" >
          <button class="btn btn-primary"><span class="spinner-border spinner-border-sm"></span></button><span> {{ $t('collectionsPage.isLoading') }} </span>
        </div>
        <div v-show="!isLoading && !hasCollections" id="no-data-section">
          {{ $t('collectionsPage.tableHasNoEntries') }}
        </div>
        <div v-show="!isLoading && hasCollections" id="table-section" >
          <table class="table table-hover">
            <thead>
              <tr>
                <th>&nbsp;</th>
                <th class="sort-header" @click="sort('created')">{{ $t('collectionsPage.columnCreated') }}</th>
                <th class="sort-header" @click="sort('hexaddress')">{{ $t('collectionsPage.columnHexaddress') }}</th>
                <th class="sort-header" @click="sort('name')">{{ $t('collectionsPage.columnName') }}</th>
                <th class="sort-header" @click="sort('serialnr')">{{ $t('collectionsPage.columnSerialnr') }}</th>
                <th class="sort-header" @click="sort('sensortype')">{{ $t('collectionsPage.columnSensortype') }}</th>
                <th>{{ $t('collectionsPage.columnJson') }}</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="collection in sortedCollections"
                :key="collection.id"
                @click="selectRow(collection)"
                :class="{'table-primary' : isCollectionSelected(collection.id)}"
              >
                <td><span @click.stop="editCollection(collection)"><font-awesome-icon icon="edit" /></span></td>
                <td>{{ collection.created | formatDate }}</td>
                <td>{{ collection.hexaddress }}</td>
                <td>{{ collection.name }}</td>
                <td>{{ collection.serialnr }}</td>
                <td>{{ collection.sensortype }}</td>
                <td>{{ collection.json | shortJson }}</td>
              </tr>
            </tbody>
         </table>
        </div>
      </div>
    </div>
    <page-footer />
    <edit-collection-modal @modalClosed="modalClosed" :selectedCollection="selectedCollection" :action="selectedAction" :modalOpened="modalTimestamp" />
    <confirm-modal
      :title="$t('collectionsPage.deleteCollection')"
      :text="confirmDeleteText"
      :confirmButtonLabel="$t('confirmModal.confirmButtonLabel')"
      :cancelButtonLabel="$t('confirmModal.cancelButtonLabel')"
      @confirm="confirmDelete"
      @modalClosed="modalClosed"
    />
  </div>
</template>

<script>
import moment from 'moment'
import objectSorter from '@/utils/object-sorter'
import PageHeader from '@/components/PageHeader.vue'
import ErrorRow from '@/components/ErrorRow.vue'
import PageFooter from '@/components/PageFooter.vue'
import EditCollectionModal from '@/modals/EditCollectionModal.vue'
import ConfirmModal from '@/modals/ConfirmModal.vue'
import PageViewMixin from '@/mixins/PageViewMixin.js'

import { mapGetters } from 'vuex'
import collectionsService from '@/services/collections'

export default {
  name: 'CollectionAddressPage',
  mixins: [PageViewMixin],
  data () {
    return {
      selectedCollection: null,
      selectedAction: 'add',
      currentSortKey: 'hexaddress',
      currentSortOrder: 'asc',
      isLoading: true
    }
  },
  created () {
    this.loadCollections(true)
  },
  destroyed () {
    this.$store.dispatch('discardCollectionsData')
  },
  mounted () {
    this.clearError()
  },
  computed: {
    ...mapGetters([
      'hasCollections',
      'collections',
      'running'
    ]),
    confirmDeleteText () {
      if (this.selectedCollectionId > 0) {
        return this.$t('collectionsPage.confirmDeleteText', { hexaddress: this.selectedCollection.hexaddress })
      }
      return ''
    },
    selectedCollectionId () {
      return this.selectedCollection ? this.selectedCollection.id : -1
    },
    sortedCollections () {
      return objectSorter.sortedArray(this.collections, this.currentSortOrder, this.currentSortKey)
    }
  },
  components: {
    'page-header': PageHeader,
    'error-row': ErrorRow,
    'page-footer': PageFooter,
    'edit-collection-modal': EditCollectionModal,
    'confirm-modal': ConfirmModal
  },
  filters: {
    formatDate (date) {
      if (date) {
        return moment(String(date)).format('DD.MM.YYYY HH:mm')
      }
    },
    //
    // Auf 12 Zeichen verkürzte Form des Tokens in der Liste anzeigen
    //
    shortJson (text) {
      if (text && text.length > 12) {
        return text.replace(/(.{12})..+/, '$1…')
      }
      return text
    }
  },
  methods: {
    loadCollections (reset) {
      this.isLoading = true
      this.$store.dispatch('getCollectionsData')
      if (reset) {
        this.selectedCollection = this.emptyCollection()
      }
    },
    isCollectionSelected (id) {
      return id === this.selectedCollectionId
    },
    selectRow (collection) {
      if (this.selectedCollection !== collection) {
        this.selectedCollection = collection
      } else {
        this.selectedCollection = this.emptyCollection()
      }
    },
    showDetail (action) {
      if (action === 'add') {
        this.selectedCollection = this.emptyCollection()
        this.selectedAction = 'add'
      } else {
        this.selectedAction = 'edit'
      }
      this.showModalView()
    },
    editCollection (collection) {
      this.selectedCollection = collection
      this.selectedAction = 'edit'
      this.showModalView()
    },
    showModalView () {
      this.openModal('#editCollectionModal')
    },
    confirmDelete () {
      collectionsService.deleteCollection(this.selectedCollectionId).then((deletedCollection) => {
        this.loadCollections(true)
      }).catch(error => {
        this.errorMessage = this.localizedBackendMessage(error.message)
      })
    },
    emptyCollection () {
      return {
        id: -1,
        created: new Date(),
        hexaddress: '',
        name: '',
        serialnr: '',
        sensortype: 0,
        systemparttotal: 0,
        valuetypetotal: 0,
        json: ''
      }
    },
    sort (key) {
      // reverse sort order?
      if (key === this.currentSortKey) {
        this.currentSortOrder = this.currentSortOrder === 'asc' ? 'desc' : 'asc'
      }
      this.currentSortKey = key
    },
    reloadData () {
      this.loadCollections(false)
    }
  }
}
</script>

<style lang="scss" scoped>
.content-container {
  padding: 0 35px;

  h2 {
    font-size: 18px;
    margin-bottom: 15px;
    font-weight: 400;
  }

  .collection-list-section {
    margin: 30px 10px;
  }

  .sort-header {
    cursor: pointer;
  }
}
</style>
