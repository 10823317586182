import axios from 'axios'
import errorParser from '@/utils/error-parser'

export default {
  /**
   * Fetch all collection addresses
   */
  getCollectionsData () {
    return new Promise((resolve, reject) => {
      axios.get('/collections').then(({ data }) => {
        resolve(data)
      }).catch((error) => {
        reject(errorParser.parse(error))
      })
    })
  },

  /**
   * Add a new collection address
   * @param {Object} collection detail
   */
  addCollection (detail) {
    return new Promise((resolve, reject) => {
      axios.post('/collections', detail).then(({ data }) => {
        resolve(data)
      }).catch((error) => {
        reject(errorParser.parse(error))
      })
    })
  },

  /**
   * Update existing collection address
   * @param {Integer} collection ID
   * @param {Object} collection detail
   */
  updateCollection (collectionId, detail) {
    return new Promise((resolve, reject) => {
      axios.put('/collections/' + collectionId, detail).then(({ data }) => {
        resolve(data)
      }).catch((error) => {
        reject(errorParser.parse(error))
      })
    })
  },

  /**
   * Delete an existing collection address
   * @param {Integer} collectionId
   */
  deleteCollection (collectionId) {
    return new Promise((resolve, reject) => {
      axios.delete('/collections/' + collectionId).then(({ data }) => {
        resolve(data)
      }).catch((error) => {
        reject(errorParser.parse(error))
      })
    })
  }
}
