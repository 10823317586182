<template>
  <div class="auto-logout-wrapper" v-if="isVisible" >
    <div class="alert alert-danger">
      <button type="button" class="close" @click="preventLogout" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
      {{ $t("autoLogout.hint") }}
    </div>
  </div>
</template>

<script>

import LogoutViewMixin from '@/mixins/LogoutViewMixin.js'
import { mapGetters } from 'vuex'

export default {
  name: 'AutoLogout',
  mixins: [LogoutViewMixin],
  data () {
    return {
      isVisible: false,
      timer: ''
    }
  },
  computed: {
    ...mapGetters([
      'client'
    ])
  },
  created () {
    this.timer = setInterval(this.checkTimer, 30 * 1000) // alle 30 Sekunden
  },
  methods: {
    elapsedTime () {
      return this.client.timestamp ? new Date().getTime() - this.client.timestamp.getTime() : 0
    },
    checkTimer () {
      // Annahme: Session-Timeout 15 Minuten
      // Maximale Idle-Zeit von 10 Minuten in Millisekunden
      const maxAge = 10 * 60 * 1000
      const elapsed = this.elapsedTime()
      //
      // Session bereits nach 10 Minuten vollständig abgelaufen?
      //
      if (elapsed > maxAge) {
        this.forceLogout()
        return
      }

      //
      // Noch etwas Restzeit (3 Minuten) übrig?
      //
      if ((maxAge - elapsed) < 3 * 60 * 1000) {
        this.isVisible = true
      }
    },
    preventLogout () {
      this.isVisible = false
      //
      // Benutzerdaten im Hintergrund laden und damit den Zeitstempel aktualisieren.
      //
      this.$store.dispatch('getClientData')
    },
    forceLogout () {
      this.clearTimer()
      this.signOut()
    },
    clearTimer () {
      clearInterval(this.timer)
      this.timer = ''
    }
  },
  beforeDestroy () {
    this.clearTimer()
  }
}
</script>

<style lang="scss" scoped>
.auto-logout-wrapper {
  margin-top: 40px;
  margin-bottom: 40px;
}
</style>
