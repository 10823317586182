<template>
  <div class="container public">
    <page-header />
    <div class="content-container">
      <div class="<overview-list-section">
        <h2 class="section-title">{{ $t('userPushTokensPage.title') }} <span v-if="hasUsers" class="blue-text"> {{ user.email }} </span></h2>
        <div class="btn-toolbar">
          <button type="button" class="btn btn-info mr-3" @click="backToUsers">
            <font-awesome-icon icon="arrow-alt-circle-left" /> &nbsp; {{ $t('userPushTokensPage.backToUsers') }}
          </button>
         <button v-if="userMayEdit" type="button" class="btn btn-primary mr-3" @click="showDetail('add')">
           <font-awesome-icon icon="plus" /> &nbsp; {{ $t('userPushTokensPage.addButton') }}
         </button>
         <button  v-if="userMayEdit" type="button" class="btn btn-secondary  mr-3" @click="showDetail('edit')"
           :disabled="this.selectedUserPushTokenId === -1"><font-awesome-icon icon="edit" />&nbsp; {{ $t('userPushTokensPage.editButton') }}</button>
         <button  v-if="userMayEdit" type="button" class="btn btn-secondary  mr-3" @click="askDelete"
           :disabled="this.selectedUserPushTokenId  === -1"><font-awesome-icon icon="trash" />&nbsp; {{ $t('userPushTokensPage.deleteButton') }}</button>
        </div>
        <error-row @clearError="clearError" :errorMessage="errorMessage" />
        <div v-show="isLoading" class="is-loading-section" >
          <button class="btn btn-primary"><span class="spinner-border spinner-border-sm"></span></button><span> {{ $t('userPushTokensPage.isLoading') }} </span>
        </div>
        <div v-show="!isLoading && hasUserPushTokens" id="table-section" >
          <table class="table table-hover auto-col">
            <thead>
              <tr>
                <th v-if="userMayEdit">&nbsp;</th>
                <th @click="sort('developermode')">{{ $t('userPushTokensPage.columnDevelopermode') }}</th>
                <th @click="sort('lastseen')">{{ $t('userPushTokensPage.columnLastSeen') }}</th>
                <th @click="sort('vendor')">{{ $t('userPushTokensPage.columnVendor') }}</th>
                <th @click="sort('model')">{{ $t('userPushTokensPage.columnModel') }}</th>
                <th @click="sort('token')">
                  {{ $t('userPushTokensPage.columnToken') }}
                  <span v-if="tokenDisplayStyle === 'compact'" class="toggle-icon" @click.stop="toggletokenDisplayStyle"><font-awesome-icon icon="expand-alt" /></span>
                  <span v-if="tokenDisplayStyle === 'verbose'" class="toggle-icon" @click.stop="toggletokenDisplayStyle"><font-awesome-icon icon="compress-alt" /></span>
                </th>
                <th @click="sort('silentdelivery')">{{ $t('userPushTokensPage.columnSilentDelivery') }}</th>
                <th @click="sort('appvendorid')">
                  {{ $t('userPushTokensPage.columnAppVendorId') }}
                  <span v-if="vendorDisplayStyle === 'compact'" class="toggle-icon" @click.stop="toggleVendorDisplayStyle"><font-awesome-icon icon="expand-alt" /></span>
                  <span v-if="vendorDisplayStyle === 'verbose'" class="toggle-icon" @click.stop="toggleVendorDisplayStyle"><font-awesome-icon icon="compress-alt" /></span>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(userpushtoken, index) in sortedUserPushTokens"
                @click="selectRow(userpushtoken)"
                :class="{'table-primary' : isUserPushTokenSelected(userpushtoken.id)}"
                :key="index"
              >
                <td v-if="userMayEdit"><span @click.stop="editUserPushToken(userpushtoken)"><font-awesome-icon icon="edit" /></span></td>
                <td class="text-center"><span v-if="userpushtoken.developermode">{{ $t('userPushTokensPage.booleanTrue') }}</span><span v-else>{{ $t('userPushTokensPage.booleanFalse') }}</span></td>
                <td> {{ userpushtoken.lastseen | formatDate }} </td>
                <td> {{ localizedVendor(userpushtoken.vendor) }} </td>
                <td> {{ userpushtoken.model }} </td>
                <td v-if="tokenDisplayStyle === 'compact'"> {{ userpushtoken.token | shortToken }} </td>
                <td v-if="tokenDisplayStyle === 'verbose'"> {{ userpushtoken.token }} </td>
                <td> {{ localizedSensorAlertStatus(userpushtoken.silentdelivery) }} </td>
                <td v-if="vendorDisplayStyle === 'compact'"> {{ userpushtoken.appvendorid  | shortToken }} </td>
                <td v-if="vendorDisplayStyle === 'verbose'"> {{ userpushtoken.appvendorid }} </td>
              </tr>
            </tbody>
         </table>
        </div>
      </div>
    </div>
    <page-footer />

    <edit-user-push-token-modal @modalClosed="modalClosed" :selectedUser="user" :selectedUserPushToken="selectedUserPushToken" :action="selectedAction" :modalOpened="modalTimestamp" />

    <confirm-modal
      :title="$t('userPushTokensPage.deletePushToken')"
      :text="confirmDeleteText"
      :confirmButtonLabel="$t('confirmModal.confirmButtonLabel')"
      :cancelButtonLabel="$t('confirmModal.cancelButtonLabel')"
      @confirm="confirmDelete"
      @modalClosed="modalClosed"
    />
  </div>
</template>

<script>
import moment from 'moment'
import objectSorter from '@/utils/object-sorter'
import PageHeader from '@/components/PageHeader.vue'
import ErrorRow from '@/components/ErrorRow.vue'
import PageFooter from '@/components/PageFooter.vue'
import ConfirmModal from '@/modals/ConfirmModal.vue'
import PageViewMixin from '@/mixins/PageViewMixin.js'
import EditUserPushTokenModal from '@/modals/EditUserPushTokenModal.vue'
import userService from '@/services/users'

import { mapGetters } from 'vuex'

export default {
  name: 'UserPushTokensPage',
  mixins: [PageViewMixin],
  data () {
    return {
      userid: 0,
      selectedUserPushToken: null,
      selectedAction: 'add',
      currentSortKey: 'vendor',
      currentSortOrder: 'asc',
      tokenDisplayStyle: 'compact',
      vendorDisplayStyle: 'compact',
      isLoading: false
    }
  },
  filters: {
    formatDate (date) {
      if (date) {
        return moment(String(date)).format('DD.MM.YYYY HH:mm')
      }
    },
    //
    // Auf 12 Zeichen verkürzte Form des Tokens in der Liste anzeigen
    //
    shortToken (token) {
      if (token && token.length > 12) {
        return token.replace(/(.{12})..+/, '$1…')
      }
      return token
    }
  },
  mounted () {
    this.clearError()
    this.userid = this.$route.params.userid
    if (this.userid > 0) {
      this.loadUserPushTokens(true)
    }
  },
  destroyed () {
    this.$store.dispatch('discardUserPushTokensData')
  },
  computed: {
    ...mapGetters([
      'client',
      'hasUsers',
      'userMap',
      'hasUserPushTokens',
      'userpushtokens'
    ]),
    selectedUserPushTokenId () {
      return this.selectedUserPushToken ? this.selectedUserPushToken.id : -1
    },
    emptyUserPushToken () {
      return {
        id: -1,
        userid: this.userid,
        developermode: true,
        lastseen: new Date(),
        vendor: '',
        model: '',
        token: '',
        appvendorid: '',
        silentdelivery: 2
      }
    },
    confirmDeleteText () {
      if (this.selectedUserPushToken != null) {
        return this.$t('userPushTokensPage.confirmDeleteText', { model: this.selectedUserPushToken.model })
      }
      return ''
    },
    sortedUserPushTokens () {
      return objectSorter.sortedArray(this.userpushtokens, this.currentSortOrder, this.currentSortKey)
    },
    user () {
      return this.userid > 0 && this.hasUsers ? this.userMap[this.userid] : { name: '' }
    }
  },
  components: {
    'page-header': PageHeader,
    'error-row': ErrorRow,
    'page-footer': PageFooter,
    'edit-user-push-token-modal': EditUserPushTokenModal,
    'confirm-modal': ConfirmModal
  },
  methods: {
    loadUserPushTokens (reset) {
      this.isLoading = true
      const payload = { userId: this.userid }
      this.$store.dispatch('getUserPushTokensData', payload)
      if (reset) {
        this.selectedUserPushToken = this.emptyUserPushToken
      }
    },
    backToUsers () {
      this.$router.push({ name: 'users' })
    },
    isUserPushTokenSelected (id) {
      return this.selectedUserPushToken && id === this.selectedUserPushToken.id
    },
    toggletokenDisplayStyle () {
      this.tokenDisplayStyle = (this.tokenDisplayStyle === 'compact') ? 'verbose' : 'compact'
    },
    toggleVendorDisplayStyle () {
      this.vendorDisplayStyle = (this.vendorDisplayStyle === 'compact') ? 'verbose' : 'compact'
    },
    selectRow (userPushToken) {
      if (this.selectedUserPushToken !== userPushToken) {
        this.selectedUserPushToken = userPushToken
      } else {
        this.selectedUserPushToken = this.emptyUserPushToken
      }
    },
    showDetail (action) {
      if (action === 'add') {
        this.selectedUserPushToken = this.emptyUserPushToken
        this.selectedAction = 'add'
      } else {
        this.selectedAction = 'edit'
      }
      this.showModalView()
    },
    editUserPushToken (userPushToken) {
      this.selectedUserPushToken = userPushToken
      this.selectedAction = 'edit'
      this.showModalView()
    },
    showModalView () {
      this.openModal('#editUserPushTokenModal')
    },
    confirmDelete () {
      userService.deleteUserPushToken(this.selectedUserPushToken.id).then((deletedUserPushToken) => {
        this.loadUserPushTokens(true)
      }).catch(error => {
        this.errorMessage = this.localizedBackendMessage(error.message)
      })
    },
    sort (key) {
      // reverse sort order?
      if (key === this.currentSortKey) {
        this.currentSortOrder = this.currentSortOrder === 'asc' ? 'desc' : 'asc'
      }
      this.currentSortKey = key
    },
    localizedSensorAlertStatus (code) {
      return this.attributeLabelForCode('sensorAlertStatus', code)
    },
    localizedVendor (vendorCode) {
      return this.$t('editUserPushTokenModal.vendorCode' + vendorCode)
    },
    reloadData () {
      this.loadUserPushTokens(false)
    }
  }
}
</script>

<style lang="scss" scoped>
.content-container {
  padding: 0 35px;

  h2 {
    font-size: 18px;
    margin-bottom: 15px;
    font-weight: 400;
  }

  .magazine-list-section {
    margin: 30px 10px;
  }

  .representation-hierarchy {
    margin-top: 30px;
  }

  table.auto-col {
    table-layout: auto;
  }

  .grey {
    color: #808080;
  }

  .blue-text {
    color: #0069d9;
    font-weight: 700;
  }
  .toggle-icon {
    margin-left: 20px;
  }
}
</style>
