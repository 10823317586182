<template>
  <form @submit.prevent="saveSensorEvent" ref="sensorEventEditForm">
    <div class="modal" tabindex="-1" role="dialog" backdrop="static" id="editSensorEventModal" @keyup.esc="onEsc($event)">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header header">
            <h3 class="modal-title"><span v-if="action === 'add'">{{ $t('editSensorEventModal.titleAdd') }}</span><span v-if="action === 'edit'">{{ $t('editSensorEventModal.titleEdit') }}</span></h3>
            <button type="button" class="close" @click="close" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="form">
              <div v-show="errorMessage" class="alert alert-danger failed">{{ errorMessage }}</div>
              <div class="form-group">
                <label for="sensorTitle">{{ $t('editSensorEventModal.sensorTitle') }}</label>
                <div id="sensorTitle" class="blue-text" v-if="parentSensor && parentDevice && parentFacility">{{ parentSensor.name }} ({{ sensorType(parentSensor.type) }}) / {{ parentDevice.apikey }} / {{ parentFacility.name }}</div>
              </div>
              <div class="form-group">
                <label for="enabledInput">{{ $t('editSensorEventModal.enabledEvent') }}</label>
                &nbsp;&nbsp;<input type="checkbox" v-model="sensorEvent.enabled">
              </div>
              <div class="form-group">
                <label for="sensoralerteventcodeInput">{{ $t('editSensorEventModal.sensoralerteventcodeInput') }}</label>
                <select class="form-control" id="sensoralerteventcodeInput" v-model="sensorEvent.sensoralerteventcode" >
                  <option v-for="sensorAlertEventCode in sensorAlertEventCodes" :value="sensorAlertEventCode" :key="sensorAlertEventCode">
                    {{ localizedSensorAlertEvent(sensorAlertEventCode) }}
                  </option>
                </select>
                <div class="invalid-feedback" v-if="$v.sensorEvent.sensoralerteventcode.$dirty">
                  <div class="error" v-if="!$v.sensorEvent.sensoralerteventcode.required">{{ $t('editSensorEventModal.sensoralerteventcodeRequired') }}</div>
                  <div class="error" v-if="!$v.sensorEvent.sensoralerteventcode.integer || !$v.sensorEvent.sensoralerteventcode.between">{{ $t('editSensorEventModal.integer') }}</div>
                </div>
              </div>
              <div class="form-group">
                <label for="sensoralertstatuscodeInput">{{ $t('editSensorEventModal.sensoralertstatuscodeInput') }}</label>
                <select class="form-control" id="sensoralertstatuscodeInput" v-model="sensorEvent.sensoralertstatuscode" >
                  <option v-for="sensorAlertStatusCode in sensorAlertStatusCodes" :value="sensorAlertStatusCode" :key="sensorAlertStatusCode">
                    {{ localizedSensorAlertStatus(sensorAlertStatusCode) }}
                  </option>
                </select>
                <div class="invalid-feedback" v-if="$v.sensorEvent.sensoralertstatuscode.$dirty">
                  <div class="error" v-if="!$v.sensorEvent.sensoralertstatuscode.required">{{ $t('editSensorEventModal.sensoralertstatuscodeRequired') }}</div>
                  <div class="error" v-if="!$v.sensorEvent.sensoralertstatuscode.integer || !$v.sensorEvent.sensoralertstatuscode.between">{{ $t('editSensorEventModal.integer') }}</div>
                </div>
              </div>
              <div class="form-group">
                <label for="systempartInput">{{ $t('editSensorEventModal.systempartInput') }}</label>
                <select class="form-control" id="systempartInput" v-model="sensorEvent.systempart" >
                  <option v-for="systemPartCode in systemPartCodes" :value="systemPartCode" :key="systemPartCode">
                    {{ localizedPart(systemPartCode) }}
                  </option>
                </select>
                <div class="invalid-feedback" v-if="$v.sensorEvent.systempart.$dirty">
                  <div class="error" v-if="!$v.sensorEvent.systempart.required">{{ $t('editSensorEventModal.systempartRequired') }}</div>
                  <div class="error" v-if="!$v.sensorEvent.systempart.integer || !$v.sensorEvent.systempart.between">{{ $t('editSensorEventModal.integer') }}</div>
                </div>
              </div>
              <div class="form-group">
                <label for="typeInput">{{ $t('editSensorEventModal.typeInput') }}</label>
                <select class="form-control" id="typeInput" v-model="sensorEvent.type" >
                  <option v-for="valueTypeCode in valueTypeCodes" :value="valueTypeCode" :key="valueTypeCode">
                    {{ localizedValueType(valueTypeCode) }}
                  </option>
                </select>
                <div class="invalid-feedback" v-if="$v.sensorEvent.type.$dirty">
                  <div class="error" v-if="!$v.sensorEvent.type.required">{{ $t('editSensorEventModal.typeRequired') }}</div>
                  <div class="error" v-if="!$v.sensorEvent.type.integer || !$v.sensorEvent.type.between">{{ $t('editSensorEventModal.integer') }}</div>
                </div>
              </div>
              <div class="form-group">
                <label for="valueInput">{{ $t('editSensorEventModal.valueInput') }}</label>
                <input type="text" class="form-control" :class="{ 'is-invalid': $v.sensorEvent.value.$dirty && $v.sensorEvent.value.$error }" id="valueInput" v-model="sensorEvent.value" />
                <div class="invalid-feedback" v-if="$v.sensorEvent.value.$dirty">
                  <div class="error" v-if="!$v.sensorEvent.value.required">{{ $t('editSensorEventModal.valueRequired') }}</div>
                  <div class="error" v-if="!$v.sensorEvent.value.decimal">{{ $t('editSensorEventModal.valueDecimal') }}</div>
                </div>
              </div>
              <div class="form-group">
                <label for="thresholdInput">{{ $t('editSensorEventModal.thresholdInput') }}</label>
                <input type="text" class="form-control" :class="{ 'is-invalid': $v.sensorEvent.threshold.$dirty && $v.sensorEvent.threshold.$error }" id="thresholdInput" v-model="sensorEvent.threshold" />
                <div class="invalid-feedback" v-if="$v.sensorEvent.threshold.$dirty">
                  <div class="error" v-if="!$v.sensorEvent.threshold.required">{{ $t('editSensorEventModal.thresholdRequired') }}</div>
                  <div class="error" v-if="!$v.sensorEvent.threshold.decimal">{{ $t('editSensorEventModal.thresholdDecimal') }}</div>
                </div>
              </div>
              <div class="form-group">
                <label for="intermissionInput">{{ $t('editSensorEventModal.intermissionInput') }}</label>
                <input type="text" class="form-control" :class="{ 'is-invalid': $v.sensorEvent.intermission.$dirty && $v.sensorEvent.intermission.$error }" id="intermissionInput" v-model="sensorEvent.intermission" />
                <div class="invalid-feedback" v-if="$v.sensorEvent.intermission.$dirty">
                  <div class="error" v-if="!$v.sensorEvent.intermission.required">{{ $t('editSensorEventModal.intermissionRequired') }}</div>
                  <div class="error" v-if="!$v.sensorEvent.intermission.integer || !$v.sensorEvent.systempart.minValue">{{ $t('editSensorEventModal.intermissionInteger') }}</div>
                </div>
                <div class="button-bar">
                  <button class="btn btn-outline-info btn-sm" @click="predefinedIntermission(0, $event)">{{ $t('editSensorEventModal.5minutes') }}</button>
                  <button class="btn btn-outline-info btn-sm" @click="predefinedIntermission(1, $event)">{{ $t('editSensorEventModal.20minutes') }}</button>
                  <button class="btn btn-outline-info btn-sm" @click="predefinedIntermission(2, $event)">{{ $t('editSensorEventModal.1hour') }}</button>
                  <button class="btn btn-outline-info btn-sm" @click="predefinedIntermission(3, $event)">{{ $t('editSensorEventModal.12hours') }}</button>
                  <button class="btn btn-outline-info btn-sm" @click="predefinedIntermission(4, $event)">{{ $t('editSensorEventModal.24hours') }}</button>
                  </div>
              </div>
              <div class="form-group">
                <label for="messageInput">{{ $t('editSensorEventModal.messageInput') }}</label>
                <input type="text" class="form-control" :class="{ 'is-invalid': $v.sensorEvent.message.$dirty && $v.sensorEvent.message.$error }" id="messageInput" v-model.trim="sensorEvent.message" :placeholder="$t('editSensorEventModal.messagePlaceholder')" maxlength="50" />
                <div class="invalid-feedback" v-if="$v.sensorEvent.message.$dirty">
                  <div class="error" v-if="!$v.sensorEvent.message.maxLength">{{ $t('editSensorEventModal.messageMaxLength', { max: $v.sensorEvent.message.$params.maxLength.max }) }}</div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="submit" class="btn btn-primary"><span v-if="action === 'add'">{{ $t('editSensorEventModal.buttonAdd') }}</span><span v-if="action === 'edit'">{{ $t('editSensorEventModal.buttonSave') }}</span></button>
            <button type="button" class="btn btn-secondary" @click="close">{{ $t('editSensorEventModal.close') }}</button>
          </div>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import $ from 'jquery'
import { required, integer, decimal, between, maxLength, minValue } from 'vuelidate/lib/validators'
import cdcsensorService from '@/services/cdcsensors'
import ModalViewMixin from '@/mixins/ModalViewMixin.js'

export default {
  name: 'EditSensorEventModal',
  mixins: [ModalViewMixin],
  props: {
    parentFacility: {
      type: Object,
      required: true
    },
    parentDevice: {
      type: Object,
      required: true
    },
    parentSensor: {
      type: Object,
      required: true
    },
    selectedSensorEvent: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      sensorEvent: {},
      errorMessage: '',
      sensorAlertEventCodes: [0, 1, 2, 3, 4],
      sensorAlertStatusCodes: [0, 1, 2, 3, 4],
      systemPartCodes: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32],
      valueTypeCodes: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33]
    }
  },
  watch: {
    modalOpened () {
      this.copySensorEvent()
      this.triggerFocus()
    }
  },
  validations: {
    sensorEvent: {
      sensoralerteventcode: {
        required,
        integer,
        between: between(0, 4)
      },
      sensoralertstatuscode: {
        required,
        integer,
        between: between(0, 4)
      },
      systempart: {
        required,
        integer,
        between: between(0, 25)
      },
      type: {
        required,
        integer,
        between: between(0, 23)
      },
      value: {
        required,
        decimal
      },
      threshold: {
        required,
        decimal
      },
      message: {
        maxLength: maxLength(1024)
      },
      intermission: {
        required,
        integer,
        minValue: minValue(0)
      },
      combinedsensoreventid: {
        integer
      }
    }
  },
  mounted () {
    $('#editSensorEventModal').on('shown.bs.modal', () => {
      this.triggerFocus()
    })
  },
  methods: {
    copySensorEvent () {
      this.sensorEvent = Object.assign({}, this.selectedSensorEvent)
    },
    saveSensorEvent () {
      this.$v.$touch()
      if (this.$v.$invalid) {
        return
      }

      if (this.action === 'add') {
        cdcsensorService.addSensorEvent(this.sensorEvent).then((createdSensorEvent) => {
          this.flagChanges()
        }).catch(error => {
          this.errorMessage = this.localizedBackendMessage(error.message)
        })
      } else if (this.action === 'edit') {
        cdcsensorService.updateSensorEvent(this.sensorEvent.id, this.sensorEvent).then((updatedSensorEvent) => {
          this.flagChanges()
        }).catch(error => {
          this.errorMessage = this.localizedBackendMessage(error.message)
        })
      }
    },
    close () {
      this.$v.$reset()
      this.errorMessage = ''
      // zurück zum ursprünglichen 'selected sensorEvent',
      // um etwaigen Änderungen im Formular loszuwerden
      this.copySensorEvent()
      //
      // this.$refs.sensorEventEditForm.reset()
      this.emitClosed()
      $('#editSensorEventModal').modal('hide')
    },
    triggerFocus () {
      // Alle 'textarea-autosize' berühren!
      $('#messageInput').trigger('focus')
    },
    predefinedIntermission (idx, event) {
      if (event) {
        event.preventDefault()
      }

      let seconds = 300
      switch (idx) {
        case 0:
          seconds = 5 * 60
          break
        case 1:
          seconds = 20 * 60
          break
        case 2:
          seconds = 60 * 60
          break
        case 3:
          seconds = 12 * 60 * 60
          break
        case 4:
          seconds = 24 * 60 * 60
          break
      }
      this.sensorEvent.intermission = seconds
    },
    localizedSensorAlertEvent (code) {
      return this.attributeLabelForCode('sensorAlertEvent', code)
    },
    localizedSensorAlertStatus (code) {
      return this.attributeLabelForCode('sensorAlertStatus', code)
    },
    localizedPart (systemPart) {
      return this.attributeLabelForCode('systemPart', systemPart)
    },
    localizedValueType (valueTypeCode) {
      return this.attributeLabelForCode('valueType', valueTypeCode)
    },
    sensorType (type) {
      return this.attributeLabelForCode('sensorType', type)
    }
  }
}
</script>

<style lang="scss" scoped>
.modal {
  .modal-dialog {
    width: 400px;
  }

  .blue-text {
    color: #0069d9;
    font-weight: 700;
  }

  .td-right {
    text-align: right;
  }

  .button-bar {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
  }
}
</style>
