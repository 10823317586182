import axios from 'axios'
import errorParser from '@/utils/error-parser'

export default {
  /**
   * Fetch current Company
   */
  getCompany () {
    return new Promise((resolve, reject) => {
      axios.get('/company').then(({ data }) => {
        /* eslint-disable no-console */
        console.log('getCompany')
        /* eslint-disable no-console */
        console.log(data)
        resolve(data)
      }).catch((error) => {
        reject(errorParser.parse(error))
      })
    })
  }
}
