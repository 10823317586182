<template>
  <form @submit.prevent="saveUser" ref="userEditForm">
    <div class="modal" tabindex="-1" role="dialog" backdrop="static" id="editUserModal" @keyup.esc="onEsc($event)">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header header">
            <h3 class="modal-title"><span v-if="action === 'add'">{{ $t('editUserModal.titleAdd') }}</span><span v-if="action === 'edit'">{{ $t('editUserModal.titleEdit') }}</span></h3>
            <button type="button" class="close" @click="close" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="form">
              <div v-show="errorMessage" class="alert alert-danger failed">{{ errorMessage }}</div>
              <div class="form-group" v-if="action === 'edit'">
                <label for="created">{{ $t('editUserModal.created') }}</label>
                <div class="modified-and-user" id="created">{{ user.created | formatDate }}</div>
              </div>
              <div class="form-group">
                <label for="userNameInput">{{ $t('editUserModal.userNameInput') }}</label>
                <input type="text" class="form-control" :class="{ 'is-invalid': $v.user.username.$dirty && $v.user.username.$error }" id="userNameInput" v-model.trim="user.username" :placeholder="$t('editUserModal.usernamePlaceholder')" maxlength="60" />
                <div class="invalid-feedback" v-if="$v.user.username.$dirty">
                  <div class="error" v-if="!$v.user.username.required">{{ $t('editUserModal.usernameRequired') }}</div>
                  <div class="error" v-if="!$v.user.username.minLength">{{ $t('editUserModal.usernameMinLength', { min: $v.user.username.$params.minLength.min }) }}</div>
                  <div class="error" v-if="!$v.user.username.maxLength">{{ $t('editUserModal.usernameMaxLength', { max: $v.user.username.$params.maxLength.max }) }}</div>
                </div>
              </div>
              <div class="form-group">
                <label for="emailAddress">{{ $t('editUserModal.emailInput') }}</label>
                <input type="email" class="form-control" :class="{ 'is-invalid': $v.user.email.$dirty && $v.user.email.$error }" id="emailAddress" v-model.trim="user.email" :placeholder="$t('editUserModal.emailPlaceholder')" maxlength="100" />
                <div class="invalid-feedback" v-if="$v.user.email.$dirty">
                  <div class="error" v-if="!$v.user.email.required">{{ $t('editUserModal.emailRequired') }}</div>
                  <div class="error" v-if="!$v.user.email.email">{{ $t('editUserModal.emailInvalid') }}</div>
                  <div class="error" v-if="!$v.user.email.maxLength">{{ $t('editUserModal.emailMaxLength', { max: $v.user.email.$params.maxLength.max }) }}</div>
                </div>
              </div>
              <div class="form-group">
                <label for="firstName">{{ $t('editUserModal.firstNameInput') }}</label>
                <input type="text" class="form-control" :class="{ 'is-invalid': $v.user.firstname.$dirty && $v.user.firstname.$error }" id="firstName" v-model.trim="user.firstname" :placeholder="$t('editUserModal.firstNamePlaceholder')" maxlength="50" />
                <div class="invalid-feedback" v-if="$v.user.firstname.$dirty">
                  <div class="error" v-if="!$v.user.firstname.maxLength">{{ $t('editUserModal.firstNameMaxLength', { max: $v.user.firstname.$params.maxLength.max }) }}</div>
                </div>
              </div>
              <div class="form-group">
                <label for="lastName">{{ $t('editUserModal.lastNameInput') }}</label>
                <input type="text" class="form-control" :class="{ 'is-invalid': $v.user.lastname.$dirty && $v.user.lastname.$error }" id="lastName" v-model.trim="user.lastname" :placeholder="$t('editUserModal.lastNamePlaceholder')" maxlength="50" />
                <div class="invalid-feedback" v-if="$v.user.lastname.$dirty">
                  <div class="error" v-if="!$v.user.lastname.maxLength">{{ $t('editUserModal.lastNameMaxLength', { max: $v.user.lastname.$params.maxLength.max }) }}</div>
                </div>
              </div>
              <div class="form-group">
                <label for="secret">{{ $t('editUserModal.secretInput') }}</label>
                <input type="password" class="form-control" :class="{ 'is-invalid': $v.user.secret.$dirty && $v.user.secret.$error }" id="secret" v-model="user.secret" :placeholder="$t('editUserModal.secretPlaceholder')" ref="secret" />
                <div class="invalid-feedback" v-if="$v.user.secret.$dirty">
                  <div class="error" v-if="!$v.user.secret.maxLength">{{ $t('editUserModal.secretMaxLength', { max: $v.user.secret.$params.maxLength.max }) }}</div>
                  <div class="error" v-if="!$v.user.secret.minLength">{{ $t('editUserModal.secretMinLength', { min: $v.user.secret.$params.minLength.min }) }}</div>
                </div>
              </div>
              <div class="form-group" v-if="singleOrganization === false">
                <label for="userOrganizationSelect">{{ $t('editUserModal.userOrganizationInput') }}</label>
                <select class="form-control" id="userOrganizationSelect" v-if="hasOrganizations" v-model="selectedOrganizationId" >
                  <option value="-1">{{ $t('editUserModal.chooseOrganization') }}</option>
                  <option v-for="organization in organizations" :value="organization.id" :key="organization.id">
                    {{ organization.name }}
                  </option>
                </select>
              </div>
              <div class="form-group">
                <label v-if="singleOrganization === false" for="userRoleSelect">{{ $t('editUserModal.userRoleInput') }}</label>
                <label v-else for="userRoleSelect">{{ $t('editUserModal.userRoleInputSingleOrganization') }}</label>
                <select class="form-control" id="userRoleSelect" v-if="hasRoles" @change="addOrgRole" v-model="selectedRoleId" >
                  <option value="-1">{{ $t('editUserModal.chooseRole') }}</option>
                  <option v-for="role in sortedRoles" :value="role.id" :key="role.id">
                    {{ role.name }}
                  </option>
                </select>
              </div>
              <div class="form-group" v-if="user && user.organizationgroups && user.organizationgroups.length > 0">
                <table class="table auto-col">
                  <thead>
                    <tr><th v-if="singleOrganization === false">{{ $t('editUserModal.columnOrganization') }}</th><th>{{ $t('editUserModal.columnRole') }}</th><th>&nbsp;</th></tr>
                  </thead>
                  <tbody>
                    <tr v-for="organizationGroup in organizationGroups" :key="organizationGroup">
                      <td v-if="singleOrganization === false">
                        <span class="blue-text">{{ organizationMap[organizationGroup.organizationid].name }}</span>
                      </td>
                      <td>
                        <span class="blue-text">{{ roleMap[organizationGroup.groupid].name }}</span>
                      </td>
                      <td class="td-right">
                        <button type="button" @click="removeOrganizationGroup(organizationGroup)" v-title.light="$t('editUserModal.removeOrgRoleHint')"><font-awesome-icon icon="trash" /></button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="submit" class="btn btn-primary"><span v-if="action === 'add'">{{ $t('editUserModal.buttonAdd') }}</span><span v-if="action === 'edit'">{{ $t('editUserModal.buttonSave') }}</span></button>
            <button type="button" class="btn btn-secondary" @click="close">{{ $t('editUserModal.close') }}</button>
          </div>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import $ from 'jquery'
import moment from 'moment'
// import _ from 'lodash'
import { required, email, minLength, maxLength } from 'vuelidate/lib/validators'
import userService from '@/services/users'
import ModalViewMixin from '@/mixins/ModalViewMixin.js'
import objectSorter from '@/utils/object-sorter'

import { mapGetters } from 'vuex'

export default {
  name: 'EditUserModal',
  mixins: [ModalViewMixin],
  props: {
    selectedUser: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      user: {},
      errorMessage: '',
      selectedOrganizationId: -1,
      selectedRoleId: -1
    }
  },
  filters: {
    formatDate (date) {
      if (date) {
        return moment(String(date)).format('DD.MM.YYYY HH:mm')
      }
    }
  },
  watch: {
    modalOpened () {
      this.copyUser()
      if (this.singleOrganization) {
        this.selectedOrganizationId = this.organizations[0].id
      }
    }
  },
  validations: {
    user: {
      username: {
        required,
        minLength: minLength(1),
        maxLength: maxLength(50)
      },
      email: {
        required,
        email,
        maxLength: maxLength(100)
      },
      firstname: {
        maxLength: maxLength(50)
      },
      lastname: {
        maxLength: maxLength(50)
      },
      secret: {
        minLength: minLength(6),
        maxLength: maxLength(30)
      }
    }
  },
  mounted () {
    $('#editUserModal').on('shown.bs.modal', () => {
      $('#userNameInput').trigger('focus')
    })
  },
  computed: {
    ...mapGetters([
      'hasOrganizations',
      'organizations',
      'organizationMap',
      'hasRoles',
      'roles',
      'roleMap'
    ]),
    sortedRoles () {
      return objectSorter.sortedArray(this.roles, 'asc', 'code')
    },
    organizationGroups () {
      var wrappers = []
      this.user.organizationgroups.forEach(orgRole => {
        const combinedCode = this.organizationMap[orgRole.organizationid].code + '-' + this.roleMap[orgRole.groupid].code
        wrappers.push({ code: combinedCode, obj: orgRole })
      })
      // jetzt sortieren
      wrappers = objectSorter.sortedArray(wrappers, 'asc', 'code')
      // Objekte wieder herausprojizieren
      var sorted = []
      objectSorter.sortedArray(wrappers, 'asc', 'code').forEach(wrapper => {
        sorted.push(wrapper.obj)
      })
      return sorted
    },
    singleOrganization () {
      return (this.organizations && this.organizations.length === 1)
    }
  },
  methods: {
    copyUser () {
      this.user = Object.assign({}, this.selectedUser)
      if (!this.user.organizationgroups) {
        this.user.organizationgroups = []
      }
    },
    saveUser () {
      this.$v.$touch()
      if (this.$v.$invalid) {
        return
      }

      if (this.action === 'add') {
        userService.addUser(this.user).then((createdUser) => {
          this.flagChanges()
        }).catch(error => {
          this.errorMessage = this.localizedBackendMessage(error.message)
        })
      } else if (this.action === 'edit') {
        userService.updateUser(this.user.id, this.user).then((updatedUser) => {
          this.flagChanges()
        }).catch(error => {
          this.errorMessage = this.localizedBackendMessage(error.message)
        })
      }
    },
    close () {
      this.$v.$reset()
      this.errorMessage = ''
      // zurück zum ursprünglichen 'selected user',
      // um etwaigen Änderungen im Formular loszuwerden
      this.copyUser()
      //
      // this.$refs.userEditForm.reset()
      this.emitClosed()
      $('#editUserModal').modal('hide')
    },
    addOrgRole () {
      if (this.selectedOrganizationId > 0 && this.selectedRoleId > 0) {
        //
        // Bereits in der Liste vorhanden?
        //
        const already = this.user.organizationgroups.find(organizationGroup => organizationGroup.organizationid === this.selectedOrganizationId && organizationGroup.groupid === this.selectedRoleId)
        if (!already) {
          const newEntry = { organizationid: this.selectedOrganizationId, groupid: this.selectedRoleId }
          this.user.organizationgroups.push(newEntry)
        }
        this.selectedRoleId = -1
      }
    },
    removeOrganizationGroup (organizationGroup) {
      var index = -1
      for (var i = 0; i < this.user.organizationgroups.length; i++) {
        const orgGroup = this.user.organizationgroups[i]
        if (orgGroup === organizationGroup) {
          index = i
          break
        }
      }
      if (index >= 0) {
        this.user.organizationgroups.splice(index, 1)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.modal {
  .modal-dialog {
    width: 400px;
  }

  .blue-text {
    color: #0069d9;
    font-weight: 700;
  }

  .td-right {
    text-align: right;
  }
}
</style>
