<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
export default {
  name: 'App'
}
</script>

<style lang="scss">
html {
  font-size: 14px;
}

.public.container {
  max-width: 1000px;
}

input.form-control:focus,
textarea.form-control:focus {
  border: 1px solid #377EF6 !important;
}

.public {
  .form {
    margin-top: 50px;
    width: 320px;

    .form-group {
      label {
        font-weight: bold;
        color: #555;
      }

      .error {
        line-height: 1;
        margin-top: 5px;
      }
    }
  }
}

.is-loading-section {
  color: blue;
  margin-top: 1em;
  margin-bottom: 1em;
}

.modal {
  .modal-dialog {
    -webkit-transform: translate(0,-25%);
    -o-transform: translate(0,-25%);
    transform: translate(0,-25%);
    top: 25%;
    margin: 0 auto;
    width: 600px;

    .modal-header {
      border-bottom: none;
      padding: 1rem 1rem .5rem;

      .modal-title {
        font-size: 1rem;
      }
    }

    .modal-body {
      padding-bottom: 0;

      textarea {
        resize: none;
        height: 100px;
      }
    }

    .modal-footer {
      justify-content: start;
      border-top: none;
      padding-top: 0;
      padding-bottom: 1.5rem;
    }
  }

  .modified-and-user {
    color: rgb(50, 50, 50);
  }

  .form {
    margin-top: 0px;
    width: 100%;
  }

  .header {
    color: white;
    background-color: rgb(99, 99, 99);
  }
}

</style>
