import { render, staticRenderFns } from "./DateTime.vue?vue&type=template&id=473b685c&scoped=true"
import script from "./DateTime.vue?vue&type=script&lang=js"
export * from "./DateTime.vue?vue&type=script&lang=js"
import style0 from "./DateTime.vue?vue&type=style&index=0&id=473b685c&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "473b685c",
  null
  
)

export default component.exports