<template>
  <div class="container public">
    <page-header />
    <div class="content-container">
      <div>
          <h2 class="section-title">{{ $t('cdcDateValuesPage.title') }} <span v-if="facilityForDevice" class="blue-text"> {{ selectedDevice.apikey }} / {{ facilityForDevice.name }} / {{ organizationForFacility.code }} </span></h2>
        <div class="row">
          <div class="btn-toolbar">
            <button type="button" class="btn btn-info mr-3" @click="backToDevices">
              <font-awesome-icon icon="arrow-alt-circle-left" /> &nbsp; {{ $t('cdcDateValuesPage.backToDevices') }}
            </button>
          </div>
          <div>
            <vue-ctk-date-time-picker v-model="dayOfInterest" noLabel="true" enable-button-validate="true" :button-now-translation="$t('cdcDateValuesPage.now')" format="YYYY-MM-DDTHH:mm:ssZ" />
          </div>
        </div>
        <div class="row optional-section">
          <!-- Sensor ID Map -->
          <div>
            <ul class="list-group">
              <li class="list-group-item d-flex justify-content-center">{{ $t('cdcDateValuesPage.sensorKey') }}</li>
              <li v-for="(key, index) in sensorIdCountMapKeys" :key="key" class="list-group-item d-flex justify-content-between align-items-center">
                <span style="margin-right: 20px;"><input type="checkbox" v-model="sensorIdIndexMap[index]" /></span>
                <span style="margin-right: 20px;">{{ key }}</span>
                <span class="badge badge-primary badge-pill">{{ sensorIdCountMap[key] }}</span>
              </li>
            </ul>
          </div>
          <!-- System-Part Map -->
          <div style="margin-left: 10px;">
            <ul class="list-group">
              <li class="list-group-item d-flex justify-content-center">{{ $t('cdcDateValuesPage.systemPartKey') }}</li>
              <li v-for="(key, index) in systemPartCountMapKeys" :key="key" class="list-group-item d-flex justify-content-between align-items-center">
                <span style="margin-right: 20px;"><input type="checkbox" v-model="systemPartIndexMap[index]" /></span>
                <span style="margin-right: 20px;">{{ localizedPart(key) }}</span>
                <span class="badge badge-primary badge-pill">{{ systemPartCountMap[key] }}</span>
              </li>
            </ul>
          </div>
          <!-- Sensor-Type Map -->
          <div style="margin-left: 10px;">
            <ul class="list-group">
              <li class="list-group-item d-flex justify-content-center">{{ $t('cdcDateValuesPage.quantityKey') }}</li>
              <li v-for="(key, index) in quantityCountMapKeys" :key="key" class="list-group-item d-flex justify-content-between align-items-center">
                <span style="margin-right: 20px;"><input type="checkbox" v-model="quantityIndexMap[index]" /></span>
                <span style="margin-right: 20px;">{{ localizedUnit(key) }}</span>
                <span class="badge badge-primary badge-pill">{{ quantityCountMap[key] }}</span>
              </li>
            </ul>
          </div>
        </div>
        <div>
          <error-row @clearError="clearError" :errorMessage="errorMessage" />
          <div v-show="isLoading" class="is-loading-section" >
            <button class="btn btn-primary"><span class="spinner-border spinner-border-sm"></span></button><span> {{ $t('cdcDateValuesPage.isLoading') }} </span>
          </div>
        </div>
        <div>
          <div v-show="!isLoading && hasCdcdatavalues" id="graph-section" >
            <line-chart :data="chartDataSets" :points="false"></line-chart>
            <div style="margin-top: 30px">
                <range-slider @rangeUpdate="rangeUpdate" :startValues="sliderBounds" />
            </div>
          </div>
        </div>
        <div v-show="hasCdcdatavalues">
          <div class="btn-toolbar optional-section">
            <button type="button" class="btn btn-info mr-3" @click="toggleDisplayTableData">
              <span v-if="showTableData"> {{ $t('cdcDateValuesPage.hideTable') }} </span>
              <span v-if="!showTableData"> {{ $t('cdcDateValuesPage.showTable') }} </span>
            </button>
          </div>
        </div>
        <div v-show="hasCdcdatavalues && showTableData">
          <div class="table-section" >
            <table class="table table-hover auto-col">
              <thead>
                <tr>
                  <th @click="sort('recordedtime')">{{ $t('cdcDateValuesPage.columnRecordedTime') }}</th>
                  <th>{{ $t('cdcDateValuesPage.columnSensorTime') }}</th>
                  <th>{{ $t('cdcDateValuesPage.columnValuePart') }}</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(cdcData, index) in sortedCdcData"
                  @click="selectRow(cdcData)"
                  :class="{'table-primary' : isDataPackageSelected(cdcData.id)}"
                  :key="index"
                >
                  <td> {{ cdcData.recordedtime | formatDate }} </td>
                  <td> {{ cdcData.sensortime | formatDate }} </td>
                  <td>
                    <ul class="list-group">
                      <li v-for="(valueObject, vIndex) in visibleDataValues(cdcData.values)" :key="vIndex" class="list-group-item d-flex justify-content-between align-items-center">
                        <span> {{ localizedPart(valueObject.systempart) }} </span> <span> {{ decimalValue(valueObject) }} {{ localizedUnit(valueObject.type) }} </span>
                      </li>
                    </ul>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <page-footer />
  </div>
</template>

<script>
import moment from 'moment'
import objectSorter from '@/utils/object-sorter'
import PageHeader from '@/components/PageHeader.vue'
import ErrorRow from '@/components/ErrorRow.vue'
import PageFooter from '@/components/PageFooter.vue'
import RangeSlider from '@/components/RangeSlider.vue'
import PageViewMixin from '@/mixins/PageViewMixin.js'

import { mapGetters } from 'vuex'

export default {
  name: 'CDCDataValuesPage',
  mixins: [PageViewMixin],
  data () {
    return {
      cdcDeviceId: 0,
      selectedDataPackage: null,
      selectedDevice: { id: -1, facilityid: -1, apikey: '', serialnumber: '' },
      currentSortKey: 'recordedtime',
      currentSortOrder: 'desc',
      isLoading: false,
      showTableData: false,
      dayOfInterest: moment(new Date()).subtract(24, 'hours').toDate(),
      // FIXME!!! Mit wievielen Detail-Varianten muss man eigentlich rechnen?
      defaultIndexMap: { 0: false, 1: false, 2: false, 3: false, 4: false, 5: false, 6: false, 7: false, 8: false, 9: false },
      sensorIdCountMap: {},
      sensorIdIndexMap: {},
      sensorIdSwitchMap: new Map(),
      systemPartCountMap: {},
      systemPartIndexMap: {},
      systemPartSwitchMap: new Map(),
      quantityCountMap: {},
      quantityIndexMap: {},
      quantitySwitchMap: new Map(),
      sliderBounds: [0, 100]

    }
  },
  filters: {
    formatDate (date) {
      if (date) {
        return moment(String(date)).format('DD.MM.YYYY HH:mm')
      }
    }
  },
  mounted () {
    this.clearError()
    this.cdcDeviceId = this.$route.params.cdcdeviceid
    if (this.cdcDeviceId > 0) {
      this.selectedDevice = { id: this.cdcDeviceId, facilityid: -1, apikey: '' }
      this.loadDataAndValues(true)
    }
  },
  destroyed () {
    this.$store.dispatch('discardCDCDataAndValues')
  },
  computed: {
    ...mapGetters([
      'client',
      'hasOrganizations',
      'organizationMap',
      'facilities',
      'hasFacilities',
      'facilityMap',
      'cdcdevices',
      'hasCdcdevices',
      'cdcDeviceMap',
      'cdcdatavalues',
      'hasCdcdatavalues'
    ]),
    selectedDataPackageId () {
      return this.selectedDataPackage ? this.selectedDataPackage.id : -1
    },
    emptyDataPackage () {
      return {
        id: -1,
        cdcdeviceid: this.selectedDevice.id,
        recordedtime: null,
        sensortime: null
      }
    },
    sortedCdcData () {
      return objectSorter.sortedArray(this.visibleDataPackages(), this.currentSortOrder, this.currentSortKey)
    },
    cdcDataSortedByTime () {
      return objectSorter.sortedArray(this.visibleDataPackages(), 'asc', 'recordedtime')
    },
    facilityForDevice () {
      return this.selectedDevice.id > 0 && this.hasFacilities ? this.facilityMap[this.selectedDevice.facilityid] : { id: -1, name: '' }
    },
    organizationForFacility () {
      return this.facilityForDevice.id > 0 && this.hasOrganizations ? this.organizationMap[this.facilityForDevice.organizationid] : { name: '', code: '' }
    },
    sensorIdCountMapKeys () {
      return this.sortRelevantNumericMapKeys(this.sensorIdCountMap)
    },
    systemPartCountMapKeys () {
      return this.sortRelevantNumericMapKeys(this.systemPartCountMap)
    },
    quantityCountMapKeys () {
      return this.sortRelevantNumericMapKeys(this.quantityCountMap)
    },
    chartDataSimple () {
      const data = []
      this.cdcDataSortedByTime.forEach(dataPackage => {
        const valueObjects = this.visibleDataValues(dataPackage.values)
        valueObjects.forEach(valueObject => {
          if (valueObject.systempart === 4 && valueObject.type === 3) {
            const stamp = moment(String(dataPackage.recordedtime)).format('HH:mm:ss')
            data.push([stamp, this.decimalValue(valueObject)])
          }
        })
      })
      return data
    },
    chartXaxisLabels () {
      const labels = []
      this.cdcDataSortedByTime.forEach(dataPackage => {
        const stamp = moment(String(dataPackage.recordedtime)).format('HH:mm:ss')
        labels.push(stamp)
      })
      return labels
    },
    chartPowerData () {
      const data = {}
      const dataPackages = this.cdcDataSortedByTime
      const bounds = this.timeStampsForSliderRange(dataPackages)
      dataPackages.forEach(dataPackage => {
        if (this.insideTimeStampBounds(dataPackage, bounds)) {
          const valueObjects = this.visibleDataValues(dataPackage.values)
          valueObjects.forEach(valueObject => {
            if (valueObject.systempart === 4 && valueObject.type === 3) {
              const stamp = moment(String(dataPackage.recordedtime)).format('HH:mm:ss')
              data[stamp] = this.decimalValue(valueObject)
            }
          })
        }
      })
      return data
    },
    chartEnergyData () {
      const data = {}
      const dataPackages = this.cdcDataSortedByTime
      const bounds = this.timeStampsForSliderRange(dataPackages)
      dataPackages.forEach(dataPackage => {
        if (this.insideTimeStampBounds(dataPackage, bounds)) {
          const valueObjects = this.visibleDataValues(dataPackage.values)
          valueObjects.forEach(valueObject => {
            if (valueObject.systempart === 4 && valueObject.type === 9) {
              const stamp = moment(String(dataPackage.recordedtime)).format('HH:mm:ss')
              data[stamp] = this.decimalValue(valueObject) / 1000.0
            }
          })
        }
      })
      return data
    },
    chartDataSets () {
      const data = []

      const powerData = this.chartPowerData
      if (powerData && Object.keys(powerData).length > 0) {
        const datasetPower = {
          type: 'line',
          name: 'Leistung in Watt',
          color: 'rgba(54,73,93,.5)',
          data: powerData
        }
        data.push(datasetPower)
      }

      const energyData = this.chartEnergyData
      if (energyData && Object.keys(energyData).length > 0) {
        const datasetEnergy = {
          type: 'bar',
          name: 'Verbrauch in Kilowatt-Stunden',
          color: 'rgba(71, 183,132,.5)',
          data: energyData
        }
        data.push(datasetEnergy)
      }

      return data
    },
    sliderValuesFormatted () {
      const bounds = this.timeStampsForSliderRange(this.cdcDataSortedByTime)
      if (bounds && bounds.length === 2) {
        const t1 = moment(bounds[0]).format('DD.MM.YYYY HH:mm:ss')
        const t2 = moment(bounds[1]).format('DD.MM.YYYY HH:mm:ss')
        return [t1, t2]
      }
      return ['', '']
    }
  },
  components: {
    'page-header': PageHeader,
    'error-row': ErrorRow,
    'page-footer': PageFooter,
    'range-slider': RangeSlider
  },
  watch: {
    cdcdatavalues () {
      if (!this.running) {
        this.dataHaveBeenLoaded()
      }
    },
    dayOfInterest () {
      if (this.cdcDeviceId > 0) {
        if (this.hasCdcdatavalues) {
          this.switchUpdate()
        }
        this.loadDataAndValues(true)
      }
    }
  },
  methods: {
    getStartTime () {
      if (this.dayOfInterest) {
        if (typeof this.dayOfInterest === 'string' || this.dayOfInterest instanceof String) {
          // console.log('getStartTime : ' + this.dayOfInterest)
          const date = new Date(this.dayOfInterest)
          return date ? date.getTime() : new Date().getTime()
        } else {
          return this.dayOfInterest.getTime()
        }
      } else {
        return moment(new Date()).subtract(24, 'hours').toDate()
      }
    },
    loadDataAndValues (reset) {
      this.isLoading = true
      const startTime = this.getStartTime()
      const payload = { cdcDeviceId: this.selectedDevice.id, fromDate: startTime, untilDate: 0 }
      this.$store.commit('clearError')
      this.$store.dispatch('getCDCDataValues', payload)
      if (reset) {
        this.selectedSensor = this.emptySensor
      }
    },
    backToDevices () {
      this.$router.push({ name: 'cdcdevices', params: { facilityid: this.selectedDevice.facilityid } })
    },
    isDataPackageSelected (id) {
      return this.selectedDataPackage && id === this.selectedDataPackage.id
    },
    selectRow (dataPackage) {
      if (this.selectedDataPackage !== dataPackage) {
        this.selectedDataPackage = dataPackage
      } else {
        this.selectedDataPackage = this.emptyDataPackage
      }
    },
    sort (key) {
      // reverse sort order?
      if (key === this.currentSortKey) {
        this.currentSortOrder = this.currentSortOrder === 'asc' ? 'desc' : 'asc'
      }
      this.currentSortKey = key
    },
    sortRelevantNumericMapKeys (map) {
      const array = []
      for (var key in map) {
        const cnt = map[key]
        if (cnt && cnt > 0) {
          array.push(key)
        }
      }
      return array.sort((a, b) => a - b)
    },
    dataHaveBeenLoaded () {
      if (this.cdcDeviceId > 0 && this.hasCdcdevices) {
        this.selectedDevice = this.cdcDeviceMap[this.cdcDeviceId]
      }
      this.initCounterAndFilterMaps()
      if (this.hasCdcdatavalues) {
        this.cdcdatavalues.forEach(pkg => {
          var cnt = this.sensorIdCountMap[pkg.sensorpk]
          this.sensorIdCountMap[pkg.sensorpk] = !cnt ? 1 : cnt + 1
          // Mit den Value-Details weitere Zähler befüllen
          pkg.values.forEach(valueObj => {
            cnt = this.systemPartCountMap[valueObj.systempart]
            this.systemPartCountMap[valueObj.systempart] = !cnt ? 1 : cnt + 1
            //
            cnt = this.quantityCountMap[valueObj.type]
            this.quantityCountMap[valueObj.type] = !cnt ? 1 : cnt + 1
          })
        })

        //
        // Wichtig: die Keys müssen sortiert vorliegen, damit der Index sinnvoll
        // für den späteren Lookup verwendet werden kann!
        //
        var anySwitch = false
        for (const key of this.sensorIdSwitchMap.keys()) {
          if (this.sensorIdSwitchMap.get(key)) {
            const index = this.sensorIdCountMapKeys.indexOf('' + key)
            if (index >= 0) {
              this.sensorIdIndexMap[index] = true
              anySwitch = true
            }
          }
        }
        if (!anySwitch) {
          for (const index of this.sensorIdCountMapKeys) {
            this.sensorIdIndexMap[index] = true
          }
        }

        anySwitch = false
        for (const key of this.systemPartSwitchMap.keys()) {
          if (this.systemPartSwitchMap.get(key)) {
            const index = this.systemPartCountMapKeys.indexOf('' + key)
            if (index >= 0) {
              this.systemPartIndexMap[index] = true
              anySwitch = true
            }
          }
        }
        if (!anySwitch) {
          for (const index of this.systemPartCountMapKeys) {
            this.systemPartIndexMap[index] = true
          }
        }

        anySwitch = false
        for (const key of this.quantitySwitchMap.keys()) {
          if (this.quantitySwitchMap.get(key)) {
            const index = this.quantityCountMapKeys.indexOf('' + key)
            if (index >= 0) {
              this.quantityIndexMap[index] = true
              anySwitch = true
            }
          }
        }
        if (!anySwitch) {
          for (const index of this.systemPartCountMapKeys) {
            this.quantityIndexMap[index] = true
          }
        }
      }
    },
    visibleDataPackages () {
      const visiblePackages = []
      this.cdcdatavalues.forEach(pkg => {
        //
        // Haken an der Sensor-ID?
        //
        var idx = this.sensorIdCountMapKeys.indexOf('' + pkg.sensorpk)
        if (idx >= 0 && this.sensorIdIndexMap[idx]) {
          var match = false
          pkg.values.forEach(valueObj => {
            idx = this.systemPartCountMapKeys.indexOf('' + valueObj.systempart)
            if (idx >= 0 && this.systemPartIndexMap[idx]) {
              idx = this.quantityCountMapKeys.indexOf('' + valueObj.type)
              if (this.quantityIndexMap[idx]) {
                match = true
              }
            }
          })
          if (match) {
            visiblePackages.push(pkg)
          }
        }
      })
      return visiblePackages
    },
    visibleDataValues (valueObjects) {
      const visibleValues = []
      valueObjects.forEach(valueObj => {
        var idx = this.systemPartCountMapKeys.indexOf('' + valueObj.systempart)
        if (idx >= 0 && this.systemPartIndexMap[idx]) {
          idx = this.quantityCountMapKeys.indexOf('' + valueObj.type)
          if (this.quantityIndexMap[idx]) {
            visibleValues.push(valueObj)
          }
        }
      })
      return objectSorter.sortArray(visibleValues, 'asc', 'systempart')
    },
    initCounterAndFilterMaps () {
      this.sensorIdCountMap = {}
      this.sensorIdIndexMap = Object.assign({}, this.defaultIndexMap)
      this.systemPartCountMap = {}
      this.systemPartIndexMap = Object.assign({}, this.defaultIndexMap)
      this.quantityCountMap = {}
      this.quantityIndexMap = Object.assign({}, this.defaultIndexMap)
    },
    //
    // Übertragung der aktuellen Auswahl der anzuzeigenden Merkmale
    // an die die konkret vorliegenden Daten übergreifenden Maps
    //
    switchUpdate () {
      this.sensorIdSwitchMap = new Map()
      for (const [index, val] of this.sensorIdCountMapKeys.entries()) {
        if (this.sensorIdIndexMap[index]) {
          this.sensorIdSwitchMap.set('' + val, true)
        }
      }

      this.systemPartSwitchMap = new Map()
      for (const [index, val] of this.systemPartCountMapKeys.entries()) {
        if (this.systemPartIndexMap[index]) {
          this.systemPartSwitchMap.set('' + val, true)
        }
      }

      this.quantitySwitchMap = new Map()
      for (const [index, val] of this.quantityCountMapKeys.entries()) {
        if (this.quantityIndexMap[index]) {
          this.quantitySwitchMap.set('' + val, true)
        }
      }
    },
    decimalValue (valueObject) {
      return valueObject.value * Math.pow(10, valueObject.factor)
    },
    localizedUnit (valueObjectType) {
      return this.$t('physicalUnit.' + valueObjectType)
    },
    localizedPart (valueObjectSystemPart) {
      return this.$t('systemPart.' + valueObjectSystemPart)
    },
    toggleDisplayTableData () {
      this.showTableData = !this.showTableData
    },
    rangeUpdate (range) {
      this.sliderBounds = range
    },
    timeStampsForSliderRange (dataPackages) {
      if (dataPackages.length > 0) {
        const t1 = moment(String(dataPackages[0].recordedtime))
        const t2 = moment(String(dataPackages[dataPackages.length - 1].recordedtime))

        var delta = t2 - t1
        if (delta < 0) delta = 0

        const b1 = t1 + delta * this.sliderBounds[0] / 100
        const b2 = t1 + delta * this.sliderBounds[1] / 100

        return [b1, b2]
      }
      return []
    },
    insideTimeStampBounds (dataPackage, bounds) {
      const t = moment(String(dataPackage.recordedtime))
      return (t >= bounds[0] && t <= bounds[1])
    }
  }
}
</script>

<style lang="scss" scoped>
.content-container {
  padding: 0 35px;

  h2 {
    font-size: 18px;
    margin-bottom: 15px;
    font-weight: 400;
  }

  .optional-section {
    margin-top: 20px;
  }

  .table-section {
    margin-top: 20px;
  }

  table.auto-col {
    table-layout: auto;
  }

  .grey {
    color: #808080;
  }

  .blue-text {
    color: #0069d9;
    font-weight: 700;
  }

  .toggle-icon {
    margin-left: 20px;
  }
}
</style>
