import clientService from '@/services/client'
import organizationService from '@/services/organizations'
import facilityService from '@/services/facilities'
import cdcDeviceService from '@/services/cdcdevices'
import cdcSensorService from '@/services/cdcsensors'
import userService from '@/services/users'
import collectionsService from '@/services/collections'
import companyService from '@/services/company'

export const logout = ({ commit }) => {
  commit('logout')
}

export const getClientData = ({ commit }) => {
  commit('updateRunning', { flag: true })
  clientService.getClientData().then(data => {
    commit('updateClientData', data)
  }).catch(error => {
    commit('updateErrorMessage', { flag: true, origin: 'getClientData', message: error.message })
  })
}

export const getOrganizationData = ({ commit }) => {
  commit('updateRunning', { flag: true })
  organizationService.getOrganizationData().then(data => {
    commit('updateOrganizationData', data)
  }).catch(error => {
    commit('updateErrorMessage', { flag: true, origin: 'getOrganizationData', message: error.message })
  })
}

export const getFacilityData = ({ commit }, payload) => {
  commit('updateRunning', { flag: true })
  facilityService.getFacilityData(payload.organizationId).then(data => {
    commit('updateFacilityData', data)
  }).catch(error => {
    commit('updateErrorMessage', { flag: true, origin: 'getFacilityData', message: error.message })
  })
}

export const getCDCDeviceData = ({ commit }, payload) => {
  commit('updateRunning', { flag: true })
  cdcDeviceService.getCDCDeviceData(payload.facilityId).then(data => {
    commit('updateCDCDeviceData', data)
  }).catch(error => {
    commit('updateErrorMessage', { flag: true, origin: 'getCDCDeviceData', message: error.message })
  })
}

export const getCDCSensorData = ({ commit }, payload) => {
  commit('updateRunning', { flag: true })
  cdcSensorService.getCDCSensorData(payload.cdcDeviceId).then(data => {
    commit('updateCDCSensorData', data)
  }).catch(error => {
    commit('updateErrorMessage', { flag: true, origin: 'getCDCSensorData', message: error.message })
  })
}

export const getSensorEventData = ({ commit }, payload) => {
  commit('updateRunning', { flag: true })
  cdcSensorService.getSensorEventData(payload.cdcSensorId).then(data => {
    commit('updateSensorEventData', data)
  }).catch(error => {
    commit('updateErrorMessage', { flag: true, origin: 'getSensorEventData', message: error.message })
  })
}

export const getCDCDataValues = ({ commit }, payload) => {
  commit('updateRunning', { flag: true })
  cdcDeviceService.getCDCDataValues(payload.cdcDeviceId, payload.fromDate, payload.untilDate).then(data => {
    commit('updateCDCDataValues', data)
  }).catch(error => {
    commit('updateErrorMessage', { flag: true, origin: 'getCDCDataValues', message: error.message })
  })
}

export const getCDCDataValuesSlim = ({ commit }, payload) => {
  commit('updateRunning', { flag: true })
  cdcDeviceService.getCDCDataValues(payload.cdcDeviceId, payload.fromDate, payload.untilDate).then(data => {
    commit('updateCDCDataValuesSlim', data)
  }).catch(error => {
    commit('updateErrorMessage', { flag: true, origin: 'getCDCDataValues', message: error.message })
  })
}

export const getCDCDataHierarchy = ({ commit }, payload) => {
  commit('updateRunning', { flag: true })
  cdcDeviceService.getCDCDataHierarchy(payload.cdcDeviceId, payload.fromDate, payload.untilDate).then(data => {
    commit('updateOrganizationData', data)
  }).catch(error => {
    commit('updateErrorMessage', { flag: true, origin: 'getCDCDataHierarchy', message: error.message })
  })
}

export const getLatestCDCDataValues = ({ commit }, payload) => {
  commit('updateRunning', { flag: true })
  organizationService.getLatestCDCDataValues(payload.organizationId).then(data => {
    commit('updateOrganizationData', data)
  }).catch(error => {
    commit('updateErrorMessage', { flag: true, origin: 'getLatestCDCDataValues', message: error.message })
  })
}

export const getDailyTotals = ({ commit }, payload) => {
  commit('updateRunning', { flag: true })
  organizationService.getDailyTotals(payload.dateString, payload.formatId).then(data => {
    commit('updateDailyTotals', data)
  }).catch(error => {
    commit('updateErrorMessage', { flag: true, origin: 'updateDailyTotals', message: error.message })
  })
}

export const getUserData = ({ commit }) => {
  commit('updateRunning', { flag: true })
  userService.getUserData().then(data => {
    commit('updateUserData', data)
  }).catch(error => {
    commit('updateErrorMessage', { flag: true, origin: 'getUserData', message: error.message })
  })
}

export const getUserPushTokensData = ({ commit }, payload) => {
  commit('updateRunning', { flag: true })
  userService.getUserPushTokensData(payload.userId).then(data => {
    commit('updateUserPushTokensData', data)
  }).catch(error => {
    commit('updateErrorMessage', { flag: true, origin: 'getUserPushTokensData', message: error.message })
  })
}

export const getCollectionsData = ({ commit }) => {
  commit('updateRunning', { flag: true })
  collectionsService.getCollectionsData().then(data => {
    commit('updateCollectionsData', data)
  }).catch(error => {
    commit('updateErrorMessage', { flag: true, origin: 'getCollectionsData', message: error.message })
  })
}

export const clearError = ({ commit }) => {
  commit('updateErrorMessage', { flag: false, origin: '', message: '' })
}

export const getCompanyData = ({ commit }) => {
  commit('updateRunning', { flag: true })
  companyService.getCompany().then(data => {
    commit('updateCompanyData', data)
  }).catch(error => {
    commit('updateErrorMessage', { flag: true, origin: 'getCompany', message: error.message })
  })
}
