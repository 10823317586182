  <template>
  <div class="container public">
    <page-header />
    <div class="content-container">
      <div class="<overview-list-section">
        <h2 class="section-title">{{ $t('facilitiesPage.title') }} <span class="blue-text"> {{ selectedOrganization.name }} </span></h2>
        <div class="btn-toolbar">
          <button v-if="userIsAdmin" type="button" class="btn btn-info mr-3" @click="backToOrganizations">
            <font-awesome-icon icon="arrow-alt-circle-left" /> &nbsp; {{ $t('facilitiesPage.backToOrganizations') }}
          </button>
         <button v-if="userMayEdit" type="button" class="btn btn-primary mr-3" @click="showDetail('add')">
           <font-awesome-icon icon="plus" /> &nbsp; {{ $t('facilitiesPage.addButton') }}
         </button>
         <button  v-if="userMayEdit" type="button" class="btn btn-secondary  mr-3" @click="showDetail('edit')"
           :disabled="this.selectedFacilityId === -1"><font-awesome-icon icon="edit" />&nbsp; {{ $t('facilitiesPage.editButton') }}</button>
         <button  v-if="userMayEdit" type="button" class="btn btn-secondary  mr-3" @click="askDelete"
           :disabled="this.selectedFacilityId  === -1"><font-awesome-icon icon="trash" />&nbsp; {{ $t('facilitiesPage.deleteButton') }}</button>
        </div>
        <error-row @clearError="clearError" :errorMessage="errorMessage" />
        <div v-show="isLoading" class="is-loading-section" >
          <button class="btn btn-primary"><span class="spinner-border spinner-border-sm"></span></button><span> {{ $t('facilitiesPage.isLoading') }} </span>
        </div>
        <div v-show="!isLoading && hasFacilities" id="table-section" >
          <table class="table table-hover auto-col">
            <thead>
              <tr>
                <th v-if="userMayEdit">&nbsp;</th>
                <th @click="sort('name')">{{ $t('facilitiesPage.columnName') }}</th>
                <th @click="sort('description')">{{ $t('facilitiesPage.columnDescription') }}</th>
                <th @click="sort('geolatitude')">{{ $t('facilitiesPage.columnLatitude') }}</th>
                <th @click="sort('geolongitude')">{{ $t('facilitiesPage.columnLongitude') }}</th>
                <th>{{ $t('facilitiesPage.columnShowMap') }}</th>
                <th colspan="2" @click="sort('devicecnt')">{{ $t('facilitiesPage.columnDeviceCnt') }}</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(facility, index) in sortedFacilities"
                @click="selectRow(facility)"
                :class="{'table-primary' : isFacilitySelected(facility.id)}"
                :key="index"
              >
                <td v-if="userMayEdit"><span @click.stop="editFacility(facility)"><font-awesome-icon icon="edit" /></span></td>
                <td> {{ facility.name }} </td>
                <td> {{ facility.description }} </td>
                <td class="text-center"> {{ facility.geolatitude }} </td>
                <td class="text-center"> {{ facility.geolongitude }} </td>
                <td><button v-if="facility.geolatitude && facility.geolongitude" class="btn btn-outline-info btn-sm" @click="showMapSnapshot(facility)">{{ $t('facilitiesPage.showMapSnapshot') }}</button></td>
                <td class="text-center"><span v-if="facility.devicecnt > 0">{{ facility.devicecnt }}</span></td>
                <td><button class="btn btn-outline-info btn-sm" @click="gotoDevices(facility)">{{ $t('facilitiesPage.editDevices') }}</button></td>
              </tr>
            </tbody>
         </table>
        </div>
      </div>
    </div>
    <page-footer />

    <edit-facility-modal @modalClosed="modalClosed" :selectedOrganization="selectedOrganization" :selectedFacility="selectedFacility" :action="selectedAction" :modalOpened="modalTimestamp" />
    <map-snapshot-modal @modalClosed="modalClosed" :selectedOrganization="selectedOrganization" :selectedFacility="showMapFacility" :action="selectedAction" :modalOpened="modalTimestamp" />

    <confirm-modal
      :title="$t('facilitiesPage.deleteFacility')"
      :text="confirmDeleteText"
      :confirmButtonLabel="$t('confirmModal.confirmButtonLabel')"
      :cancelButtonLabel="$t('confirmModal.cancelButtonLabel')"
      @confirm="confirmDelete"
      @modalClosed="modalClosed"
    />
  </div>
</template>

<script>
import objectSorter from '@/utils/object-sorter'
import PageHeader from '@/components/PageHeader.vue'
import ErrorRow from '@/components/ErrorRow.vue'
import PageFooter from '@/components/PageFooter.vue'
import ConfirmModal from '@/modals/ConfirmModal.vue'
import PageViewMixin from '@/mixins/PageViewMixin.js'
import EditFacilityModal from '@/modals/EditFacilityModal.vue'
import MapSnapshotModal from '@/modals/MapSnapshotModal.vue'
import facilityService from '@/services/facilities'

import { mapGetters } from 'vuex'

export default {
  name: 'FacilitiesPage',
  mixins: [PageViewMixin],
  data () {
    return {
      showMapFacility: {},
      selectedFacility: { id: -1, name: '' },
      organizationid: 0,
      selectedOrganization: { id: -1, name: '', code: '' },
      selectedAction: 'add',
      currentSortKey: 'name',
      currentSortOrder: 'asc',
      isLoading: false
    }
  },
  mounted () {
    this.clearError()
    this.organizationid = this.$route.params.organizationid
    if (this.organizationid > 0) {
      this.selectedOrganization = { id: this.organizationid, name: '', code: '' }
      this.loadFacilities(true)
    }
  },
  destroyed () {
    this.$store.dispatch('discardFacilityData')
  },
  computed: {
    ...mapGetters([
      'client',
      'hasOrganizations',
      'organizationMap',
      'facilities',
      'hasFacilities'
    ]),
    selectedFacilityId () {
      return this.selectedFacility ? this.selectedFacility.id : -1
    },
    emptyFacility () {
      return {
        id: -1,
        organizationid: this.selectedOrganization.id,
        name: '',
        description: '',
        geolatitude: null,
        geolongitude: null
      }
    },
    confirmDeleteText () {
      if (this.selectedFacility != null) {
        return this.$t('facilitiesPage.confirmDeleteText', { name: this.selectedFacility.name })
      }
      return ''
    },
    sortedFacilities () {
      return objectSorter.sortedArray(this.facilities, this.currentSortOrder, this.currentSortKey)
    }
  },
  components: {
    'page-header': PageHeader,
    'error-row': ErrorRow,
    'page-footer': PageFooter,
    'edit-facility-modal': EditFacilityModal,
    'map-snapshot-modal': MapSnapshotModal,
    'confirm-modal': ConfirmModal
  },
  watch: {
    facilities () {
      if (!this.running) {
        this.dataHaveBeenLoaded()
      }
    }
  },
  methods: {
    loadFacilities (reset) {
      this.isLoading = true
      const payload = { organizationId: this.selectedOrganization.id }
      this.$store.commit('clearError')
      this.$store.dispatch('getFacilityData', payload)
      if (reset) {
        this.selectedFacility = this.emptyFacility
      }
    },
    backToOrganizations () {
      this.$router.push({ name: 'organizations' })
    },
    isFacilitySelected (id) {
      return this.selectedFacility && id === this.selectedFacility.id
    },
    selectRow (facility) {
      if (this.selectedFacility !== facility) {
        this.selectedFacility = facility
      } else {
        this.selectedFacility = this.emptyFacility
      }
    },
    showDetail (action) {
      if (action === 'add') {
        this.selectedFacility = this.emptyFacility
        this.selectedAction = 'add'
      } else {
        this.selectedAction = 'edit'
      }
      this.showModalView()
    },
    editFacility (facility) {
      this.selectedFacility = facility
      this.selectedAction = 'edit'
      this.showModalView()
    },
    showModalView () {
      this.openModal('#editFacilityModal')
    },
    showMapSnapshot (facility) {
      if (facility.geolatitude && facility.geolongitude) {
        this.showMapFacility = facility
        this.openModal('#mapSnapshotModal')
      }
    },
    confirmDelete () {
      facilityService.deleteFacility(this.selectedFacility.id).then((deletedFacility) => {
        this.loadFacilities(true)
      }).catch(error => {
        this.errorMessage = this.localizedBackendMessage(error.message)
      })
    },
    sort (key) {
      // reverse sort order?
      if (key === this.currentSortKey) {
        this.currentSortOrder = this.currentSortOrder === 'asc' ? 'desc' : 'asc'
      }
      this.currentSortKey = key
    },
    reloadData () {
      this.loadFacilities(false)
    },
    gotoDevices (facility) {
      this.$router.push({ name: 'cdcdevices', params: { facilityid: facility.id } })
    },
    dataHaveBeenLoaded () {
      if (this.organizationid > 0 && this.hasOrganizations) {
        this.selectedOrganization = this.organizationMap[this.organizationid]
      }
    },
    createMapLink (facility) {
      if (facility && facility.geolatitude && facility.geolongitude) {
        // return 'http://maps.apple.com/?ll=' + facility.geolatitude + ',' + facility.geolongitude
        return 'maps://q=' + facility.geolatitude + ',' + facility.geolongitude
      }
      return ''
    }
  }
}
</script>

<style lang="scss" scoped>
.content-container {
  padding: 0 35px;

  h2 {
    font-size: 18px;
    margin-bottom: 15px;
    font-weight: 400;
  }

  .magazine-list-section {
    margin: 30px 10px;
  }

  .representation-hierarchy {
    margin-top: 30px;
  }

  table.auto-col {
    table-layout: auto;
  }

  .grey {
    color: #808080;
  }

  .blue-text {
    color: #0069d9;
    font-weight: 700;
  }
  .toggle-icon {
    margin-left: 20px;
  }
}
</style>
