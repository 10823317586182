<template>
  <form @submit.prevent="saveFacility" ref="facilityEditForm">
    <div class="modal" tabindex="-1" role="dialog" backdrop="static" id="editFacilityModal" @keyup.esc="onEsc($event)">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header header">
            <h3 class="modal-title"><span v-if="action === 'add'">{{ $t('editFacilityModal.titleAdd') }}</span><span v-if="action === 'edit'">{{ $t('editFacilityModal.titleEdit') }}</span></h3>
            <button type="button" class="close" @click="close" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="form">
              <div v-show="errorMessage" class="alert alert-danger failed">{{ errorMessage }}</div>
              <div class="form-group">
                <label for="organizationName">{{ $t('editFacilityModal.organizationName') }}</label>
                <div id="organizationName" class="blue-text">{{ selectedOrganization.name }}</div>
              </div>
              <div class="form-group">
                <label for="nameInput">{{ $t('editFacilityModal.nameInput') }}</label>
                <input type="text" class="form-control" :class="{ 'is-invalid': $v.facility.name.$dirty && $v.facility.name.$error }" id="nameInput" v-model.trim="facility.name" :placeholder="$t('editFacilityModal.namePlaceholder')" maxlength="255">
                <div class="invalid-feedback" v-if="$v.facility.name.$dirty">
                  <div class="error" v-if="!$v.facility.name.required">{{ $t('editFacilityModal.nameRequired') }}</div>
                  <div class="error" v-if="!$v.facility.name.minLength">{{ $t('editFacilityModal.nameMinLength', { min: $v.facility.name.$params.minLength.min }) }}</div>
                  <div class="error" v-if="!$v.facility.name.maxLength">{{ $t('editFacilityModal.nameMaxLength', { max: $v.facility.name.$params.maxLength.max }) }}</div>
                </div>
              </div>
              <div class="form-group">
                <label for="descriptionInput">{{ $t('editFacilityModal.descriptionInput') }}</label>
                <textarea-autosize rows="1" class="form-control" :class="{ 'is-invalid': $v.facility.description.$dirty && $v.facility.description.$error }" id="descriptionInput" v-model.trim="facility.description" :placeholder="$t('editFacilityModal.descriptionPlaceholder')" maxlength="255" :max-height="150" />
                <div class="invalid-feedback" v-if="$v.facility.description.$dirty">
                  <div class="error" v-if="!$v.facility.description.maxLength">{{ $t('editFacilityModal.descriptionMaxLength', { max: $v.facility.description.$params.maxLength.max }) }}</div>
                </div>
              </div>
              <div class="form-group">
                <label for="geolatitudeInput">{{ $t('editFacilityModal.geolatitudeInput') }}</label>
                <input type="text" class="form-control" :class="{ 'is-invalid': $v.facility.geolatitude.$dirty && $v.facility.geolatitude.$error }" id="geolatitudeInput" v-model="facility.geolatitude" :placeholder="$t('editFacilityModal.geolatitudePlaceholder')">
                <div class="invalid-feedback" v-if="$v.facility.geolatitude.$dirty">
                  <div class="error" v-if="!$v.facility.geolatitude.decimal">{{ $t('editFacilityModal.geolatitudeValue') }}</div>
                  <div class="error" v-if="!$v.facility.geolatitude.minValue">{{ $t('editFacilityModal.geolatitudeValue') }}</div>
                  <div class="error" v-if="!$v.facility.geolatitude.maxValue">{{ $t('editFacilityModal.geolatitudeValue') }}</div>
                </div>
              </div>
              <div class="form-group">
                <label for="geolongitudeInput">{{ $t('editFacilityModal.geolongitudeInput') }}</label>
                <input type="text" class="form-control" :class="{ 'is-invalid': $v.facility.geolongitude.$dirty && $v.facility.geolongitude.$error }" id="geolongitudeInput" v-model="facility.geolongitude" :placeholder="$t('editFacilityModal.geolongitudePlaceholder')">
                <div class="invalid-feedback" v-if="$v.facility.geolongitude.$dirty">
                  <div class="error" v-if="!$v.facility.geolongitude.decimal">{{ $t('editFacilityModal.geolongitudeValue') }}</div>
                  <div class="error" v-if="!$v.facility.geolongitude.minValue">{{ $t('editFacilityModal.geolongitudeValue') }}</div>
                  <div class="error" v-if="!$v.facility.geolongitude.maxValue">{{ $t('editFacilityModal.geolongitudeValue') }}</div>
                </div>
              </div>
              <div v-if="facility.geolatitude && facility.geolongitude" class="form-group">
                <label><a :href="createMapLink" target="_blank" >{{ $t('editFacilityModal.linkToMap') }}</a></label>
              </div>
              <div class="form-group">
                <label for="externalidInput">{{ $t('editFacilityModal.externalidInput') -  $t('v.facility.externalid')}}</label>
                <textarea-autosize rows="1" class="form-control" :class="{ 'is-invalid': $v.facility.externalid.$dirty && $v.facility.externalid.$error }" id="externalidInput" v-model.trim="facility.externalid" :placeholder="$t('editFacilityModal.externalidPlaceholder')" maxlength="64" />
                <div class="invalid-feedback" v-if="$v.facility.externalid.$dirty">
                  <div class="error" v-if="!$v.facility.externalid.maxLength">{{ $t('editFacilityModal.externalidMaxLength', { max: $v.facility.externalid.$params.maxLength.max }) }}</div>
                </div>
              </div>
                <div class="form-group">
                  <label for="facilityTypeInput">{{ $t('editFacilityModal.facilityTypeInput') }}</label>
                  <select class="form-control" id="facilityTypeInput" v-model="facility.type" @change="checkType">
                    <option v-for="type in facilityTypeList" :value="type" :key="type">
                      {{ facilityTypeString(type) }}
                    </option>
                  </select>

<!--
                  <textarea-autosize rows="1" class="form-control" :class="{ 'is-invalid': $v.facility.type.$dirty && $v.facility.type.$error }" id="facilityTypeInput" v-model.trim="facility.type" :placeholder="$t('editFacilityModal.facilityTypePlaceholder')"/>

                  <div class="invalid-feedback" v-if="$v.facility.type.$dirty">
                    <div class="error" v-if="!$v.facility.type.maxLength">{{ $t('editFacilityModal.typeMaxLength', { max: $v.facility.type.$params.maxLength.max }) }}</div>
                  </div>
 -->
                </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="submit" class="btn btn-primary"><span v-if="action === 'add'">{{ $t('editFacilityModal.buttonAdd') }}</span><span v-if="action === 'edit'">{{ $t('editFacilityModal.buttonSave') }}</span></button>
            <button type="button" class="btn btn-secondary" @click="close">{{ $t('editFacilityModal.close') }}</button>
          </div>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import $ from 'jquery'
import { required, minLength, maxLength, decimal, minValue, maxValue } from 'vuelidate/lib/validators'
import ModalViewMixin from '@/mixins/ModalViewMixin.js'
import facilityService from '@/services/facilities'

export default {
  name: 'EditFacilityModal',
  mixins: [ModalViewMixin],
  props: {
    selectedOrganization: {
      type: Object,
      required: true
    },
    selectedFacility: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      facility: {},
      facilityTypeList: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15],
      errorMessage: ''
    }
  },
  watch: {
    modalOpened () {
      this.copyFacility()
      this.triggerFocus()
    }
  },
  validations: {
    facility: {
      name: {
        required,
        minLength: minLength(1),
        maxLength: maxLength(255)
      },
      description: {
        maxLength: maxLength(255)
      },
      geolatitude: {
        decimal,
        minValue: minValue(-90.0),
        maxValue: maxValue(90.0)
      },
      geolongitude: {
        decimal,
        minValue: minValue(-180.0),
        maxValue: maxValue(180.0)
      },
      externalid: {
        maxLength: maxLength(64)
      },
      type: {
        decimal,
        maxValue: maxValue(255)
      }
    }
  },
  computed: {
    createMapLink () {
      if (this.facility && this.facility.geolatitude && this.facility.geolongitude) {
        return 'http://maps.apple.com/?ll=' + this.facility.geolatitude + ',' + this.facility.geolongitude
        // return 'maps://q=' + this.facility.geolatitude + ',' + this.facility.geolongitude
      }
      return ''
    }
  },
  mounted () {
    $('#editFacilityModal').on('shown.bs.modal', () => {
      this.triggerFocus()
    })
  },
  methods: {
    copyFacility () {
      this.facility = Object.assign({}, this.selectedFacility)
    },
    saveFacility () {
      this.$v.$touch()
      if (this.$v.$invalid) {
        return
      }

      if (this.action === 'add') {
        facilityService.addFacility(this.facility).then((createdFacility) => {
          this.flagChanges()
        }).catch(error => {
          this.errorMessage = this.localizedBackendMessage(error.message)
        })
      } else if (this.action === 'edit') {
        facilityService.updateFacility(this.facility.id, this.facility).then((updatedFacility) => {
          this.flagChanges()
        }).catch(error => {
          this.errorMessage = this.localizedBackendMessage(error.message)
        })
      }
    },
    close () {
      this.$v.$reset()
      this.errorMessage = ''
      // zurück zum ursprünglichen 'selected facility',
      // um etwaigen Änderungen im Formular loszuwerden
      this.copyFacility()
      //
      // this.$refs.facilityEditForm.reset()
      this.emitClosed()
      $('#editFacilityModal').modal('hide')
    },
    triggerFocus () {
      // Alle 'textarea-autosize' berühren!
      $('#locationInput').trigger('focus')
      $('#descriptionInput').trigger('focus')
      $('#nameInput').trigger('focus')
    },
    checkType () {
      //
    },
    facilityTypeString (type) {
      return this.attributeLabelForCode('facilityTypes', type)
    }
  }
}
</script>

<style lang="scss" scoped>
.modal {
  .modal-dialog {
    width: 400px;
  }

  .blue-text {
    color: #0069d9;
    font-weight: 700;
  }

  .td-right {
    text-align: right;
  }
}
</style>
