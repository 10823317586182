
import $ from 'jquery'
import { mapGetters } from 'vuex'

export default {
  data () {
    return {
      timestamp: new Date(),
      modalViewVisible: false,
      isLoading: false,
      errorMessage: ''
    }
  },
  watch: {
    running () {
      if (!this.running) {
        this.isLoading = false
        this.lookForServiceLevelMessage()
      }
    }
  },
  computed: {
    ...mapGetters([
      'client',
      'running',
      'errorflag',
      'errorinfo'
    ]),
    modalTimestamp () {
      return this.timestamp
    },
    userIsAdmin () {
      return [4].includes(this.client.role)
    },
    userMayEdit () {
      return [3, 4].includes(this.client.role)
    }
  },
  mounted () { // muss in jeder konkreten Komponente überladen werden!
    this.clearError()
  },
  beforeRouteLeave (to, from, next) {
    if (this.modalViewVisible) {
      next(false)
    } else {
      next()
    }
  },
  methods: {
    modalWillBecomeVisible () {
      this.timestamp = new Date()
      this.modalViewVisible = true
    },
    modalClosed (anyChanges) {
      this.modalViewVisible = false
      if (anyChanges) {
        this.reloadData()
      }
    },
    openModal (domid) {
      this.modalWillBecomeVisible()
      $(domid).modal({ backdrop: 'static' }, 'show')
    },
    askDelete () {
      this.modalWillBecomeVisible()
      this.openModal('#confirmModal')
    },
    clearError () {
      this.errorMessage = ''
    },
    reloadData () {
      // muss in jeder konkreten Komponente überladen werden!
    },
    localizedBackendMessage (errorMessage) {
      return this.$t('backend.' + errorMessage)
    },
    lookForServiceLevelMessage () {
      if (this.errorflag) {
        const message = this.anyErrorAtServiceLevel()
        if (message.length > 0) this.errorMessage = message
      }
    },
    anyErrorAtServiceLevel () {
      let answer = ''
      if (this.errorflag) {
        answer = this.$t('service.' + this.errorinfo.origin, { message: this.localizedBackendMessage(this.errorinfo.message) })
        this.$store.dispatch('clearError')
      }
      return answer
    },
    attributeLabelForCode (attribute, code) {
      return (code >= 0) ? this.$t(attribute + '.' + code) : '? ' + code
    }
  }
}
