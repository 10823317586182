<template>
  <div class="error-row-wrapper">
    <div v-show="errorMessage && errorMessage.length > 0" class="alert alert-danger failed spacer">{{ errorMessage }}<span class="float-right" @click="clearError()"><font-awesome-icon icon="eraser" /></span></div>
  </div>
</template>

<script>

import { mapGetters } from 'vuex'

export default {
  name: 'ErrorRow',
  props: {
    errorMessage: {
      type: String,
      required: true
    }
  },
  // watch: {
  //  errorflag () {
  //    const message = this.anyErrorAtServiceLevel()
  //    if (message.length > 0) this.errorMessage = message
  //  }
  // },
  computed: {
    ...mapGetters([
      'errorflag',
      'errorinfo'
    ])
  },
  methods: {
    lookForServiceLevelMessage () {
      if (this.errorflag) {
        const message = this.anyErrorAtServiceLevel()
        if (message.length > 0) this.errorMessage = message
      }
    },
    anyErrorAtServiceLevel () {
      let answer = ''
      if (this.errorflag) {
        const localizedBackendMessage = this.$t('backend.' + this.errorinfo.message)
        answer = this.$t('service.' + this.errorinfo.origin, { message: localizedBackendMessage })
        this.$store.commit('clearError')
      }
      return answer
    },
    clearError () {
      this.errorMessage = ''
      this.$emit('clearError')
    }
  }
}
</script>

<style lang="scss" scoped>
  .error-row-wrapper {
    margin-bottom: 10px;
    margin-bottom: 10px;
  }

  .spacer {
    margin-top: 10px;
  }
</style>
