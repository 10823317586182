import axios from 'axios'
import errorParser from '@/utils/error-parser'

export default {
  /**
   * Fetch all cdcdevices for a certain facility
   */
  getCDCDeviceData (facilityId) {
    return new Promise((resolve, reject) => {
      axios.get('/cdcdevices/' + facilityId).then(({ data }) => {
        resolve(data)
      }).catch((error) => {
        reject(errorParser.parse(error))
      })
    })
  },

  /**
   * Add a new user cdcdevice
   * @param {Object} cdcdevice detail
   */
  addCDCDevice (detail) {
    return new Promise((resolve, reject) => {
      axios.post('/cdcdevices', detail).then(({ data }) => {
        resolve(data)
      }).catch((error) => {
        reject(errorParser.parse(error))
      })
    })
  },

  /**
   * Update existing user cdcdevice
   * @param {Integer} cdcdevice ID
   * @param {Object} user cdcdevice detail
   */
  updateCDCDevice (cdcdeviceId, detail) {
    return new Promise((resolve, reject) => {
      axios.put('/cdcdevices/' + cdcdeviceId, detail).then(({ data }) => {
        resolve(data)
      }).catch((error) => {
        reject(errorParser.parse(error))
      })
    })
  },

  /**
   * Delete an existing cdcdevice
   * @param {Integer} cdcdeviceId
   */
  deleteCDCDevice (cdcdeviceId) {
    return new Promise((resolve, reject) => {
      axios.delete('/cdcdevices/' + cdcdeviceId).then(({ data }) => {
        resolve(data)
      }).catch((error) => {
        reject(errorParser.parse(error))
      })
    })
  },

  cdcDataUpload (payload) {
    return new Promise((resolve, reject) => {
      axios.post('/cdcupload', payload).then(({ data }) => {
        resolve(data)
      }).catch((error) => {
        reject(errorParser.parse(error))
      })
    })
  },

  cdcSensorConfigUpload (payload) {
    return new Promise((resolve, reject) => {
      axios.post('/cdcsensorupload', payload).then(({ data }) => {
        resolve(data)
      }).catch((error) => {
        reject(errorParser.parse(error))
      })
    })
  },

  getCDCDataValues (cdcDeviceId, fromDate, untilDate) {
    return new Promise((resolve, reject) => {
      // '/cdcdatamock/' '/cdcdatavalues/'
      axios.get('/cdcdatavalues/' + cdcDeviceId + '/' + fromDate + '/' + untilDate + '/ms').then(({ data }) => {
        resolve(data)
      }).catch((error) => {
        reject(errorParser.parse(error))
      })
    })
  },

  getCDCDataHierarchy (cdcDeviceId, fromDate, untilDate) {
    return new Promise((resolve, reject) => {
      axios.get('/cdcdatahierarchy/' + cdcDeviceId + '/' + fromDate + '/' + untilDate + '/ms').then(({ data }) => {
        resolve(data)
      }).catch((error) => {
        reject(errorParser.parse(error))
      })
    })
  },

  addSensorTotal (payload) {
    return new Promise((resolve, reject) => {
      axios.post('/sensortotal', payload).then(({ data }) => {
        resolve(data)
      }).catch((error) => {
        reject(errorParser.parse(error))
      })
    })
  }
}
