<template>
  <div class="container public">
    <page-header />
    <div class="content-container">
      <div class="<organization-list-section">
        <h2 class="section-title">{{ $t('organizationPage.title') }}</h2>
        <div class="btn-toolbar">
          <button type="button" class="btn btn-primary mr-3" @click="showDetail('add')">
            <font-awesome-icon icon="plus" /> &nbsp; {{ $t('organizationPage.addOrganization') }}
          </button>
          <button class="btn btn-secondary  mr-3" @click="showDetail('edit')"
            :disabled="this.selectedOrganizationId === -1"><font-awesome-icon icon="edit" />&nbsp; {{ $t('organizationPage.editOrganization') }}</button>
          <button class="btn btn-secondary  mr-3" @click="askDelete"
            :disabled="this.selectedOrganizationId === -1 || this.selectedOrganizationId === 1000"><font-awesome-icon icon="trash" />&nbsp; {{ $t('organizationPage.deleteOrganization') }}</button>
        </div>
        <error-row @clearError="clearError" :errorMessage="errorMessage" />
        <div v-show="isLoading" class="is-loading-section" >
          <button class="btn btn-primary"><span class="spinner-border spinner-border-sm"></span></button><span> {{ $t('organizationPage.isLoading') }} </span>
        </div>
        <div v-show="hasRoles && !hasOrganizations" id="no-data-section">
          {{ $t('organizationPage.tableHasNoEntries') }}
        </div>
        <div v-show="!isLoading && hasOrganizations" id="table-section" >
          <table class="table table-hover">
            <thead>
              <tr>
                <th>&nbsp;</th>
                <th @click="sort('code')">{{ $t('organizationPage.columnCode') }}</th>
                <th @click="sort('name')">{{ $t('organizationPage.columnName') }}</th>
                <th colspan="2" @click="sort('facilitycnt')">{{ $t('organizationPage.columnFacilityCnt') }}</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="organization in sortedOrganizations"
                :key="organization.id"
                @click="selectRow(organization)"
                :class="{'table-primary' : isOrganizationSelected(organization.id)}"
              >
                <td><span @click.stop="editOrganization(organization)"><font-awesome-icon icon="edit" /></span></td>
                <td>{{ organization.code }}</td>
                <td>{{ organization.name }}</td>
                <td class="text-center"><span v-if="organization.facilitycnt > 0">{{ organization.facilitycnt }}</span></td>
                <td><button class="btn btn-outline-info btn-sm" @click="gotoFacilities(organization)">{{ $t('organizationPage.editFacilities') }}</button></td>

              </tr>
            </tbody>
         </table>
        </div>
      </div>
    </div>
    <page-footer />
    <edit-organization-modal @modalClosed="modalClosed" :selectedOrganization="selectedOrganization" :action="selectedAction" :modalOpened="modalTimestamp" />
    <confirm-modal
      :title="$t('organizationPage.deleteOrganization')"
      :text="confirmDeleteText"
      :confirmButtonLabel="$t('confirmModal.confirmButtonLabel')"
      :cancelButtonLabel="$t('confirmModal.cancelButtonLabel')"
      @confirm="confirmDelete"
      @modalClosed="modalClosed"
    />

  </div>
</template>

<script>
import objectSorter from '@/utils/object-sorter'
import PageHeader from '@/components/PageHeader.vue'
import ErrorRow from '@/components/ErrorRow.vue'
import PageFooter from '@/components/PageFooter.vue'
import EditOrganizationModal from '@/modals/EditOrganizationModal.vue'
import ConfirmModal from '@/modals/ConfirmModal.vue'
import PageViewMixin from '@/mixins/PageViewMixin.js'

import { mapGetters } from 'vuex'
import organizationService from '@/services/organizations'

export default {
  name: 'OrganizationPage',
  mixins: [PageViewMixin],
  data () {
    return {
      selectedOrganization: null,
      selectedAction: 'add',
      currentSortKey: 'name',
      currentSortOrder: 'asc',
      isLoading: true
    }
  },
  created () {
    this.loadOrganizations(true)
  },
  destroyed () {
    this.$store.dispatch('discardOrganizationData')
  },
  mounted () {
    this.clearError()
  },
  computed: {
    ...mapGetters([
      'hasOrganizations',
      'organizations',
      'running'
    ]),
    confirmDeleteText () {
      if (this.selectedOrganization != null) {
        return this.$t('organizationPage.confirmDeleteText', { name: this.selectedOrganization.name })
      }
      return ''
    },
    selectedOrganizationId () {
      return this.selectedOrganization ? this.selectedOrganization.id : -1
    },
    sortedOrganizations () {
      return objectSorter.sortedArray(this.organizations, this.currentSortOrder, this.currentSortKey)
    }
  },
  components: {
    'page-header': PageHeader,
    'error-row': ErrorRow,
    'page-footer': PageFooter,
    'edit-organization-modal': EditOrganizationModal,
    'confirm-modal': ConfirmModal
  },
  methods: {
    loadOrganizations (reset) {
      this.isLoading = true
      this.$store.dispatch('getOrganizationData')
      if (reset) {
        this.selectedOrganization = this.emptyOrganization()
      }
    },
    isOrganizationSelected (id) {
      return id === this.selectedOrganizationId
    },
    selectRow (organization) {
      if (this.selectedOrganization !== organization) {
        this.selectedOrganization = organization
      } else {
        this.selectedOrganization = this.emptyOrganization()
      }
    },
    showDetail (action) {
      if (action === 'add') {
        this.selectedOrganization = this.emptyOrganization()
        this.selectedAction = 'add'
      } else {
        this.selectedAction = 'edit'
      }
      this.showModalView()
    },
    editOrganization (organization) {
      // FIXME!!! TEST OF 'LASTEST'
      const test = false
      if (test) {
        this.testLatestData(organization)
      } else {
        this.selectedOrganization = organization
        this.selectedAction = 'edit'
        this.showModalView()
      }
    },
    showModalView () {
      this.openModal('#editOrganizationModal')
    },
    confirmDelete () {
      organizationService.deleteOrganization(this.selectedOrganizationId).then((deletedOrganization) => {
        this.loadOrganizations(true)
      }).catch(error => {
        this.errorMessage = this.localizedBackendMessage(error.message)
      })
    },
    emptyOrganization () {
      return {
        id: -1,
        name: '',
        code: ''
      }
    },
    sort (key) {
      // reverse sort order?
      if (key === this.currentSortKey) {
        this.currentSortOrder = this.currentSortOrder === 'asc' ? 'desc' : 'asc'
      }
      this.currentSortKey = key
    },
    reloadData () {
      this.loadOrganizations(false)
    },
    gotoFacilities (organization) {
      this.$router.push({ name: 'facilities', params: { organizationid: organization.id } })
    },
    testLatestData (organization) {
      const payload = { organizationId: organization.id }
      this.$store.commit('clearError')
      this.$store.dispatch('getLatestCDCDataValues', payload)
    }
  }
}
</script>

<style lang="scss" scoped>
.content-container {
  padding: 0 35px;

  h2 {
    font-size: 18px;
    margin-bottom: 15px;
    font-weight: 400;
  }

  .organization-list-section {
    margin: 30px 10px;
  }
}
</style>
